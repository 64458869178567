<template>
    <div id="dp-prints-container">
        <h2 class="section-header">Open Interest</h2>

        <v-skeleton-loader
                style="width: 100%; height: 100%;"
                v-if="loading"
                type="table-tbody"
        ></v-skeleton-loader>

        <v-data-table
                dark
                v-else
                id="print-table"
                :headers="headers"
                :items="prints"
                :items-per-page="15"
                dense
                calculate-widths
                mobile-breakpoint="200"
        >
            <template
                    v-slot:item.expirDate="{ item }"
            >
                {{ item.expirDate }}
            </template>

            <template
                    v-slot:item.totalOI="{ item }"
            >

                {{ Number(item.totalOI).toLocaleString() }}

            </template>

            <template
                    v-slot:item.callOIpercent="{ item }"
            >
                {{ item.callOIpercent.toFixed(2) }}
            </template>

            <template
                    v-slot:item.putOIpercent="{ item }"
            >
                {{ item.putOIpercent.toFixed(2) }}
            </template>
            <template
                    v-slot:item.totalVol="{ item }"
            >
                {{ Number(item.totalVol).toLocaleString() }}
            </template>
            <template
                    v-slot:item.callVolpercent="{ item }"
            >
                {{ item.callVolpercent.toFixed(2) }}
            </template>
            <template
                    v-slot:item.putVolpercent="{ item }"
            >
                {{ item.putVolpercent.toFixed(2) }}
            </template>

        </v-data-table>
    </div>
</template>

<script>
    import apiService from "@/api/daily-data";

    export default {
        name: "OpenInterestTabTTG",
        props: {
            ticker: String
        },
        async mounted() {
            let oiData = await apiService.getOIDataTTG(this.ticker);
            this.printData = await oiData.json();
            this.prints = this.printData;
            this.loading = false;

        },
        data: () => ({
            loading: true,
            headers: [
                {
                    text: "ExpDate",
                    align: 'center',
                    sortable: true,
                    value: "expirDate",
                },
                {
                    text: "OI",
                    align: 'center',
                    sortable: true,
                    value: "totalOI",
                },
                {
                    text: "oiCall %",
                    align: 'center',
                    sortable: true,
                    value: "callOIpercent",
                },
                {
                    text: "oiPut %",
                    align: 'center',
                    sortable: true,
                    value: "putOIpercent",
                },
                {
                    text: "Vol",
                    align: 'center',
                    sortable: true,
                    value: "totalVol",
                },
                {
                    text: "vCall %",
                    align: 'center',
                    sortable: true,
                    value: "callVolpercent",
                },
                {
                    text: "vPut %",
                    align: 'center',
                    sortable: true,
                    value: "putVolpercent",
                },
            ],
            prints: [],
        }),

    }
</script>

<style scoped>

    #dp-prints-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    #print-table {
        padding-top: 10px;
        width: 100%;
        background-color: #4C4C4C;
    }

    >>>td {
        padding: 16px 0 !important;
    }

    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }


</style>