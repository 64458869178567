<template>
  <v-container grid-list-xl>
    <v-layout row justify-space-between>
      <v-flex md6>
        <v-card height="100%" width="100%">
          <div class="tradingview-widget-container">
            <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-events.js" async>
              {
                "colorTheme": "dark",
                      "isTransparent": false,
                      "width": "100%",
                      "height": "100%",
                      "locale": "en",
                      "importanceFilter": "-1,0,1"
              }
            </script>
          </div>
        </v-card>
      </v-flex>
      <v-flex md6>
        <!-- TradingView Widget BEGIN -->
        <div class="tradingview-widget-container">
          <script type="application/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js" async>
            {
              "width": 770,
                    "height": 450,
                    "symbolsGroups": [
              {
                "name": "Indices",
                "originalName": "Indices",
                "symbols": [
                  {
                    "name": "FOREXCOM:SPXUSD",
                    "displayName": "S&P 500"
                  },
                  {
                    "name": "FOREXCOM:NSXUSD",
                    "displayName": "US 100"
                  },
                  {
                    "name": "FOREXCOM:DJI",
                    "displayName": "Dow 30"
                  },
                  {
                    "name": "INDEX:NKY",
                    "displayName": "Nikkei 225"
                  },
                  {
                    "name": "INDEX:DEU40",
                    "displayName": "DAX Index"
                  },
                  {
                    "name": "FOREXCOM:UKXGBP",
                    "displayName": "UK 100"
                  }
                ]
              },
              {
                "name": "Futures",
                "originalName": "Futures",
                "symbols": [
                  {
                    "name": "CME_MINI:ES1!",
                    "displayName": "S&P 500"
                  },
                  {
                    "name": "CME:6E1!",
                    "displayName": "Euro"
                  },
                  {
                    "name": "COMEX:GC1!",
                    "displayName": "Gold"
                  },
                  {
                    "name": "NYMEX:CL1!",
                    "displayName": "Crude Oil"
                  },
                  {
                    "name": "NYMEX:NG1!",
                    "displayName": "Natural Gas"
                  },
                  {
                    "name": "CBOT:ZC1!",
                    "displayName": "Corn"
                  }
                ]
              },
              {
                "name": "Bonds",
                "originalName": "Bonds",
                "symbols": [
                  {
                    "name": "CME:GE1!",
                    "displayName": "Eurodollar"
                  },
                  {
                    "name": "CBOT:ZB1!",
                    "displayName": "T-Bond"
                  },
                  {
                    "name": "CBOT:UB1!",
                    "displayName": "Ultra T-Bond"
                  },
                  {
                    "name": "EUREX:FGBL1!",
                    "displayName": "Euro Bund"
                  },
                  {
                    "name": "EUREX:FBTP1!",
                    "displayName": "Euro BTP"
                  },
                  {
                    "name": "EUREX:FGBM1!",
                    "displayName": "Euro BOBL"
                  }
                ]
              },
              {
                "name": "Forex",
                "originalName": "Forex",
                "symbols": [
                  {
                    "name": "FX:EURUSD"
                  },
                  {
                    "name": "FX:GBPUSD"
                  },
                  {
                    "name": "FX:USDJPY"
                  },
                  {
                    "name": "FX:USDCHF"
                  },
                  {
                    "name": "FX:AUDUSD"
                  },
                  {
                    "name": "FX:USDCAD"
                  }
                ]
              }
            ],
                    "showSymbolLogo": true,
                    "colorTheme": "dark",
                    "isTransparent": false,
                    "locale": "en"
            }
          </script>
        </div>
        <!-- TradingView Widget END -->
      </v-flex>


    </v-layout>
    <v-layout row justify-space-around>
      <v-flex md6>
        ...test
      </v-flex>
      <v-flex md6>
        ...test3
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
    //import {abbreviateNumber} from "@/helpers/formatting";
    import {mapActions} from "vuex";
    //import {checkCookie, COOKIE_USER_AUTH, COOKIE_USER_ID, getCookie} from "../../utils/CookieHelper";
    //import {BASE_URL} from "../../utils/constants";
    //const ENDPOINT  = "api/v1/users/"
    //import apiService from '@/api/daily-data';

    export default {
        name: "Dash",
        props: {
            widgetMode: Boolean
        },
        beforeMount() {
            if (!this.widgetMode) {
                this.tableHeight = `${window.innerHeight - 270}`;
            }
        },
        async mounted() {

            this.setLogoAndBottomNavVisibility(false);

            //let backlog = await apiService.getFlowData();
            //this.printData = await backlog.json();
            //this.prints = this.printData;

            this.loading = false;

        },
        beforeDestroy() {
            this.setLogoAndBottomNavVisibility(true);
        },
        data: () => ({
            snapshotInitState: true,
            printData: [],
            prints: [],
        }),
        methods: {
            ...mapActions('elementVisibility', [
                'setLogoAndBottomNavVisibility'
            ]),
        }

    }
</script>

<style scoped>

    #button-container {
        display: grid;
        align-self: start;
        align-items: start;
        grid-template-rows: fit-content(1em) fit-content(1em) fit-content(1em);

        height: auto;
        width: 100%;
        margin-top: 15px;
    }


    #discord-button {
        position: relative;
        height: 52px;
        background-color: #7289DA;
    }

    #darkpool-button {
        position: relative;
        height: 62px;
        background-color: #404040;
        border: thin solid #CCCCCC;
    }

    #flow-button {
        position: relative;
        height: 62px;
        background-color: #404040;
        border: thin solid #CCCCCC;

    }

    #account-button {
        position: relative;
        height: 62px;
        background-color: #404040;
        border: thin solid #CCCCCC;

    }

    #streaming-container {
        height: 100%;
        width: 100%;
        background-color: #323232;
        overflow-y: auto;
    }

    .tradingview-widget-container{
      height: 100%;
      width: 100%;
    }

    #page-controls {
        flex: none;
        padding: 16px 16px;
        width: 100%;
        flex: none;
    }

    h1 {
        text-align:center;
    }

    div{
        text-align: -webkit-center;
    }

    #page-heading {
        flex: none;
        padding: 16px;
        color: black;
        flex: none;
        width: 100%;
        background-color: #E5E5E5;
    }

    #streaming-controls {
        width: auto;
    }

    #print-table {
        height: 100%;
        border-radius: 0 !important;
        width: 100%;
        overflow-y: auto;

        background-color: #323232;
        box-shadow: none;
    }

    .bid-ask-indicator {
        font-family: 'Verdana',  sans-serif !important;
    }

    .stock-sector-blip {
        height: 30px;
        width: 30px;
    }

    >>>th {
        padding: 16px 0 16px 14px !important;
    }
    >>>td {
        padding: 16px 0 !important;
    }



    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }

    >>>tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }

    .style-1 {
        color: rgb(215,215,44)
    }
    .style-2 {
        color: rgb(114,114,67)
    }

    >>>tr {
        text-align: center !important;
    }

    .right-side-button-icon {
        position: absolute;
        height: 30px;
    }
</style>