import Vue from 'vue'
import App from './App.vue'
import { firestorePlugin } from 'vuefire'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import moment from 'moment'
import StockExpandedTTG from "./components/user-pages/stock-expanded/StockExpanded/StockExpandedTTG";
import DashboardFlowTTG from "./components/DashboardFlowTTG";
import StreamingDarkPoolTTG from "./components/DashboardDarkPoolTTG";
import SectorRollupTTG from "./components/SectorRollupTTG";
import DashboardMain from "./components/DashboardMain";
import DashboardStock from "./components/DashboardStock";
import SectorFlow from "./components/SectorFlow";
import DashboardCryptoTTG from "./components/DashboardCryptoTTG";
import OptionProfitCalculatorTTG from "./components/opcTTG";
import test from "./components/test";
import Landing from "./components/Landing";
import HighchartsVue from 'highcharts-vue'
import mapInit from 'highcharts/modules/map'
import darkUnica from "highcharts/themes/high-contrast-dark";
import DashboardShort from "./components/DashboardShort";
import Highcharts from "highcharts";
import Treemap from 'highcharts/modules/treemap'
import VueSimpleAlert from "vue-simple-alert";
//import test from "./components/test";
import stockInit from 'highcharts/modules/stock';


Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
})

Highcharts.setOptions({
  credits: {
    enabled: false
  },
  lang: {
    numericSymbols: ['K', ' M', 'B', 'T']
  }})



Vue.use(VueSimpleAlert);
Treemap(Highcharts)
mapInit(Highcharts)
stockInit(Highcharts)
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(firestorePlugin)
darkUnica(Highcharts);
Vue.use(HighchartsVue)

function createRoutes() {

  // Add any static routes to this literal
  let generatedRoutes = [
    { name: "test", path: '/test', component: test },
    { name: "DashboardStock", path: '/insight', component: DashboardStock },
    { name: "DashboardFlowTTG", path: '/optionsttg', component: DashboardFlowTTG },
    { name: "DarkPoolFlowTTG", path: '/darkpoolttg', component: StreamingDarkPoolTTG },
    { name: "StockExpandedTTG", path: '/expandedttg', component: StockExpandedTTG },
    { name: "DashboardCryptoTTG", path: '/cryptottg', component: DashboardCryptoTTG },
    { name: "OptionProfitCalculatorTTG", path: '/opc', component: OptionProfitCalculatorTTG },
    { name: "DashboardShort",  path: '/shortsttg', component: DashboardShort },
    { name: "SectorFlowTTG", path: '/sectorflowttg', component: SectorFlow },
    { name: "DashboardMain", path: '/dashboard', component: DashboardMain },
    { name: "SectorRollupTTG", path: '/sectors', component: SectorRollupTTG, props: (route) => ({
        sectorMap: route.query.sectorMap,
      }) },
    { name: "Landing", path: '/landing', component: Landing },
  ]

  return generatedRoutes;
}

const routes = createRoutes()

const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: {App},
  vuetify,
  render: h => h(App)
})
