<template>
  <div id="details-container">
    <v-tabs dark color=#65BA7D background-color=#323232 v-model="tab">
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <v-card-text>
            <component v-bind:is="item.content" :ticker="stock.ticker"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DetailsTabTTG from "./DetailsTabTTG";
import OpenInterestTabTTG from "./OpenInterestTabTTG";
import InstitutionalTabTTG from "./InstitutionalTabTTG";
import ShortInterestTabTTG from "./ShortIntTabTTG";

export default {
  name: "DetailsTTG",
  props: {
    stock: Object
  },
  components: {
    DetailsTabTTG,
    OpenInterestTabTTG,
    ShortInterestTabTTG,
    InstitutionalTabTTG
  },
  data() {
    return {
      tab: null,
      items: [
        {tab: 'Details', content: 'DetailsTabTTG'},
        {tab: 'Open Interest', content: 'OpenInterestTabTTG'},
        {tab: 'Short Volume', content: 'ShortInterestTabTTG'},
        {tab: 'Inst. Change', content: 'InstitutionalTabTTG'}
      ],
    }
  },
}
</script>

<style scoped>
  #details-container {
    width: 100%;
  }

  .deets-table {
    width: 100%;
    color: white;
    margin-bottom: 1rem;
    table-layout: fixed;
  }

  th {
    text-align: left;
  }

  td, th {
    border: 1px none #999;
    padding: 0.5rem;
    text-align: left;
  }
</style>