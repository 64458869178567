<template>

    <div id="streaming" class="flex-cl-center-start">
        <div id="streaming-container" class="flex-cl-center-start">
        <script type="application/javascript" src="https://s3.tradingview.com/tv.js"></script>
        <div style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details><br><br><br><br> <br>
            Crypto Stream - Currently following BTC - 150k+, ETH-SOL 75k+, DOGE - SHIB - DOT - LTC - XRP 50k+
            <br>A - Ask, B - Bid, Sweep - Denotes order was made up of 2+ orders

        </div>
        <br>
        <br>
        <br>
        <br>
        <br>

        <v-card-title>

            <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search Symbol Here..."
                    single-line
                    hide-details
            ></v-text-field>

        </v-card-title>

        <v-skeleton-loader
                style="width: 100%; height: 100%;"
                v-if="loading"
                type="table-tbody"
        ></v-skeleton-loader>


        <v-data-table
                v-else
                dark
                id="print-table"
                :headers="headers"
                :items="indexedItems"
                item-key="id"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                :footer-props="{'items-per-page-options':[50, 100]}"
                :search="search"
                :custom-filter="filterPerfectMatch"
                dense
                calculate-widths
                mobile-breakpoint="200"
                @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    More info about {{ item.symbol }}


                    <!-- TradingView Widget BEGIN -->
                    <div class="tradingview-widget-container" id="tv123">

                        <script type="application/javascript" >
                            new TradingView.widget(
                                {
                                    "width": 980,
                                    "height": 610,
                                    "symbol": 'BINANCE:{{item.symbol}}USDT',
                                    "interval": "D",
                                    "timezone": "Etc/UTC",
                                    "theme": "dark",
                                    "style": "1",
                                    "locale": "en",
                                    "toolbar_bg": "#f1f3f6",
                                    "enable_publishing": false,
                                    "allow_symbol_change": true,
                                    "container_id": "tv123"
                                }
                            );
                        </script>
                    </div>
                    <!-- TradingView Widget END -->


                </td>
            </template>
            <template
                    v-slot:item.symbol="{ item }"
            >
                <v-chip
                        :color="sectorColorMap[item.sector]"
                        dark
                >
                    {{ item.symbol }}
                </v-chip>
            </template>

            <template
                    v-slot:item.exchange="{ item }"
            >
                {{ item.exchange }}
            </template>

            <template v-slot:item.price="{ item }"
            >
                {{ item.price &lt; .01 ? item.price.toFixed(8) : item.price.toLocaleString('en-US', {style: 'currency', currency: 'USD' }) }}
            </template>

            <template
                    v-slot:item.sum="{ item }"
            >
                {{ item.sum.toLocaleString() }}
            </template>

            <template
                    v-slot:item.premium="{ item }"
            >
                ${{ abbrNum(item.premium.toFixed(0)) }}
            </template>

            <template
                    v-slot:item.epochTime="{ item }"
            >
                {{ formatDate(item.epochTime) }}
            </template>
            <template
                    v-slot:item.side="{ item }"
            >
                <v-chip
                        :color="getSideColor(item)"
                        dark
                >

                    <div v-html="determinePrintType(item)"></div>
                </v-chip>
                <p v-if="item.count>1"></p><v-chip v-if="item.count>1"
                        :color="getSweepColor(item)"
                        dark
                        x-small
                >

                    <div v-html="determineSweepType(item)"></div>
                </v-chip>
            </template>
            <template v-slot:footer>
                <div style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details><br>A - Ask, B - Bid <br>
                    Sweep - Denotes order was made up of 2+ orders<br>
                </div>
            </template>
        </v-data-table>
        </div>
    </div>
</template>

<script>
    import {abbreviateNumber} from "@/helpers/formatting";
    import {CRYPTO_COLLECTION} from "@/firebase/config";
    import {mapActions} from "vuex";
    //import {checkCookie, COOKIE_USER_AUTH, COOKIE_USER_ID, getCookie} from "../../utils/CookieHelper";
    import apiService from "@/api/daily-data";
    import {getCookie, COOKIE_USER_AUTH} from "@/utils/CookieHelper";

    export default {
        name: "StreamingCrypto",
        props: {
            widgetMode: Boolean
        },
        beforeMount() {

            if (!this.widgetMode) {
                this.tableHeight = `${window.innerHeight - 270}`;
            }
        },
        async mounted() {
          // Send user back if not logged in
            // Send user back if not logged in
            //

          var cookie = getCookie(COOKIE_USER_AUTH)
          if (!cookie){
              window.location.href = 'https://truetradinggroup.com/member-dashboard/options-dark-pool-access-link/';
          }

          let backlog = await apiService.getCryptoData();
          this.printData = await backlog.json();
          let nowDate = new Date().getTime()
          this.prints = this.printData;


          // TODO: Stop this when you navigate to expanded view - current if you watch the console it keeps printing.

          this.cryptoListener = CRYPTO_COLLECTION.where('epochTime', '>', nowDate).onSnapshot(snapshot => {
              if (!snapshot.docChanges().empty) {
                  snapshot.docChanges().forEach((change) => {
                      if (change.type === "added") {
                          //console.log("New doc: ", change.doc.data());
                          this.prints.unshift(change.doc.data());
                      }
                      if (change.type === "modified") {
                          console.log("Modified doc: ", change.doc.data());
                      }
                      if (change.type === "removed") {
                          console.log("Removed doc: ", change.doc.data());
                      }
                  });
              }
          }).bind(this);

          this.loading = false;
        },
        beforeDestroy() {
            //kill the listener
            this.cryptoListener();
        },
        data: () => ({
            expanded: [],
            singleExpand: true,
            sweepPrintColorMap: {
                "Buy": "#2F65DE",
            },
            lightPrintColorMap: {
                "Buy": "#025c1d",
                "Sell": "#ab2b36",
            },
            sectorColorMap: {
                "Basic Materials": "#666e18",
                "Communication Services": "#DE832F",
                "Consumer Cyclical": "#75206b",
                "Consumer Defensive": "#2F65DE",
                "Energy": "#025c1d",
                "Financial": "#3c4d3e",
                "Healthcare": "#1e3133",
                "Industrials": "#3f3c69",
                "Real Estate": "#693827",
                "Technology": "#2180CE",
                "Utilities": "#ab4f2b"
            },
            tableHeight: 0,
            loading: true,
            search: '',
            headers: [
                {
                    text: "Symbol",
                    align: 'center',
                    sortable: true,
                    value: 'symbol',
                },
                {
                    text: "Exchange",
                    align: 'center',
                    sortable: true,
                    value: 'exchange',
                },
                {
                    text: "Price",
                    align: 'right',
                    sortable: true,
                    filterable: false,
                    value: 'price',
                },
                {
                    text: "Size",
                    align: 'right',
                    sortable: true,
                    filterable: false,
                    value: 'sum',
                },
                {
                    text: "Value",
                    align: 'right',
                    sortable: true,
                    filterable: false,
                    value: 'premium',
                },
                {
                    text: "Time",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'epochTime',
                },
                {
                    text: "Side",
                    align: 'center',
                    filterable: false,
                    sortable: true,
                    value: 'side',
                },
            ],
            prints: [],
        }),
        computed: {
            indexedItems () {
                return this.prints.map((item, index) => ({
                    id: index,
                    ...item
                }))
            }
        },
        methods: {
            ...mapActions('elementVisibility', [
                'setLogoAndBottomNavVisibility'
            ]),
            filterPerfectMatch(value, search) {
                return value != null && value.toString().toUpperCase() === search.toString().toUpperCase()
            },
            determineSweepType: function (print) {

                // TODO: Make these icons instead of text?

                let lightText = this.$vuetify.breakpoint.smAndDown ? "" : ""
                if (print.count > 1)
                    return this.$vuetify.breakpoint.smAndDown ? "Sweep" : "Sweep";
                else return lightText;
            },
            getSweepColor (print) {
                if (print.count > 1)
                    return this.sweepPrintColorMap.Buy;
                else return this.lightPrintColorMap.Buy;
            },
            determinePrintType: function (print) {

                // TODO: Make these icons instead of text?

                let lightText = this.$vuetify.breakpoint.smAndDown ? "A" : "Ask"
                if (print.side === "sell")
                    return this.$vuetify.breakpoint.smAndDown ? "B" : "Bid";
                else return lightText;
            },
            formatDate: function (millis) {
                let date = new Date(millis);
                return date.toLocaleString('en-US', {  timeZone: 'EST',  hour: 'numeric', minute: 'numeric', hour12: true })
            },
            abbrNum: abbreviateNumber,
            getSideColor (item) {
                if (item.side === "buy")
                    return this.lightPrintColorMap.Buy;
                else if (item.side === "sell") return this.lightPrintColorMap.Sell;
            },
            //toExpanded: function (value) {
            //    let stockInfo = {
            //        ticker: value.symbol,
            //    };

            //    this.$router.push({
            //        name: "StockExpandedTTG",
            //        params: {
            //            stockInfo: stockInfo
            //        }
            //    });
            //},
        }

    }
</script>

<style scoped>

    .v-main__wrap{
        background-color: #323232;
    }
    #streaming {
        background-color: #323232;
    }
    #streaming-container {
        height: 100%;
        width: 100%;
        background-color: #323232;
        padding: 1%;

    }

    #page-controls {
        flex: none;
        padding: 16px 16px;
        width: 100%;
        flex: none;
    }

    #page-heading {
        flex: none;
        padding: 16px;
        color: black;
        flex: none;
        width: 100%;
        background-color: #E5E5E5;
    }
    h1 {
        text-align:center;
    }

    #streaming-controls {
        width: auto;
    }

    #print-table {
        height: 100%;
        border-radius: 0 !important;
        width: 100%;
        overflow-y: auto;

        background-color: #323232;
        box-shadow: none;
    }

    .bid-ask-indicator {
        font-family: 'Verdana',  sans-serif !important;
    }

    .stock-sector-blip {
        height: 30px;
        width: 30px;
    }

    >>>th {
        padding: 16px 0 16px 14px !important;
    }
    >>>td {
        padding: 16px 0 !important;
    }

    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }

    #flow-button {
        position: absolute; right: 0;
        height: 62px;
        background-color: #404040;
        border: thin solid #CCCCCC;

    }
    >>>tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }


</style>