var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dp-prints-container"}},[_c('h2',{staticClass:"section-header"},[_vm._v("Open Interest")]),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"dark":"","id":"print-table","headers":_vm.headers,"items":_vm.prints,"items-per-page":15,"dense":"","calculate-widths":"","mobile-breakpoint":"200"},scopedSlots:_vm._u([{key:"item.expirDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.expirDate)+" ")]}},{key:"item.totalOI",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.totalOI).toLocaleString())+" ")]}},{key:"item.callOIpercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.callOIpercent.toFixed(2))+" ")]}},{key:"item.putOIpercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.putOIpercent.toFixed(2))+" ")]}},{key:"item.totalVol",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.totalVol).toLocaleString())+" ")]}},{key:"item.callVolpercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.callVolpercent.toFixed(2))+" ")]}},{key:"item.putVolpercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.putVolpercent.toFixed(2))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }