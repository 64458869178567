<template>
    <div id = "TTGdrawer" style="width: 100%; height: auto;">
            <v-card>
                <v-navigation-drawer
                        app
                        v-model="showNavigation"
                        expand-on-hover
                        permanent
                >
                    <v-list-item class="px-2">
                        <v-list-item-avatar>
                            <img src="../assets/ttg.jpg" alt="TTG">
                        </v-list-item-avatar>

                        <v-list-item-title>TrueTradingGroup</v-list-item-title>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list nav dense>
                        <div v-for="(link, i) in links" :key="i">

                            <v-list-item
                                    v-if="!link.subLinks"
                                    :to="link.to"
                                    class="v-list-item"
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ link.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-text="link.text" />
                            </v-list-item>

                            <v-list-group
                                    v-else
                                    :key="link.text"
                                    no-action
                                    :prepend-icon="link.icon"
                                    :value="false"
                            >
                                <template v-slot:activator>
                                    <v-list-item-title>{{ link.text }}</v-list-item-title>
                                </template>

                                <v-list-item
                                        v-for="sublink in link.subLinks"
                                        :to="sublink.to"
                                        :key="sublink.text"
                                >
                                    <v-list-item-icon>
                                        <v-icon>{{ sublink.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>{{ sublink.text }}</v-list-item-title>

                                </v-list-item>

                            </v-list-group>

                        </div>

                    </v-list>







                </v-navigation-drawer>
            </v-card>
    </div>
</template>


<script>

    export default {
        name: 'NavBar',
        props: {
        },

        components: {

        },

        data: () => ({
            links: [
                {
                    to     : '/optionsttg',
                    icon   : 'mdi-glasses',
                    text   : 'Options Flow',
                },
                {
                    to     : '/darkpoolttg',
                    icon   : 'mdi-sunglasses',
                    text   : 'Dark Pool Flow',
                },
                {
                    to     : '/cryptottg',
                    icon   : 'mdi-bitcoin',
                    text   : 'Crypto Flow',
                },
                {
                    to     : '/insight',
                    icon   : 'mdi-eye-outline',
                    text   : 'Stock Insight',
                },
                {
                    to     : '/opc',
                    icon   : 'mdi-calculator',
                    text   : 'Options Profit Calculator',
                },
                {
                    to     : '/shortsttg',
                    icon   : 'mdi-rocket-launch',
                    text   : 'Short Data',
                },
                {
                    to     : '/SectorFlowTTG',
                    icon   : 'mdi-view-dashboard',
                    text   : 'Sector Summary',
                },
                {
                    icon     : 'mdi-folder',
                    text     : 'Sectors',
                    subLinks : [
                        {
                            text : 'Basic Materials',
                            to    : '/sectors/?sectorMap=BM',
                            icon  : 'mdi-fire'
                        },
                        {
                            text : 'Communication Services',
                            to    : '/sectors/?sectorMap=CS',
                            icon  : 'mdi-radio-tower'
                        },
                        {
                            text : 'Consumer Cyclical',
                            to    : '/sectors/?sectorMap=CC',
                            icon  : 'mdi-car-side'
                        },
                        {
                            text : 'Consumer Defensive',
                            to    : '/sectors/?sectorMap=CD',
                            icon  : 'mdi-shield-home'
                        },
                        {
                            text : 'Energy',
                            to    : '/sectors/?sectorMap=E',
                            icon  : 'mdi-lightning-bolt'
                        },
                        {
                            text : 'Financial',
                            to    : '/sectors/?sectorMap=F',
                            icon  : 'mdi-piggy-bank'
                        },
                        {
                            text : 'Healthcare',
                            to    : '/sectors/?sectorMap=H',
                            icon  : 'mdi-hospital-box'
                        },
                        {
                            text : 'Industrials',
                            to    : '/sectors/?sectorMap=I',
                            icon  : 'mdi-factory'
                        },
                        {
                            text : 'Real Estate',
                            to    : '/sectors/?sectorMap=RE',
                            icon  : 'mdi-sign-real-estate'
                        },
                        {
                            text : 'Technology',
                            to    : '/sectors/?sectorMap=T',
                            icon  : 'mdi-desktop-classic'
                        },
                        {
                            text : 'Utilities',
                            to    : '/sectors/?sectorMap=U',
                            icon  : 'mdi-water'
                        },
                    ]
                },
            ],
            showNavigation: true,
            mini: true,
        }),

        methods: {

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #toolbar {

        width: 100%;
        height: 4rem;
        background-color: #343333;

        font-size: 22px;
        margin-bottom: 1rem;
    }

    #main-toolbar{
        font-size: 22px;
        margin-right:40px;
    }

    .md-drawer {
        width: 300px;
        max-width: calc(100vw - 125px);
    }

    #header-container {
        height: auto;
        width: 100%;
        margin-bottom: 10px;
        padding: 40px 16px 0 16px;
    }


    #header-container h2 {
        margin-top: 20px;
    }

    #header-container h3 {
        margin-top: 5px;
    }

    .md-content {
        padding: 16px;
    }

    #sp-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        padding: 0 16px;
    }

    #sp-title {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 1rem;
    }

    .sp-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        cursor: pointer;

        overflow-x: hidden;
        text-overflow: clip;
        margin-bottom: 0.8rem;
    }
    .v-list-item {
        align-items: center;
        display: flex;
        flex: 1 1 100%;
        letter-spacing: normal;
        min-height: 48px;
        outline: none;
        padding: 0 0px;
        position: relative;
        text-decoration: none;
    }
    @media screen and (min-width: 750px) {
        #toolbar {
            padding: 0 20px;
        }

        #sp-container {
            margin-top: 50vh;
        }

    }


    .v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
        padding-left: 45px;
        opacity: 1;
    }

    .v-list .v-list-item--active {
        color: white;
    }

    .v-list-item--link:before {
        color: transparent;
    }


</style>