import {BASE_URL, headers, authHeaders, authHeadersTTG} from "./config";


export default {

    getTodayRollup() {
        return fetch(`${BASE_URL}/toptier1.json=3`, {headers: headers})
    },
    getInstData(stock){
        return fetch(`https://api.darksweep.com/v1.0a/instDataDif/`+stock, {headers: headers})
    },
    getShortIntData(stock) {
        return fetch(`https://api.darksweep.com/v1.0a/shortint/`+stock+`/10`, {headers: headers})
    },
    getShortData() {
        return fetch(`https://api.darksweep.com/v1/ofb/shortdash/100/shortVolume`, {headers: authHeadersTTG()})
    },
    getHighShorts() {
        return fetch(`https://api.darksweep.com/v1/ofb/highshort`, {headers: authHeadersTTG()})
    },
    getFeeRate() {
        return fetch(`https://api.darksweep.com/v1/ofb/shortdash/100/feeRate`, {headers: authHeadersTTG()})
    },
    getOIDataHist(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/oihistory/` + stock, {headers: authHeadersTTG()})
    },
    dailyflowsum(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/dailyflowsum/` + stock, {headers: authHeadersTTG()})
    },
    getOIDataTTG(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/oi/` + stock, {headers: authHeadersTTG()})
    },
    getStockData(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/stockdata/` + stock, {headers: authHeaders})
    },
    getStockDataTTG(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/stockdata/` + stock, {headers: authHeadersTTG()})
    },
    getEarningsCaledar() {
    return fetch(`http://localhost:5555/v1/earningsWeekly`, {headers: authHeaders})
},
    getFlowData() {
        return fetch(`https://api.darksweep.com/v1/ofb/flowdata`, {headers: authHeadersTTG()})
    },
    getOpeningData(stock){
        return fetch(`https://api.darksweep.com/v1/ofb/options/flowOpening/`+stock, {headers: authHeadersTTG()})
    },
    getTestData(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/dailyflowsum/`+stock, {headers: authHeadersTTG()})
    },
    getSummaryOpeningFlow(){
        return fetch(`https://api.darksweep.com/v1/ofb/optionsSummary/flowOpening/top`, {headers: authHeadersTTG()})
    },
    getTestData2(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/flowHistory/`+stock, {headers: authHeadersTTG()})
    },
    getDataHistory(stock, date, cp) {
        return fetch(`https://api.darksweep.com/v1/ofb/flowHistory/all/static/`+stock+`/`+ date +`/` + cp, {headers: authHeadersTTG()})
    },
    getDataLive(stock, date, cp) {
        return fetch(`https://api.darksweep.com/v1/ofb/flowHistory/all/`+stock+`/`+ date +`/` + cp, {headers: authHeadersTTG()})
    },
    getFlowDataTTG() {
        return fetch(`https://api.darksweep.com/v1/ofb/flowdata`, {headers: authHeadersTTG()})
    },
    getDarkPoolData() {
        return fetch(`https://api.darksweep.com/v1/ofb/dpdata`, {headers: authHeaders})
    },
    getDarkPoolDataTTG() {
        return fetch(`https://api.darksweep.com/v1/ofb/dpdata`, {headers: authHeadersTTG()})
    },
    getDarkPoolDataPerStock(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/dpdatastock/` + stock, {headers: authHeaders})
    },
    getDarkPoolDataPerStockTTG(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/dpdatastock/` + stock, {headers: authHeadersTTG()})
    },
    getFlowDataPerStock(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/flowdatastock/` + stock, {headers: authHeaders})
    },
    getFlowDataPerStockTTG(stock) {
        return fetch(`https://api.darksweep.com/v1/ofb/flowdatastock/` + stock, {headers: authHeadersTTG()})
    },
    getTopShortFloat() {
        return fetch(`${BASE_URL}/topshortfloat.json`, {headers: headers})
    },
    getetfDPActivity(){
        return fetch(`https://api.darksweep.com/v1/ofb/etfDPActivity`, {headers: authHeadersTTG()})
    },
    getSectorsHistorical(){
        return fetch(`https://api.darksweep.com/v1/ofb/sectorsHistorical`, {headers: authHeadersTTG()})
    },
    getSectorData(){
        return fetch(`https://api.darksweep.com/v1/ofb/sectors.json`, {headers: authHeadersTTG()})
    },
    getSectorRollup(sector){
        return fetch(`https://api.darksweep.com/v1/ofb/sectorflow/`+sector, {headers: authHeadersTTG()})
    },
    getSectorRollupPie(sector){
        return fetch(`https://api.darksweep.com/v1/ofb/sectorflowsummary/`+sector, {headers: authHeadersTTG()})
    },
    getCryptoData(){
        return fetch(`https://api.darksweep.com/v1/ofb/flowCrypto`, {headers: authHeadersTTG()})
    },
    oratsData (stock, expirDate, strike) {
        return fetch(`https://api.darksweep.com/v1/ofb/strikes/` + stock + `/` + expirDate + `/` + strike, { headers: authHeadersTTG() })
    },
    oratsDataHist (stock, expirDate, strike) {
        return fetch(`https://api.darksweep.com/v1/ofb/hist/strikes/` + stock + `/` + expirDate + `/` + strike, { headers: authHeadersTTG() })
    },
    getLargest() {
        return fetch(`${BASE_URL}/largest.json`, {headers: headers})
    },

    // for one stock
    getTodayPrints(stock) {
        return fetch(`${BASE_URL}/today/${stock}`, {headers: headers})
    },

    getMultipleStockPrints(stockList) {
        return fetch(`${BASE_URL}/multiple`, {
            headers: headers,
            body: JSON.stringify({"stocks": stockList})
        })

    }
};