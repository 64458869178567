<template>
    <div id="streaming-container" class="flex-cl-center-start">

        <div style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details><br><br><br><br>S - Sweep, SP - Split, C - Complex, O - Opening (Vol>OI), A - Ask, B - Bid, BB - Below Bid <br>
            AA - Above Ask, LB - Lean Bid, LA - Lean Ask - All contracts are $25k+ prem for Stocks and $100k+ prem for ETFs<br>
            Unusual - column identifies size of order to current OI. 25-50x means volume of order was 25-50 times the current OI.
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>

        <v-btn
                :color="changeBackgroundColor ? 'secondary' : 'info'"
                @click="changeBackgroundColor = !changeBackgroundColor"
                :style="{
                       backgroundColor : changeBackgroundColor ? 'black !important' : '',
                       color: changeBackgroundColor ? 'secondary' : 'info'
                       }"
                large
                class="ma-2 white--text"
                id="1m+"
                v-on:click="filter1()"
        >
            Sum > 1M
        </v-btn>
        <v-btn
                :color="changeBackgroundColor5 ? 'secondary' : 'info'"
                @click="changeBackgroundColor5 = !changeBackgroundColor5"
                :style="{
                       backgroundColor : changeBackgroundColor5 ? 'black !important' : '',
                       color: changeBackgroundColor5 ? 'secondary' : 'info'
                       }"
                large
                class="ma-2 white--text"
                id="UnusualGold"
                v-on:click="unusualGold()"
        >
            Unusual >50x
        </v-btn>
        <v-btn
                :color="changeBackgroundColor2 ? 'secondary' : 'info'"
                @click="changeBackgroundColor2 = !changeBackgroundColor2"
                :style="{
                       backgroundColor : changeBackgroundColor2 ? 'black !important' : '',
                       color: changeBackgroundColor2 ? 'secondary' : 'info'
                       }"
                large
                class="ma-2 white--text"
                id="Opening"
                v-on:click="openingFilter()"
        >
            Opening
        </v-btn>

        <v-btn
                :color="changeBackgroundColor3 ? 'secondary' : 'info'"
                @click="changeBackgroundColor3 = !changeBackgroundColor3"
                :style="{
                       backgroundColor : changeBackgroundColor3 ? 'black !important' : '',
                       color: changeBackgroundColor3 ? 'secondary' : 'info'
                       }"
                large
                class="ma-2 white--text"
                id="Calls"
                v-on:click="callsFilter()"
        >
            Calls
        </v-btn>

        <v-btn
                :color="changeBackgroundColor4 ? 'secondary' : 'info'"
                @click="changeBackgroundColor4 = !changeBackgroundColor4"
                :style="{
                       backgroundColor : changeBackgroundColor4 ? 'black !important' : '',
                       color: changeBackgroundColor4 ? 'secondary' : 'info'
                       }"
                large
                class="ma-2 white--text"
                id="Puts"
                v-on:click="putsFilter()"
        >
            Puts
        </v-btn>
        <v-btn
                icon
                v-on:click="clearFilter()"
                class="ma-2 white--text"
                style="margin-right: 0; height: 45px !important; width: 45px !important;"
                dark
                small
                @click="loader = 'loading2'"
        >
            <v-icon>mdi-autorenew</v-icon>
        </v-btn>

        <v-card-title>
            <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search Symbol Here..."
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>

        <v-skeleton-loader
                style="width: 100%; height: 100%;"
                v-if="loading"
                type="table-tbody"
        ></v-skeleton-loader>


        <v-data-table
                v-else
                dark
                id="print-table"
                :headers="headers"
                :items="prints"
                :footer-props="{'items-per-page-options':[50, 100]}"
                :search="search"
                :custom-filter="filterPerfectMatch"
                :item-class="itemRowBackground"
                dense
                calculate-widths
                mobile-breakpoint="200"
                @click:row="toExpanded"
        >

            <template
                    v-slot:item.symbol="{ item }"
            >
                    {{ item.symbol }}
            </template>

            <template
                    v-slot:item.spot="{ item }"
            >
                {{ `\$${item.spot.toFixed(2)}` }}
            </template>

            <template
                    v-slot:item.expiry="{ item }"
            >
                    {{item.expiry}}
            </template>

            <template
                    v-slot:item.strike="{ item }"
            >
                    {{item.strike}}
            </template>



            <template v-slot:item.sum="{ item }">
                    <v-chip
                            :color="getColor(item)"
                            dark
                    >
                        ${{ abbrNum(item.sum) }}
                    </v-chip>

            </template>



            <template
                    v-slot:item.epochTime="{ item }"
            >
                {{ formatDate(item.epochTime*1000) }}
            </template>
            <template
                    v-slot:item.cp="{ item }"
            >
                {{ item.cp }}
            </template>
            <template
                    v-slot:item.oi="{ item }"
            >

                {{ Number(item.oi).toLocaleString() }}
            </template>
            <template
                    v-slot:item.trade="{ item }"
            >
                <div v-html="determineTradeType(item)"></div>

            </template>
            <template
                    v-slot:item.side="{ item }"
            >
                <v-chip
                        :color="getSideColor(item)"
                        dark
                >

                <div v-html="determinePrintType(item)"></div>
                </v-chip>
            </template>
            <template
                    v-slot:item.cond="{ item }"
            >
                <div v-html="determineCondType(item)"></div>
            </template>
            <template
                    v-slot:item.condExtra="{ item }"
            >

                <div v-html="determineUnusualType(item)"></div>

            </template>


            <template v-slot:footer>
                <div style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details><br><br><br><br>S - Sweep, SP - Split, C - Complex, O - Opening (Vol>OI), A - Ask, B - Bid, BB - Below Bid <br>
                    AA - Above Ask, LB - Lean Bid, LA - Lean Ask - All contracts are $25k+ prem for Stocks and $100k+ prem for ETFs <br>
                    Unusual - column identifies size of order to current OI. 25-50x means volume of order was 25-50 times the current OI.
                </div>
            </template>

        </v-data-table>
    </div>
</template>

<script>

    import {abbreviateNumber} from "@/helpers/formatting";
    import {FLOW_COLLECTION} from "@/firebase/config";
    import {mapActions} from "vuex";
    import {COOKIE_USER_AUTH, getCookie} from "@/utils/CookieHelper";
    import apiService from '@/api/daily-data';

    export default {
        name: "DashboardFlowTTG",
        props: {
            widgetMode: Boolean
        },
        beforeMount() {

            if (!this.widgetMode) {
                this.tableHeight = `${window.innerHeight - 270}`;
            }

        },
        async mounted() {

            var cookie = getCookie(COOKIE_USER_AUTH)
            if (!cookie){
                window.location.href = 'https://truetradinggroup.com/member-dashboard/options-dark-pool-access-link/';
            }


            let backlog = await apiService.getFlowDataTTG();
            this.printData = await backlog.json();
            this.prints = this.printData;
            let nowDate = new Date().getTime() / 1000

            // TODO: Stop this when you navigate to expanded view - current if you watch the console it keeps printing.
            this.flowListener = FLOW_COLLECTION.where('epochTime', '>', nowDate).onSnapshot(snapshot => {
                if (!snapshot.docChanges().empty) {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "added") {
                            //console.log("New doc: ", change.doc.data());
                            this.prints.unshift(change.doc.data());
                        }
                        if (change.type === "modified") {
                            console.log("Modified doc: ", change.doc.data());
                        }
                        if (change.type === "removed") {
                            console.log("Removed doc: ", change.doc.data());
                        }
                    });
                    //snapshot.docs.forEach((doc) => {
                        //this.prints.unshift(doc.data());
                        //console.log(length(doc.data()))
                    //});
              }
            }).bind(this);

            this.loading = false;
        },
        beforeDestroy() {
            //kill the listener
            this.flowListener();
        },
        data: () => ({
            loader: null,
            loading2: false,
            changeBackgroundColor: false,
            changeBackgroundColor2 : false,
            changeBackgroundColor3: false,
            changeBackgroundColor4 : false,
            lightPrintColorMap: {
                "Light_AA": "#005005",
                "Light_A": "#2e7d32",
                "Light_LA": "#60ad5e",
                "Light_BB": "#8e0000",
                "Light_B": "#c62828",
                "Light_LB": "#ff5f52",
                "Light_I": "#2180CE",
            },
            sectorColorMap: {
                "Basic Materials": "#666e18",
                "Communication Services": "#DE832F",
                "Consumer Cyclical": "#75206b",
                "Consumer Defensive": "#2F65DE",
                "Energy": "#025c1d",
                "Financial": "#3c4d3e",
                "Healthcare": "#1e3133",
                "Industrials": "#3f3c69",
                "Real Estate": "#693827",
                "Technology": "#2180CE",
                "Utilities": "#ab4f2b"
            },
            tableHeight: 0,
            loading: true,
            search: '',
            headers: [
                {
                    text: "Symbol",
                    align: 'center',
                    sortable: true,
                    value: 'symbol',
                },
                {
                    text: "Spot",
                    align: 'center',
                    sortable: true,
                    value: 'spot',
                },
                {
                    text: "Expiry",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'expiry',
                },
                {
                    text: "Strike",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'strike',
                },
                {
                    text: "Sum",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'sum',
                },
                {
                    text: "Time",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'epochTime',
                },
                {
                    text: "CP",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'cp',
                },
                {
                    text: "OI",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'oi',
                },
                {
                    text: "Trade",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'trade',
                },
                {
                    text: "Side",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'side',
                },
                {
                    text: "Cond",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'cond',
                },
                {
                    text: "Unusual",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'condExtra',
                },
            ],
            snapshotInitState: true,
            printData: [],
            prints: [],
        }),

        methods: {
            ...mapActions('elementVisibility', [
                'setLogoAndBottomNavVisibility'
            ]),
            getColor (item) {
                if (item.cp === "P") return 'rgba(255, 0, 0, 0.30)'
                else return 'rgba(0, 128, 0, .40)'

            },
            getSideColor (item) {
                if (item.side === 3)
                    return this.lightPrintColorMap.Light_AA;
                else if (item.side === 2) return this.lightPrintColorMap.Light_A;
                else if (item.side === 1) return this.lightPrintColorMap.Light_LA;
                else if (item.side === 0) return this.lightPrintColorMap.Light_I;
                else if (item.side === -1) return this.lightPrintColorMap.Light_LB;
                else if (item.side === -2) return this.lightPrintColorMap.Light_B;
                else if (item.side === -3) return this.lightPrintColorMap.Light_BB;
            },
            showDP(){
                this.$router.push({name: "DarkPoolFlowTTG"});
            },
            itemRowBackground: function (item) {
                return item.cp === "P" ? 'put' : 'call'
            },
            filterPerfectMatch(value, search) {
                return value != null && value.toString().toUpperCase() === search.toString().toUpperCase()
            },
            filter1() {
                this.prints = this.prints.filter(el => {
                    return (el.sum > 1000000);
                });
            },
            unusualGold() {
                this.prints = this.prints.filter(el => {
                    return (el.condExtra.includes(">50"));
                });
            },
            openingFilter() {
                this.prints = this.prints.filter(el => {
                    return (el.cond.includes("OPENING"));
                });
            },
            callsFilter() {
                this.prints = this.prints.filter(el => {
                    return (el.cp === "C");
                });
            },
            putsFilter() {
                this.prints = this.prints.filter(el => {
                    return (el.cp === "P");
                });
            },
            async clearFilter() {

                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                }, 5000);


                //TODO : Having this just reload the backlog here when they clear the filter, missing some when it does - this will cause a longer loadtime tho...
                // Maybe a better fix is to just have another array the data is stored in?
                let backlog = await apiService.getFlowDataTTG();
                this.prints = await backlog.json();
                this.changeBackgroundColor = false;
                this.changeBackgroundColor2 = false;
                this.changeBackgroundColor3 = false;
                this.changeBackgroundColor4 = false;
                this.changeBackgroundColor5 = false;
            },
            determinePrintType: function (print) {

                // TODO: Make these icons instead of text?
                //let exchange = print.exchange.split("|")[0];
                //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
                //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
                let lightText = this.$vuetify.breakpoint.smAndDown ? "AA" : "Above Ask"
                if (print.side === 3)
                    return this.$vuetify.breakpoint.smAndDown ? "AA" : `<span style="font-size:.7rem">ABOVE ASK</span>`;
                else if (print.side === 2) return this.$vuetify.breakpoint.smAndDown ? "A" : `<span style="font-size:.7rem">ASK</span>`;
                else if (print.side === 1) return this.$vuetify.breakpoint.smAndDown ? "LA" : `<span style="font-size:.7rem">LEAN ASK</span>`;
                else if (print.side === 0) return this.$vuetify.breakpoint.smAndDown ? "B" : `<span style="font-size:.7rem">BETWEEN</span>`;
                else if (print.side === -1) return this.$vuetify.breakpoint.smAndDown ? "LB" : `<span style="font-size:.7rem">LEAN BID</span>`;
                else if (print.side === -2) return this.$vuetify.breakpoint.smAndDown ? "B" : `<span style="font-size:.7rem">BID</span>`;
                else if (print.side === -3) return this.$vuetify.breakpoint.smAndDown ? "BB" : `<span style="font-size:.7rem">BELOW BID</span>`;
                else return lightText;
            },
            determineUnusualType: function (print) {

                // TODO: Make these icons instead of text?
                //let exchange = print.exchange.split("|")[0];
                //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
                //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
                let lightText = "";
                if (print.condExtra.includes("1-5")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white">1-5x</span>` : `<span style="color: white">1-5x</span>`;
                else if (print.condExtra.includes("5-10")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white; font-weight: bold">5-10x</span>` : `<span style="color: white; font-weight: bold">5-10x</span>`;
                else if (print.condExtra.includes("10-25")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white">10-25x</span>` : `<span style="color: white">10-25x</span>`;
                else if (print.condExtra.includes("25-50")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white">25-50x</span>` : `<span style="color: white">25-50x</span>`;
                else if (print.condExtra.includes(">50")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: gold">>50x</span>` : `<span style="color: gold">>50x</span>`;
                else return lightText;
            },
            determineCondType: function (print) {

                // TODO: Make these icons instead of text?
                //let exchange = print.exchange.split("|")[0];
                //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
                //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
                let lightText = "";
                if (print.cond.includes("OPENING")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: cyan">O</span>` : `<span style="color: cyan">OPENING</span>`;
                else if (print.cond.includes("COMPLEX")) return this.$vuetify.breakpoint.smAndDown ? "C" : "COMPLEX";
                else return lightText;
            },
            determineTradeType: function (print) {

                // TODO: Make these icons instead of text?
                //let exchange = print.exchange.split("|")[0];
                //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
                //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
                let lightText = "";
                if (print.trade.includes("SWEEP")) return this.$vuetify.breakpoint.smAndDown ? "S" : "SWEEP";
                else if (print.trade.includes("SPLIT")) return this.$vuetify.breakpoint.smAndDown ? "SP" : "SPLIT";
                else if (print.trade === ("")) return this.$vuetify.breakpoint.smAndDown ? "B" : "BLOCK";
                else return lightText;
            },
            formatDate: function (millis) {
                let date = new Date(millis);
                return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            },
            abbrNum: abbreviateNumber,
            toExpanded: function (value) {
                let stockInfo = {
                    ticker: value.symbol,
                };

                this.$router.push({
                    name: "StockExpandedTTG",
                    params: {
                        stockInfo: stockInfo
                    }
                });
            },
        },
        watch: {
            // TODO : this makes it so they can't click it a bunch. needs to spin tho and it doesnt
            loader () {
                const l = this.loader
                this[l] = !this[l]

                setTimeout(() => (this[l] = false), 3000)

                this.loader = null
            },
        },

    }
</script>

<style scoped>


    #streaming-container {
        height: 100%;
        width: 100%;
        background-color: #323232;
    }

    #page-controls {
        flex: none;
        padding: 16px 16px;
        width: 100%;
        flex: none;
    }

    h1 {
        text-align:center;
    }

    #page-heading {
        flex: none;
        padding: 16px;
        color: black;
        flex: none;
        width: 100%;
        background-color: #E5E5E5;
    }

    #streaming-controls {
        width: auto;
    }

    .rowClassCP {
        background-color: #3f87a6 !important;
    }

    #print-table {
        height: 100%;
        border-radius: 0 !important;
        width: 100%;
        overflow-y: auto;

        background-color: #323232;
        box-shadow: none;
    }

    .bid-ask-indicator {
        font-family: 'Verdana',  sans-serif !important;
    }

    .stock-sector-blip {
        height: 30px;
        width: 30px;
    }

    >>>th {
        padding: 16px 0 16px 14px !important;
    }
    >>>td {
        padding: 16px 0 !important;
    }
    #flow-button {
        position: absolute; right: 0;
        height: 62px;
        background-color: #404040;
        border: thin solid #CCCCCC;

    }


    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }

    >>>tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }

    >>>tbody tr.call {
        background-image: linear-gradient(90deg, rgba(0, 128, 0, .40) 5%, rgba(36,36,36,.05) 20%, rgba(36,36,36,.05) 80%, rgba(0, 128, 0, .40) 95%);
    }
    >>>tbody tr.put {
        background-image: linear-gradient(90deg, rgba(235, 0, 15, 0.40) 5%, rgba(36,36,36,.15) 20%, rgba(36,36,36,.15) 80%, rgba(255, 0, 0, .40) 95%);
    }

    >>>tr {
        text-align: center !important;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>