<template>
    <div id="streaming-container">


        <div id="row" class="flex-cl-center-start">
            <div class="flex-row-center-between">
                <h1>Options Profit Calculator</h1>
            </div>

            <div>
                <div id="inputContainers" style="padding-left: 10%; padding-right: 10%">
                    <v-form ref="inputs" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col
                                        cols="4"
                                        md="4"
                                >
                                    <v-autocomplete
                                            v-model="stock"
                                            :items="stocks"
                                            label="Stock"
                                            chips
                                            color="blue"
                                            required
                                            :filter="getList"
                                    >
                                    </v-autocomplete>
                                </v-col>

                                <v-col
                                        cols="4"
                                        md="4"
                                >
                                    <v-text-field
                                            v-model="form['strike']"
                                            :rules="strikeRules"
                                            label="Strike"
                                            required
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                        cols="4"
                                        md="4"
                                >
                                    <v-switch
                                            v-model="callPut"
                                            flat
                                            :label="determineLabel(callPut)"
                                    ></v-switch>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>


                    <v-form ref="optionalInputs" v-model="valid2">
                        <v-container>
                            <v-row>
                                Below are Optional Values - You can fill these out for more fine tuned Calculations:
                            </v-row>
                            <v-row>
                                <v-col
                                        cols="4"
                                        md="4"
                                >
                                    <v-text-field
                                            v-model="form['optionprice']"
                                            :rules="optionRules"
                                            label="Option Price"
                                            required
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                        cols="4"
                                        md="4"
                                >
                                    <v-text-field
                                            v-model="form['volatility']"
                                            :rules="volatilityRules"
                                            label="Volatility % Ex. - 54.66"
                                            required
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                        cols="4"
                                        md="4"
                                >
                                    <v-text-field
                                            v-model="form['stockprice']"
                                            :rules="stockpriceRules"
                                            label="Stock Price"
                                            required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </div>
                <div class="buttons">
                    <v-btn large v-on:click="getAllData()" class="ma-2 white--text"
                           id="Calculate">Calculate</v-btn>
                    <v-btn large v-on:click="clear()" class="ma-2 white--text"
                           id="Clear">Clear All</v-btn>
                    <v-btn large v-on:click="clearOptional()" class="ma-2 white--text"
                           id="Clear">Clear Optional</v-btn>
                </div>
                <highcharts id="chartContainer" class="chart" :options="chartOptions" :updateArgs="updateArgs" ref="highcharts"></highcharts>
                <v-col cols="12">
                    <v-subheader class="pl-0">
                        Adjust slider to see live changes in volatilty.
                    </v-subheader>
                    <v-slider
                            :disabled="disabled"
                            v-model="slider"
                            thumb-label="always"
                            max="200"
                            min="10"
                    ></v-slider>
                </v-col>

            </div>

        </div>
    </div>
</template>

<script>
    import { Chart } from "highcharts-vue";
    import Highcharts from "highcharts";
    var bs = require("black-scholes");
    import exportingInit from "highcharts/modules/exporting";
    import offlineExporting from "highcharts/modules/offline-exporting";
    import stockList from '../assets/stocks.json'
    import weeklyList from '../assets/weeklys.json'
    import apiService from '../api/daily-data';
    import {COOKIE_USER_AUTH, getCookie} from "@/utils/CookieHelper";

    exportingInit(Highcharts);
    offlineExporting(Highcharts);

    function getPointCategoryName(point, dimension) {
        var series = point.series,
            isY = dimension === 'y',
            axis = series[isY ? 'yAxis' : 'xAxis'];
        return axis.categories[point[isY ? 'y' : 'x']];
    }

    async function generateRangeCalls(x){
        var values = []
        var previousNumber = x - (x * .02);
        var i = 0;
        var bounds = 0;
        while (i < 31) {
            bounds = (x * .02 / 11) + previousNumber
            //console.log(Math.floor(bounds))
            //console.log(Math.round(bounds) + "t")
            values.push(bounds.toFixed(2))
            previousNumber = bounds
            i++;
        }
        return(values)
    }
    function getBusinessDatesCount(endDate) {
        let count = 0;
        const curDate = new Date();
        while (curDate <= endDate) {
            const dayOfWeek = curDate.getDay();
            if(dayOfWeek !== 0 && dayOfWeek !== 6){
                count++;
            }
            curDate.setDate(curDate.getDate() + 1);
        }
        return count;
    }

    function getNextDayOfTheWeek(dayName, excludeToday = true, refDate = new Date()) {
        const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
            .indexOf(dayName.slice(0,3).toLowerCase());
        if (dayOfWeek < 0) return;
        refDate.setHours(0,0,0,0);
        refDate.setDate(refDate.getDate() + +!!excludeToday +
            (dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7);
        return refDate;
    }

    export default {
        components: {
            highcharts: Chart
        },
        data () {
            return {
                model: null,
                stock: null,
                stocks: [
                    'Samson', 'Wichita', 'Combustion', 'Triton',
                    'Helios', 'Wimbeldon', 'Brixton', 'Iguana',
                    'Xeon', 'Falsy', 'Armagedon', 'Zepellin'],
                disabled : true,
                updateArgs: [true, false, false],
                slider : 60,
                rangeCalls : [],
                days : [],
                chart: undefined,
                chartVisibility : false,
                callPut: false,
                callorput : "call",
                stockRules: [
                    v => !!v || 'Stock symbol is required',
                ],
                strikeRules: [
                    v => !!v || 'Strike is required',
                    v => /\d+(\.\d+)?(?=$| )|\.\d+(?=$| )/.test(v) || "Don't include $",
                ],
                stockpriceRules: [
                    v => /\d+(\.\d+)?(?=$| )|\.\d+(?=$| )/.test(v) || "Don't include $",
                ],
                optionRules: [
                    v => /\d+(\.\d+)?(?=$| )|\.\d+(?=$| )/.test(v) || "Don't include $",
                ],
                volatilityRules: [
                    v => /\d+(\.\d+)?(?=$| )|\.\d+(?=$| )/.test(v) || "Don't include %",
                ],

                valid : false,
                valid2 : false,
                stockPrice : 0,
                optionPrice : 0,
                strikePrice : 0,
                volatility : 0,
                form: {},
                data : [],
                prices: [],
                chartOptions: {
                    boost: {
                        useGPUTranslations: false,
                        // Chart-level boost when there are more than 5 series in the chart
                        seriesThreshold: 3
                    },
                    chart: {
                        type: 'heatmap',
                        height: 600,
                        plotBorderWidth: 1,
                        animation: false
                    },
                    exporting: {
                        chartOptions: { // specific options for the exported image
                            plotOptions: {
                                series: {
                                    dataLabels: {
                                        enabled: true
                                    }
                                }
                            }
                        },
                        fallbackToExportServer: false
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: true
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    title: {
                        text: ''
                    },

                    xAxis: {
                        categories: ['3', '2', '1', '0'],
                        labels: {
                            style: {
                                color: 'white'
                            }
                        }
                    },

                    yAxis: {
                        plotLines: [{
                            color: 'black', // Color value
                            dashStyle: 'solid', // Style of the plot line. Default to solid
                            value: 10.5, // Value of where the line will appear
                            width: 6, // Width of the line
                            zIndex: 3,
                            label: {
                                // TODO - put stock price here
                                text: '48.59',
                                align: 'left',
                                x: 10
                            }
                        }],
                        categories: [],
                        title: null,
                        //reversed: true
                    },

                    accessibility: {
                        point: {
                            descriptionFormatter: function (point) {
                                var ix = point.index + 1,
                                    xName = getPointCategoryName(point, 'x'),
                                    yName = getPointCategoryName(point, 'y'),
                                    val = point.value;
                                return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.';
                            }
                        }
                    },
                    colorAxis: {
                        min: -125,
                        max: 125,
                        stops: [],
                    },

                    tooltip: {
                        formatter: function () {
                            return '<b>' + "$" +
                                this.point.value + '</b> dollars at <br><b>' + getPointCategoryName(this.point, 'y') + '</b>';
                        }
                    },

                    series: [{
                        name: 'Options Profit Calculator',
                        boostThreshold: 1,
                        borderWidth: 1,
                        data: [],
                        dataLabels: {
                            enabled: true,
                            padding: 0,
                            opacity: 0,
                            shadow: false,
                            style: {
                                fontWeight: 'bold',
                                fontFamily: 'Verdana',
                                textOutline: false
                            },
                            color: 'rgba(241,229,229,0.95)'
                        }
                    }],
                }
            }
        },
        mounted(){

            var cookie = getCookie(COOKIE_USER_AUTH)
            if (!cookie){
                window.location.href = 'https://truetradinggroup.com/member-dashboard/options-dark-pool-access-link/';
            }

            var result = [];

            for(var i in stockList)
                result.push(stockList[i].ticker);


            this.stocks = result;



        },
        methods: {
            getList: function  (item, queryText, itemText) {
                if (queryText.length <= 1){
                    return itemText.toLocaleLowerCase() == queryText.toLocaleLowerCase()
                }else{
                    return itemText.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase())
                }
            },
            determineLabel: function (print) {
                //console.log(print)
                this.callorput = "put"
                var put = "Put"
                if (print === false) {
                    this.callorput = "call"
                    return "Call"
                }
                else return put;
            },
            GetNextMonthsThirdFriday: function (input){
                var parts = input.split('/');

                var date = new Date(parts[2], parts[0], 1);

                var fridayCount = 0;

                while (fridayCount < 3){
                    if (date.getDay() === 5){
                        break;
                    }

                    date = new Date(date.getYear() + 1900, date.getMonth(), (date.getDate() + 1));
                }

                return new Date(date.getYear() + 1900, date.getMonth(), (date.getDate() + 14));
            },
            getAllData: function () {
                //console.info(this.form)
                this.data = [];
                //console.log(this.stock)


                // calculate expiration date
                var dateNow = new Date()


                const holidays = ["2022-01-17","2022-02-21","2022-05-30","2022-06-20","2022-07-04","2022-09-05"]
                const fridayHolidays = ["2022-05-15"]
                const expirDatesFriday = ["2022-05-14"]




                // check weekly and holiday list

                //var holidayBool = false
                var weekly = false;

                //if this.stock in weekly
                for (var i in weeklyList) {
                    if (this.stock === weeklyList[i]['Stock Symbol']) {
                        weekly = true;
                    }
                }


                var arraycontainsDate;
                var strFriday;
                var friday;
                var thirdFriday;
                var strThirdFriday;
                var expirDate;
                var curDate2 = new Date().toJSON().split("T")[0];


                if (weekly) {
                    friday = getNextDayOfTheWeek("Friday", false);
                    strFriday = friday.toLocaleDateString('en-CA')
                    arraycontainsDate = (fridayHolidays.indexOf(strFriday) > -1);
                    if (arraycontainsDate) {
                        friday = getNextDayOfTheWeek("Thursday", false);
                        strFriday = friday.toLocaleDateString('en-CA')
                    }
                    //console.log(strFriday)
                    expirDate = strFriday
                } else {
                    dateNow = dateNow.toLocaleDateString()
                    thirdFriday = this.GetNextMonthsThirdFriday(dateNow)
                    strThirdFriday = thirdFriday.toLocaleDateString('en-CA')
                    arraycontainsDate = (fridayHolidays.indexOf(strThirdFriday) > -1);
                    if (arraycontainsDate) {
                        thirdFriday = getNextDayOfTheWeek("Thursday", false);
                        strThirdFriday = thirdFriday.toLocaleDateString('en-CA')
                    }
                    //console.log(strThirdFriday)
                    expirDate = strThirdFriday
                }
                // if not weekly then

                // TODO - make this work for holidays then home freeeeee
                var endDate = new Date(expirDate)
                var numOfDates = getBusinessDatesCount(endDate);

                //console.log(numOfDates);

                // calc the days for 3 and 4 also
                const curDate = new Date();
                let day1 = curDate.getDay();
                let dayisit = curDate.getDay();
                const expirDate2 = new Date(expirDate).toJSON().split("T")[0];
                var holidayBool = false
                this.days = []

                if (numOfDates < 6){
                    //console.log("weekly run")
                    //console.log("Curent Day = " + (day1))
                    if (holidays.includes(curDate2)){
                        day1 = 2
                    }
                    if (expirDatesFriday.includes(expirDate2)){
                        holidayBool = true
                    }
                }else{
                    //console.log("longer run")
                    var dte = Number(numOfDates)
                    if (expirDatesFriday.includes(expirDate2)){
                        dte = dte - 1
                        holidayBool = true
                    }
                    var count = 0
                    day1 = dte
                    while (count < 5) {
                        var day = dte - 1
                        dte = dte - 1
                        this.days.push(day)
                        count++;
                    }
                }

                //figure out how many days to run
                if (day1 == 1){
                    this.days = [4,3,2,1,.5,0]
                    this.chartOptions.xAxis.categories = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Expiration']
                    if (holidayBool){
                        this.days = [3,2,1,.5,0]
                        this.chartOptions.xAxis.categories = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Expiration']
                    }
                }
                else if (day1 == 2){
                    this.days = [3,2,1,.5,0]
                    this.chartOptions.xAxis.categories = ['Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Expiration']
                    if (holidayBool){
                        this.days = [2,1,.5,0]
                        this.chartOptions.xAxis.categories = ['Tuesday', 'Wednesday', 'Thursday', 'Expiration']
                    }

                }
                else if (day1 == 3){
                    this.days = [2,1,.5,0]
                    this.chartOptions.xAxis.categories = ['Wednesday', 'Thursday', 'Friday', 'Expiration']
                    if (holidayBool){
                        this.days = [1,.5,0]
                        this.chartOptions.xAxis.categories = ['Wednesday', 'Thursday', 'Expiration']
                    }
                }
                else if (day1 == 4){
                    this.days = [1,.5,0]
                    this.chartOptions.xAxis.categories = ['Thursday', 'Friday', 'Expiration']
                    if (holidayBool){
                        this.days = [.5,0]
                        this.chartOptions.xAxis.categories = ['Thursday', 'Expiration']
                    }
                }
                else if (day1 == 5  && !holidayBool){
                    this.days = [.5,0]
                    this.chartOptions.xAxis.categories = ['Friday', 'Expiration']
                }
                    // check that this fixes saturday and sunday run - Checking day1 as 0 and 6 to get it to monday. Need to
                // do this for other saturday and sunday stuff too
                else if ((day1 == 1 || day1==0 || day1==6) && holidayBool){
                    this.days = [3,2,1,.5,0]
                    this.chartOptions.xAxis.categories = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Expiration']
                }
                else if (day1 > 6){
                    // what day is it
                    //console.log(dayisit)
                    switch (dayisit){
                        case 1:
                            this.chartOptions.xAxis.categories = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
                            break;
                        case 2:
                            this.chartOptions.xAxis.categories = ['Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Monday']
                            break;
                        case 3:
                            this.chartOptions.xAxis.categories = ['Wednesday', 'Thursday', 'Friday', 'Monday', 'Tuesday']
                            break;
                        case 4:
                            this.chartOptions.xAxis.categories = ['Thursday', 'Friday', 'Monday', 'Tuesday','Wednesday']
                            break;
                        case 5:
                            this.chartOptions.xAxis.categories = ['Friday', 'Monday', 'Tuesday','Wednesday','Thursday']
                            break;
                        default:
                            this.chartOptions.xAxis.categories = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
                            break;
                    }
                }
                else{
                    this.days = [4,3,2,1,.5,0]
                    this.chartOptions.xAxis.categories = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Expiration']
                }






                if (this.valid === true && this.valid2 === false) {
                    this.disabled = false;

                    this.strikePrice = parseFloat(this.form['strike'])
                    this.slider = 50

                    const marketOpen = 9 * 60 + 45 // minutes
                    const marketClosed = 20 * 60 + 30 // minutes
                    var now = new Date();
                    var currentTime = now.getHours() * 60 + now.getMinutes(); // Minutes since Midnight
                    //console.log(marketOpen)
                    //console.log(marketClosed)
                    //console.log(currentTime)
                    //console.log(now.getDay())
                    if ((currentTime > marketOpen && currentTime < marketClosed) && (now.getDay() != 6 || now.getDay() != 0)) {
                        this.getOratsData(this.stock, expirDate, this.strikePrice);
                    } else {
                        this.getOratsDataHist(this.stock, expirDate, this.strikePrice);
                    }

                } else if (this.valid === true && this.valid2 === true) {
                    this.optionPrice = parseFloat(this.form['optionprice'])
                    this.stockPrice = parseFloat(this.form['stockprice'])
                    this.strikePrice = parseFloat(this.form['strike'])
                    this.volatility = parseFloat(this.form['volatility']) / 100
                    this.slider = parseFloat(this.form['volatility'])

                    this.disabled = false;
                    this.getData(expirDate);
                } else {
                    console.log("forms aren't filled out")
                }
                // axios.post('/some/url', this.form)
            },
            clear: function (){
                this.chartVisibility = false;
                this.$refs.inputs.reset();
                this.$refs.optionalInputs.reset();
                this.callPut = false;
                this.callorput = "call";
            },
            clearOptional: function (){
                this.chartVisibility = false;
                this.$refs.optionalInputs.reset();
            },
            getData: async function (expirDate) {
                let self = this;
                //var curDate2 = new Date().toJSON().split("T")[0];
                //var expirDate2 = new Date(expirDate).toJSON().split("T")[0];

                let i = 30;
                var whatDay = 0;

                var profit = 0;
                //console.log(now)

                this.rangeCalls = await generateRangeCalls(this.stockPrice)
                //var rangePuts = await generateRangePuts(this.stockPrice)
                //console.log(this.rangeCalls)

                for (const day of this.days){
                    //console.log(day);

                    while (i > -1) {

                        var x = bs.blackScholes(this.rangeCalls[i], this.strikePrice, day/365, this.volatility, .25, this.callorput);
                        //console.log(x, this.rangeCalls[i], this.strikePrice)
                        self.prices.unshift(Math.round(this.rangeCalls[i] * 100) / 100)

                        profit = Math.round((x-this.optionPrice)*100)

                        if (isNaN(profit)) {
                            profit = (this.optionPrice * -1) * 100
                        }

                        self.data.push([whatDay,i,+profit.toFixed(2)])
                        i--;
                    }
                    whatDay++;
                    i=30;


                }

                const counts = this.rangeCalls;

                const goal = this.stockPrice;

                const output = counts.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
                let index = counts.findIndex(price => price === output);

                // set line of current price
                this.chartOptions.yAxis.plotLines[0].value = index + 0.5
                this.chartOptions.yAxis.plotLines[0].label.text = this.stockPrice
                // set the categories for the price
                this.chartOptions.yAxis.categories = this.rangeCalls

                this.chartOptions.colorAxis.max = this.data[0][2]
                this.chartOptions.colorAxis.min = this.data[(this.data.length-1)][2]
                this.chartOptions.colorAxis.stops = [
                    [0.1, 'rgba(179,29,29,0.91)'],
                    [0.4, 'rgb(113,111,111)'],
                    [0.9, 'rgba(14,83,26,0.91)'],
                ];
                if (this.data[0][2] < 0){
                    this.chartOptions.colorAxis.stops = [
                        [0.1, 'rgba(179,29,29,0.91)'],
                        [0.9, 'rgb(113,111,111)'],
                        [0.9, 'rgba(14,83,26,0.91)'],
                    ];
                }else{
                    this.chartOptions.colorAxis.stops = [
                        [0.1, 'rgba(179,29,29,0.91)'],
                        [0.4, 'rgb(113,111,111)'],
                        [0.9, 'rgba(14,83,26,0.91)'],
                    ];
                }
                //console.log(this.data)

                this.chartOptions.title.text = this.stock + " - Expiration Date - " + expirDate



                // put the data on the heatmap chart
                //console.log(this.data)
                this.chartOptions.series[0].data = this.data

            },
            getOratsData: async function (stock, expirDate, strike) {
                let self = this;

                let data = await apiService.oratsData(stock, expirDate, strike);
                var stockData = await data.json();

                //console.log(stockData)

                try {
                    this.stockPrice = stockData[0]['spotPrice']
                }
                catch(err) {
                    this.$alert("Error or Double Check the Strike Price is correct.");
                }

                this.strikePrice = strike
                //console.log(this.callorput)
                if (this.callorput === "call"){
                    this.volatility = stockData[stockData.length-1]['callMidIv']
                    this.optionPrice = stockData[stockData.length-1]['callValue']
                    this.$set(this.form,'optionprice',this.optionPrice.toFixed(2))
                    this.$set(this.form,'volatility',(this.volatility * 100).toFixed(2))
                }else{
                    this.volatility = stockData[stockData.length-1]['putMidIv']
                    this.optionPrice = stockData[stockData.length-1]['putValue']
                    this.$set(this.form,'optionprice',this.optionPrice.toFixed(2))
                    this.$set(this.form,'volatility',(this.volatility * 100).toFixed(2))
                }

                this.$set(this.form,'stockprice',this.stockPrice.toFixed(2))




                //var curDate2 = new Date().toJSON().split("T")[0];
                //var expirDate2 = new Date(expirDate).toJSON().split("T")[0];



                let i = 30;
                var whatDay = 0;

                var profit = 0;

                this.rangeCalls = await generateRangeCalls(this.stockPrice)
                //var rangePuts = await generateRangePuts(this.stockPrice)
                //console.log(this.rangeCalls)

                ////console.log("DAta")
                //console.log(this.strikePrice)
                //console.log(this.optionPrice)


                for (const day of this.days){
                    //console.log(day);

                    while (i > -1) {

                        var x = bs.blackScholes(this.rangeCalls[i], this.strikePrice, day/365, this.volatility, .25, this.callorput);
                        //console.log(x, this.rangeCalls[i], this.strikePrice)
                        self.prices.unshift(Math.round(this.rangeCalls[i] * 100) / 100)

                        profit = Math.round((x-this.optionPrice)*100)

                        if (isNaN(profit)) {
                            profit = (this.optionPrice * -1) * 100
                        }

                        self.data.push([whatDay,i,+profit.toFixed(2)])
                        i--;
                    }
                    whatDay++;
                    i=30;


                }

                const counts = this.rangeCalls;

                const goal = this.stockPrice;

                const output = counts.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
                let index = counts.findIndex(price => price === output);

                // set line of current price
                this.chartOptions.yAxis.plotLines[0].value = index + 0.5
                this.chartOptions.yAxis.plotLines[0].label.text = this.stockPrice
                // set the categories for the price
                this.chartOptions.yAxis.categories = this.rangeCalls

                this.chartOptions.colorAxis.max = this.data[0][2]
                this.chartOptions.colorAxis.min = this.data[(this.data.length-1)][2]
                this.chartOptions.colorAxis.stops = [
                    [0.1, 'rgba(179,29,29,0.91)'],
                    [0.4, 'rgb(113,111,111)'],
                    [0.9, 'rgba(14,83,26,0.91)'],
                ];
                if (this.data[0][2] < 0){
                    this.chartOptions.colorAxis.stops = [
                        [0.1, 'rgba(179,29,29,0.91)'],
                        [0.9, 'rgb(113,111,111)'],
                        [0.9, 'rgba(14,83,26,0.91)'],
                    ];
                }else{
                    this.chartOptions.colorAxis.stops = [
                        [0.1, 'rgba(179,29,29,0.91)'],
                        [0.4, 'rgb(113,111,111)'],
                        [0.9, 'rgba(14,83,26,0.91)'],
                    ];
                }

                this.chartOptions.title.text = this.stock + " - Expiration Date - " + expirDate


                // put the data on the heatmap chart
                this.chartOptions.series[0].data = this.data

            },
            getOratsDataHist: async function (stock, expirDate, strike) {
                let self = this;

                let data = await apiService.oratsDataHist(stock, expirDate, strike);
                var stockData = await data.json();

                console.log(stockData.length)


                try {
                    this.stockPrice = stockData[stockData.length-1]['spotPrice']
                }
                catch(err) {
                    this.$alert("Error or Double Check the Strike Price is correct.");
                }
                this.strikePrice = strike
                //console.log(this.callorput)
                if (this.callorput === "call"){
                    this.volatility = stockData[stockData.length-1]['callMidIv']
                    this.optionPrice = stockData[stockData.length-1]['callValue']
                    this.$set(this.form,'optionprice',this.optionPrice.toFixed(2))
                    this.$set(this.form,'volatility',(this.volatility * 100).toFixed(2))
                }else{
                    this.volatility = stockData[stockData.length-1]['putMidIv']
                    this.optionPrice = stockData[stockData.length-1]['putValue']
                    this.$set(this.form,'optionprice',this.optionPrice.toFixed(2))
                    this.$set(this.form,'volatility',(this.volatility * 100).toFixed(2))
                }


                this.$set(this.form,'stockprice',this.stockPrice.toFixed(2))




                let i = 30;
                var whatDay = 0;

                var profit = 0;
                //console.log(now)

                this.rangeCalls = await generateRangeCalls(this.stockPrice)
                //var rangePuts = await generateRangePuts(this.stockPrice)
                //console.log(this.rangeCalls)

                ////console.log("DAta")
                //console.log(this.strikePrice)
                //console.log(this.optionPrice)


                for (const day of this.days){
                    //console.log(day);

                    while (i > -1) {

                        var x = bs.blackScholes(this.rangeCalls[i], this.strikePrice, day/365, this.volatility, .25, this.callorput);
                        //console.log(x, this.rangeCalls[i], this.strikePrice)
                        self.prices.unshift(Math.round(this.rangeCalls[i] * 100) / 100)

                        profit = Math.round((x-this.optionPrice)*100)

                        if (isNaN(profit)) {
                            profit = (this.optionPrice * -1) * 100
                        }

                        self.data.push([whatDay,i,+profit.toFixed(2)])
                        i--;
                    }
                    whatDay++;
                    i=30;

                }

                const counts = this.rangeCalls;

                const goal = this.stockPrice;

                const output = counts.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
                let index = counts.findIndex(price => price === output);

                // set line of current price
                this.chartOptions.yAxis.plotLines[0].value = index + 0.5
                this.chartOptions.yAxis.plotLines[0].label.text = this.stockPrice
                // set the categories for the price
                this.chartOptions.yAxis.categories = this.rangeCalls

                this.chartOptions.colorAxis.max = this.data[0][2]
                this.chartOptions.colorAxis.min = this.data[(this.data.length-1)][2]
                this.chartOptions.colorAxis.stops = [
                    [0.1, 'rgba(179,29,29,0.91)'],
                    [0.4, 'rgb(113,111,111)'],
                    [0.9, 'rgba(14,83,26,0.91)'],
                ];
                if (this.data[0][2] < 0){
                    this.chartOptions.colorAxis.stops = [
                        [0.1, 'rgba(179,29,29,0.91)'],
                        [0.9, 'rgb(113,111,111)'],
                        [0.9, 'rgba(14,83,26,0.91)'],
                    ];
                }else{
                    this.chartOptions.colorAxis.stops = [
                        [0.1, 'rgba(179,29,29,0.91)'],
                        [0.4, 'rgb(113,111,111)'],
                        [0.9, 'rgba(14,83,26,0.91)'],
                    ];
                }
                this.chartOptions.title.text = this.stock + " - Expiration Date - " + expirDate


                // put the data on the heatmap chart
                this.chartOptions.series[0].data = this.data

            },
            getDataUpdated: function (sliderVal) {
                let i = 30;
                var whatDay = 0;

                var profit = 0;
                var dataNew = []
                for (const day of this.days){
                    //console.log(day);

                    while (i > -1) {

                        var x = bs.blackScholes(this.rangeCalls[i], this.strikePrice, day/365, sliderVal/100, .25, this.callorput);
                        //console.log(x, this.rangeCalls[i], this.strikePrice)
                        //self.prices.unshift(Math.round(this.rangeCalls[i] * 100) / 100)

                        //profit = ((x-this.optionPrice)*100)
                        // bitwise - supposed to be faster
                        //profit = ~~profit
                        profit = Math.round((x-this.optionPrice)*100)

                        if (isNaN(profit)) {
                            profit = (this.optionPrice * -1) * 100
                        }

                        dataNew.push([whatDay,i,+profit])
                        i--;
                    }
                    whatDay++;
                    i=30;


                }
                return dataNew;

            }
        },
        created () {
            let i = document.createElement('input')
            i.setAttribute('type', 'color');
            (i.type === 'color') ? this.colorInputIsSupported = true : this.colorInputIsSupported = false
        },
        watch: {
            chartOptions (newValue) {
                this.chartOptions = newValue
            },
            slider (newValue) {

                let chart = this.$refs.highcharts.chart;
                this.slider = newValue

                var dataNew = this.getDataUpdated(this.slider)

                chart.redraw(false);
                chart.series[0].setData(dataNew)

            },
            points (newValue) {
                this.chartOptions.series[0].data = newValue
            },
            data (newValue) {
                this.chartOptions.series[0].data = newValue
            },
            chartType (newValue) {
                this.chartOptions.chart.type = newValue.toLowerCase()
            },
            seriesColor (newValue) {
                this.chartOptions.series[0].color = newValue.toLowerCase()
            },
            animationDuration (newValue) {
                this.updateArgs[2].duration = Number(newValue)
            }
        }
    }
</script>

<style scoped>

    #streaming-container {
        height: 100%;
        width: 100%;
        background-color: #323232;
        padding: 3%;
    }

    .inputContainers[style] {
        padding-left: 20% !important;
        padding-right: 20% !important;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .highcharts-figure,
    .highcharts-data-table table {
        min-width: 320px;
        max-width: 400px;
        margin: 1em auto;
    }

    .highcharts-data-table table {
        font-family: Verdana, sans-serif;
        border-collapse: collapse;
        border: 1px solid #ebebeb;
        margin: 10px auto;
        text-align: center;
        width: 50%;
        max-width: 300px;
    }

    .highcharts-data-table caption {
        padding: 1em 0;
        font-size: 1.2em;
        color: #555;
    }

    .highcharts-data-table th {
        font-weight: 600;
        padding: 0.5em;
    }

    .highcharts-data-table td,
    .highcharts-data-table th,
    .highcharts-data-table caption {
        padding: 0.5em;
    }

    .highcharts-data-table thead tr,
    .highcharts-data-table tr:nth-child(even) {
        background: #f8f8f8;
    }

    .highcharts-data-table tr:hover {
        background: #f1f7ff;
    }

    .v-select__slot{
        height: 32px !important;
    }

    h1 {
        text-align: center;
    }

</style>