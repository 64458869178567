<template>
    <div id="dp-prints-container">
        <h2 class="section-header">Institutional Changes</h2>

        <v-skeleton-loader
                style="width: 100%; height: 100%;"
                v-if="loading"
                type="table-tbody"
        ></v-skeleton-loader>

        <v-data-table
                dark
                v-else
                id="print-table"
                :headers="headers"
                :items="prints"
                :items-per-page="15"
                dense
                calculate-widths
                mobile-breakpoint="200"
        >
            <template
                    v-slot:item.origName="{ item }"
            >
                {{ item.origName }}
            </template>

            <template
                    v-slot:item.AsharesSum1="{ item }"
            >

                {{ Number(item.AsharesSum1).toLocaleString() }}

            </template>

            <template
                    v-slot:item.BsharesSum2="{ item }"
            >
                {{ item.BsharesSum2.toLocaleString() }}
            </template>

            <template
                    v-slot:item.difference="{ item }"
            >
                {{ item.difference.toLocaleString() }}
            </template>
            <template
                    v-slot:item.percentDif="{ item }"
            >
                {{ (item.percentDif.toFixed(2)).toLocaleString() }}
            </template>


        </v-data-table>
    </div>
</template>

<script>
    import apiService from "@/api/daily-data";

    export default {
        name: "ShortInterestTabTTG",
        props: {
            ticker: String
        },
        async mounted() {
            let oiData = await apiService.getInstData(this.ticker);
            this.printData = await oiData.json();
            this.prints = this.printData;
            this.loading = false;

        },
        data: () => ({
            loading: true,
            headers: [
                {
                    text: "Firm",
                    align: 'center',
                    sortable: true,
                    value: "origName",
                },
                {
                    text: "Cur Holdings",
                    align: 'center',
                    sortable: true,
                    value: "AsharesSum1",
                },
                {
                    text: "Prev Holdings",
                    align: 'center',
                    sortable: true,
                    value: "BsharesSum2",
                },
                {
                    text: "% Dif",
                    align: 'center',
                    sortable: true,
                    value: "percentDif",
                },
            ],
            prints: [],
        }),

    }
</script>

<style scoped>

    #dp-prints-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    #print-table {
        padding-top: 10px;
        width: 100%;
        background-color: #4C4C4C;
    }

    >>>td {
        padding: 16px 0 !important;
    }

    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }


</style>