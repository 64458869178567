var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dp-prints-container"}},[_c('h2',{staticClass:"section-header"},[_vm._v("Individual Flow Prints")]),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"dark":"","id":"print-table","headers":_vm.headers,"items":_vm.prints,"items-per-page":5,"dense":"","calculate-widths":"","mobile-breakpoint":"200"},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" ")]}},{key:"item.spot",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("$" + (item.spot.toFixed(2))))+" ")]}},{key:"item.expiry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.expiry)+" ")]}},{key:"item.strike",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.strike)+" ")]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.abbrNum(item.sum))+" ")]}},{key:"item.epochTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.epochTime*1000))+" ")]}},{key:"item.cp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cp)+" ")]}},{key:"item.oi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.oi).toLocaleString())+" ")]}},{key:"item.trade",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.trade)+" ")]}},{key:"item.side",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.determinePrintType(item))}})]}},{key:"item.cond",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.determineCondType(item))}})]}},{key:"item.condExtra",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.determineUnusualType(item))}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }