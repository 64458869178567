import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const configOptions = {
    apiKey: "AIzaSyAOTfJngbh3uYJvB09ELbIRSuW0s0IknUk",
    authDomain: "darksweep-7c212.firebaseapp.com",
    databaseURL: "https://darksweep-7c212-default-rtdb.firebaseio.com",
    projectId: "darksweep-7c212",
    storageBucket: "darksweep-7c212.appspot.com",
    messagingSenderId: "300225228901",
    appId: "1:300225228901:web:cba6e4002ce09b90d8168c",
    measurementId: "G-RDHKE0LZRS"
};
firebase.initializeApp(configOptions);

// Utils
const DB = firebase.firestore();
const AUTH = firebase.auth();


// Collection references
const TOTALS_COLLECTION = DB.collection('totals');
const ORDERS_COLLECTION = DB.collection('orders');
const FLOW_COLLECTION = DB.collection('flow');
const CRYPTO_COLLECTION = DB.collection('crypto');
const USER_META_COLLECTION = DB.collection('UserMeta');

export {
    DB,
    AUTH,
    TOTALS_COLLECTION,
    ORDERS_COLLECTION,
    FLOW_COLLECTION,
    USER_META_COLLECTION,
    CRYPTO_COLLECTION
}