<template>
    <div id="dp-prints-container">
        <v-card-title>
            <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search..."
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>

        <v-skeleton-loader
                style="width: 100%; height: 100%;"
                v-if="loading"
                type="table-tbody"
        ></v-skeleton-loader>

        <v-data-table
                dark
                v-else
                id="print-table"
                :headers="headers"
                :search="search"
                :custom-filter="filterPerfectMatch"
                :items="prints"
                :items-per-page="15"
                dense
                calculate-widths
                mobile-breakpoint="200"
        >
            <template
                    v-slot:item.ticker="{ item }"
            >
                {{ item.ticker}}
            </template>
            <template
                    v-slot:item.companyName="{ item }"
            >
                {{ item.companyName}}
            </template>
            <template
                    v-slot:item.industry="{ item }"
            >
                {{ item.industry}}
            </template>
            <template
                    v-slot:item.marketCap="{ item }"
            >
                {{ (item.marketCap * 1000000).toLocaleString()}}
            </template>
            <template
                    v-slot:item.sharesFloat="{ item }"
            >

                {{ (Number(item.sharesFloat) * 1000000).toLocaleString() }}

            </template>

            <template
                    v-slot:item.shortExemptVolume="{ item }"
            >
                {{ item.shortExemptVolume.toLocaleString() }}
            </template>

            <template
                    v-slot:item.totalVolume="{ item }"
            >
                {{ item.totalVolume.toLocaleString() }}
            </template>
            <template
                    v-slot:item.feeRate="{ item }"
            >
                {{ item.feeRate.toFixed(2) }}%
            </template>

        </v-data-table>
    </div>
</template>

<script>
    import apiService from "@/api/daily-data";

    export default {
        name: "HighFloatShort",
        props: {
            ticker: String
        },
        async mounted() {
            let oiData = await apiService.getHighShorts();
            this.printData = await oiData.json();
            this.prints = this.printData;
            this.loading = false;

        },
        data: () => ({
            loading: true,
            search: '',
            headers: [
                {
                    text: "Ticker",
                    align: 'left',
                    sortable: true,
                    value: "ticker",
                },
                {
                    text: "Company",
                    align: 'left',
                    sortable: true,
                    value: "company",
                },
                {
                    text: "Industry",
                    align: 'left',
                    sortable: true,
                    value: "industry",
                },
                {
                    text: "Market Cap",
                    align: 'right',
                    sortable: true,
                    value: "marketCap",
                },
                {
                    text: "Shares Float",
                    align: 'right',
                    sortable: true,
                    value: "sharesFloat",
                },
                {
                    text: "Float Short %",
                    align: 'right',
                    sortable: true,
                    value: "floatShort",
                },
                {
                    text: "Average Vol",
                    align: 'right',
                    sortable: true,
                    value: "avgVolume",
                },
                {
                    text: "RSI - 14",
                    align: 'right',
                    sortable: true,
                    value: "rsi14",
                },
            ],
            prints: [],
        }),
        methods: {
            filterPerfectMatch(value, search) {
                if (value.length <= 1){
                    return value.toString().toLocaleLowerCase() == search.toString().toLocaleLowerCase()
                }else{
                    return value.toString().toLocaleLowerCase().startsWith(search.toString().toLocaleLowerCase())
                }

                //return value != null && value.toString().toUpperCase() === search.toString().toUpperCase()
            },
        },

    }
</script>

<style scoped>


    #dp-prints-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

    }

    #print-table {
        width: 100%;
        background-color: #4C4C4C;
        padding: 10px;
    }

    >>>th {
        padding: 16px 0 16px 14px !important;
    }
    >>>td {
        padding: 16px 0 !important;
    }

    .v-card__subtitle, .v-card__text, .v-card__title {
        padding: 0px !important;
    }

    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }

    >>>tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }

</style>