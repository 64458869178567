<template>
    <div id="streaming-container" class="flex-cl-center-start">
        <div v-if="!widgetMode" id="page-controls" class="flex-row-center-between">
            <v-btn
                    icon
                    @click="() => this.$router.go(-1)"
                    class="stage-buttons"
                    style="margin-right: 0; height: 45px !important; width: 45px !important;"
                    dark
                    small
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </div>
        <div class="flex-row-center-between">
            <h1>Short Data</h1>
            <div style="font-size:.75em" class="pa-0 pl-2">Data is currently updated 1-2 times per day<br><br>
            </div>
            <ShortData/>


        </div>
    </div>



</template>

<script>

    import ShortData from "@/components/user-pages/stock-expanded/ShortData/ShortData";
    import {COOKIE_USER_AUTH, getCookie} from "@/utils/CookieHelper";



    export default {
        name: "DashboardShort",

        beforeMount() {
        },

        mounted() {
            var cookie = getCookie(COOKIE_USER_AUTH)
            if (!cookie){
                window.location.href = 'https://truetradinggroup.com/member-dashboard/options-dark-pool-access-link/';
            }
        },

        beforeDestroy() {
        },

        data: () => ({
            stock: null,
        }),
        components: {ShortData}
    }
</script>

<style>

    #streaming-container {
        height: 100%;
        width: 100%;
    }

    #page-controls {
        flex: none;
        padding: 16px 16px;
        width: 100%;
        flex: none;
    }

    h1 {
        text-align:center;
    }

</style>