<template>
    <div id="dp-prints-container">
        <v-card-title>
            <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search..."
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>

        <v-skeleton-loader
                style="width: 100%; height: 100%;"
                v-if="loading"
                type="table-tbody"
        ></v-skeleton-loader>

        <v-data-table
                dark
                v-else
                id="print-table"
                :headers="headers"
                :items="prints"
                :search="search"
                :custom-filter="filterPerfectMatch"
                :items-per-page="15"
                dense
                calculate-widths
                mobile-breakpoint="200"
        >
            <template
                    v-slot:item.ticker="{ item }"
            >
                {{ item.ticker}}
            </template>
            <template
                    v-slot:item.date="{ item }"
            >
                {{ new Date(item.date).toLocaleDateString("en-US", {timeZone: 'UTC'}) }}
            </template>

            <template
                    v-slot:item.shortVolume="{ item }"
            >

                {{ Number(item.shortVolume).toLocaleString() }}

            </template>

            <template
                    v-slot:item.shortExemptVolume="{ item }"
            >
                {{ item.shortExemptVolume.toLocaleString() }}
            </template>

            <template
                    v-slot:item.totalVolume="{ item }"
            >
                {{ item.totalVolume.toLocaleString() }}
            </template>
            <template
                    v-slot:item.feeRate="{ item }"
            >
                {{ item.feeRate.toFixed(2) }}%
            </template>


        </v-data-table>
    </div>
</template>

<script>
    import apiService from "@/api/daily-data";

    export default {
        name: "HighFeeStock",
        props: {
            ticker: String
        },
        async mounted() {
            let oiData = await apiService.getFeeRate();
            this.printData = await oiData.json();
            this.prints = this.printData;
            this.loading = false;

        },
        data: () => ({
            loading: true,
            search: '',
            headers: [
                {
                    text: "Ticker",
                    align: 'center',
                    sortable: true,
                    value: "ticker",
                },
                {
                    text: "Date",
                    align: 'center',
                    sortable: true,
                    value: "date",
                },
                {
                    text: "Short Volume",
                    align: 'right',
                    sortable: true,
                    value: "shortVolume",
                },
                {
                    text: "Exempt Vol",
                    align: 'right',
                    sortable: true,
                    value: "shortExemptVolume",
                },
                {
                    text: "Total Vol",
                    align: 'right',
                    sortable: true,
                    value: "totalVolume",
                },
                {
                    text: "Fee Rate",
                    align: 'right',
                    sortable: true,
                    value: "feeRate",
                },
            ],
            prints: [],
        }),
        methods: {
            filterPerfectMatch(value, search) {
                if (value.length <= 1){
                    return value.toString().toLocaleLowerCase() == search.toString().toLocaleLowerCase()
                }else{
                    return value.toString().toLocaleLowerCase().startsWith(search.toString().toLocaleLowerCase())
                }

                //return value != null && value.toString().toUpperCase() === search.toString().toUpperCase()
            },
        },

    }
</script>

<style scoped>


    #dp-prints-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

    }

    #print-table {
        width: 100%;
        background-color: #4C4C4C;
        padding: 10px;
    }

    >>>th {
        padding: 16px 0 16px 14px !important;
    }
    >>>td {
        padding: 16px 0 !important;
    }

    .v-card__subtitle, .v-card__text, .v-card__title {
        padding: 0px !important;
    }

    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }

    >>>tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }

</style>