<template>
  <div class="TVChartContainer">
    <div id="tradingview_eb93a" />
    <div id="chart-control-center" :style="{display: showDpSettings ? 'block' : 'none'}">
      <div class="range-slider-container flex-row-center-between">
        <v-slider
            v-model="dpInterval"
            min="1"
            max="30"
            label="DP Interval"
            hide-details
            thumb-label
            dark
            color=#65BA7D
            thumb-color=#65BA7D
            @change="dpLineIntervalChange"
        />
        <span class="slider-value">{{dpInterval.toString()}}</span>
      </div>

      <div class="range-slider-container flex-row-center-between">
        <v-slider
            class="max-dp-line-range"
            v-model="numDpToShow"
            min="1"
            max="10"
            label="Max DP Lines"
            hide-details
            thumb-label
            dark
            color=#65BA7D
            thumb-color=#65BA7D
            @change="dpLineIntervalChange"
        />
        <span class="slider-value">{{numDpToShow.toString()}}</span>
      </div>
    </div>
    <div
        id="dp-settings-btn" class="flex-cl-end-center"
        @click="showDpSettings = !showDpSettings"
        :style="{display: showDpSettingsBtn ? 'block' : 'none'}"
    >
      <span style="font-weight: bold; margin-left: auto; color: #65BA7D;">DP</span>
    </div>
  </div>
</template>

<script>

  import { widget } from '../../../../../public/charting_library';
import apiService from '@/api/chart-data';
import {abbreviateNumber} from "@/helpers/formatting";
function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
export default {
  name: 'TVChartContainerTTG',
  props: {
    ticker: {
      default: 'AAPL',
      type: String,
    },
    interval: {
      default: '1m',
      type: String,
    },
    datafeedUrl: {
      default: 'https://demo_feed.tradingview.com',
      type: String,
    },
    libraryPath: {
      default: '/charting_library/',
      type: String,
    },
    chartsStorageUrl: {
      default: 'https://saveload.tradingview.com',
      type: String,
    },
    chartsStorageApiVersion: {
      default: '1.1',
      type: String,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    }
  },
  tvWidget: null,

  mounted() {
    //let today = new Date().toISOString().split("T")[0];
    let today2 = new Date();
    let fiveAgoDay = new Date();
    // Adding 1 day to the date because the chart was stupid.
    today2.setDate(today2.getDate() + 1);
    fiveAgoDay.setDate(fiveAgoDay.getDate() - 5);
    fiveAgoDay = fiveAgoDay.toISOString().split("T")[0];
    today2 = today2.toISOString().split("T")[0];
    console.log(today2)
    console.log(fiveAgoDay)
    this.fetchChartData(fiveAgoDay, today2);
  },

  data: () => ({
    loading: true,
    showDpSettings: false,
    showDpSettingsBtn: false,
    dpInterval: 1,
    numDpToShow: 3,
    dpLines: []
  }),

  destroyed() {
    if (this.tvWidget) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  },

  methods: {

    fetchChartData: function (startDate, endDate) {
      apiService.getMinuteChartingData(this.ticker, "1min", startDate, endDate)
          .then(response => response.json().then(response => {
            this.rawChartData = response['series']['data'];
            this.chartSetup();
          }));
    },

    // USER CHART CONTROLS
    dpLineIntervalChange: function () {
      this.addStudies();
    },

    chartSetup: function () {
      this.loading = false;

      const nFeed = this.createDataFeed(this.ticker, this.rawChartData);
      const widgetOptions = {
        height: "100%",
        width: "100%",
        hide_legend: true,
        timezone: "Etc/UTC",
        theme: "dark",
        style: "1",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        allow_symbol_change: true,
        symbol: this.ticker,
        // BEWARE: no trailing slash is expected in feed URL
        datafeed: nFeed,
        interval: this.interval,
        container_id: "tradingview_eb93a",
        library_path: this.libraryPath,
        locale: getLanguageFromURL() || 'en',
        //TODO - need to edit this to show the correct time frames to pick
        time_frames: [
          //{ text: "1y", resolution: "D" },
          //{ text: "8m", resolution: "D" },
          //{ text: "2m", resolution: "D" },
          { text: "1m", resolution: "1" },
          //{ text: "1w", resolution: "30" },
          { text: "7d", resolution: "30" },
          { text: "5d", resolution: "10" },
          { text: "3d", resolution: "10" },
          { text: "2d", resolution: "5" },
          { text: "1d", resolution: "5" }
        ],
        disabled_features: [
          "use_localstorage_for_settings", "header_widget",
          "left_toolbar",
          "header_widget_dom_node", "header_symbol_search",
          "symbol_search_hot_key", "header_resolutions",
          "header_interval_dialog_button", "header_chart_type",
          "header_settings", "header_indicators",
          "header_compare", "header_undo_redo",
          "header_screenshot", "header_fullscreen_button",
          "header_saveload", "compare_symbol",
          "control_bar"
        ],
        enabled_features: ['study_templates'],
        charts_storage_url: this.chartsStorageUrl,
        charts_storage_api_version: this.chartsStorageApiVersion,
        fullscreen: this.fullscreen,
        studies_overrides: this.studiesOverrides,
      };
      const tvWidget = new widget(widgetOptions);
      this.tvWidget = tvWidget;
      tvWidget.onChartReady(() => {
        this.loading = false;
        tvWidget.headerReady().then(() => {
          const button = tvWidget.createButton();
          button.setAttribute('title', 'Click to show a notification popup');
          button.classList.add('apply-common-tooltip');
          button.addEventListener('click', () => tvWidget.showNoticeDialog({
            title: 'Notification',
            body: 'TradingView Charting Library API works correctly',
            callback: () => {
              // eslint-disable-next-line no-console
              console.log('Noticed!');
            },
          }));
          button.innerHTML = 'Check API';
        }).catch(err => {
          console.log(err);
        });
        this.showDpSettingsBtn = true;
        this.addStudies();
      });
    },

    createDataFeed: function (stock, data){
      const config = {
        supported_resolutions: [this.interval]
      }

      return {
        onReady: cb => {
          setTimeout(() => cb(config), 0)
        },

        resolveSymbol: (symbolName, onSymbolResolvedCallback) => {

          let symbol_stub = {
            name: stock,
            description: '',
            type: 'Stock',
            session: '24x7',
            timezone: 'America/New_York',
            ticker: stock,
            minmov: 1,
            pricescale: 100000000,
            has_intraday: true,
            //TODO - need to pass this correctly when its daily vs / intraday.
            intraday_multipliers: ['1','5','15'],
            supported_resolution: ["1m","5m","15m"],
            volume_precision: 8,
            data_status: 'streaming',
          }

          setTimeout(function () {
            onSymbolResolvedCallback(symbol_stub)
          }, 0)
        },

        getBars: (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback) => {
          let formattedData = this.formatDataSource(data)

          try {
            onHistoryCallback(formattedData, {noData: false})
          } catch (e) {
            console.log(e);
            onErrorCallback(e);
          }
        }
      }
    },

    formatDataSource: function (data) {
      return data.map(elem => {
        return {
          time: elem['timestamp']*1000, // TradingView requires bar time in epoch ms
          low: elem["low"],
          high: elem["high"],
          open: elem["open"],
          close: elem["close"],
          volume: elem["volume"]
        }
      });
    },

    removeAllDpLines: function () {
      for (let line of this.dpLines) {
        line.remove();
      }
      this.dpLines = [];
    },

    // Adds DP line studies
    addStudies: function () {
      this.removeAllDpLines();
      apiService.getStockDpLevels(this.ticker, this.dpInterval)
          .then(response => response.json())
          .then(levels => {
            console.log(levels)
            console.log("----")
            for (let i = 0; i < Math.min(this.numDpToShow, levels.length); i++) {
              const level = levels[i];
              let dpLine = this.tvWidget.activeChart()
                  .createPositionLine({})
                  .setText(this.abbrNum(level.sum))
                  .setTooltip(this.abbrNum(level.sum) + " @ " + level.price)
                  .setQuantity("Dark").setPrice(level.price)
                  .setLineStyle(0).setLineLength(25).setLineColor("#65BA7D");
              this.dpLines.push(dpLine);
            }
          })
        .catch(err => {
          console.log("dp data err");
          console.log(err);
        });

    },

    abbrNum: abbreviateNumber
  }
}
</script>

<style scoped>
  .TVChartContainer {
    margin-top: 1rem;
    width: 100%;
  }

  #tradingview_eb93a {
    height: 400px;
  }

  #chart-control-center {
    width: 100%;
    background-color: #131722;
    padding: 0 8px;
  }

  /* DP SETTINGS */
  #dp-settings-btn {
    padding: 8px;
    background-color: #131722;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width: fit-content;
    margin-left: auto;
  }

  /* Deep Selector for slider labels */
  >>>.v-label.theme--dark{
    color: #787b86;
    font-size: 14px;
    font-family: Trebuchet MS,roboto,ubuntu,sans-serif;
  }

  .slider-value {
    color: #787b86;
    font-size: 14px;
    font-family: Trebuchet MS,roboto,ubuntu,sans-serif;
  }
</style>