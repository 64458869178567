var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dp-prints-container"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"dark":"","id":"print-table","headers":_vm.headers,"search":_vm.search,"custom-filter":_vm.filterPerfectMatch,"items":_vm.prints,"items-per-page":15,"dense":"","calculate-widths":"","mobile-breakpoint":"200"},scopedSlots:_vm._u([{key:"item.ticker",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ticker)+" ")]}},{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.companyName)+" ")]}},{key:"item.industry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.industry)+" ")]}},{key:"item.marketCap",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.marketCap * 1000000).toLocaleString())+" ")]}},{key:"item.sharesFloat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((Number(item.sharesFloat) * 1000000).toLocaleString())+" ")]}},{key:"item.shortExemptVolume",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shortExemptVolume.toLocaleString())+" ")]}},{key:"item.totalVolume",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalVolume.toLocaleString())+" ")]}},{key:"item.feeRate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.feeRate.toFixed(2))+"% ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }