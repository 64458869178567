import {COOKIE_USER_AUTH, getCookie} from "@/utils/CookieHelper";

export const BASE_URL = 'https://api.darksweep.com/v1'

export const headers = {
    'Content-Type': 'application/json'
}


export const authHeaders = {
    'x-access-token': getCookie(COOKIE_USER_AUTH), //'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiIxNTUwMzkzMDM5NTU2NDQ0MTciLCJpYXQiOjE2MzU1MTIwOTcsImV4cCI6MTYzODEwNDA5N30.5WLjOwz6Gpz0IZLpIz4vDkPZbvvg3som2s2Zh-RK9uQ',//getCookie(COOKIE_USER_AUTH),
    'Content-Type': 'application/json'
}

export const authHeadersTTG = function() {
    return {
        'x-access-token': getCookie(COOKIE_USER_AUTH),//'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiIxNTUwMzkzMDM5NTU2NDQ0MTciLCJpYXQiOjE2MzU1MTIwOTcsImV4cCI6MTYzODEwNDA5N30.5WLjOwz6Gpz0IZLpIz4vDkPZbvvg3som2s2Zh-RK9uQ',
        'Content-Type': 'application/json'
    }
}

export default {
    BASE_URL: {BASE_URL},
    headers,
    authHeaders,
    authHeadersTTG
};