<template>
    <div id="streaming-container" class="flex-cl-center-start">

        <div style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details><br><br><br><br>D - Dark, L - Light <br>
            Colors - Basic Materials - Yellow, Com. Services - Orange, Consumer Cyclical - Maroon, Consumer Defensive - Blue, Energy - Green, Financial - Grey<br>
            Healthcare - Forest Green, Industrials - Dark Purple,Real Estate - Brown, Technology - Light Blue, Utilities - Dark Orange
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>

        <v-card-title>

            <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search Symbol Here..."
                    single-line
                    hide-details
            ></v-text-field>

        </v-card-title>

        <v-skeleton-loader
                style="width: 100%; height: 100%;"
                v-if="loading"
                type="table-tbody"
        ></v-skeleton-loader>


        <v-data-table
                v-else
                dark
                id="print-table"
                :headers="headers"
                :items="prints"
                :footer-props="{'items-per-page-options':[50, 100]}"
                :search="search"
                :custom-filter="filterPerfectMatch"
                dense
                calculate-widths
                mobile-breakpoint="200"
                @click:row="toExpanded"
        >

            <template
                    v-slot:item.symbol="{ item }"
            >
                <v-chip
                        :color="sectorColorMap[item.sector]"
                        dark
                >
                    {{ item.symbol }}
                </v-chip>
            </template>

            <template
                    v-slot:item.price="{ item }"
            >
                {{ `\$${item.price.toFixed(2)}` }}
            </template>

            <template
                    v-slot:item.size="{ item }"
            >
                {{ abbrNum(item.size) }}
            </template>

            <template
                    v-slot:item.total="{ item }"
            >
                ${{ abbrNum(item.total) }}
            </template>

            <template
                    v-slot:item.epochTime="{ item }"
            >
                {{ formatDate(item.epochTime*1000) }}
            </template>

            <template
                    v-slot:item.side="{ item }"
            >
                <div v-html="determinePrintType(item)"></div>
            </template>
            <template v-slot:footer>
                <div style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details><br><br><br><br>D - Dark, L - Light <br>
                    Colors - Basic Materials - Yellow, Com. Services - Orange, Consumer Cyclical - Maroon, Consumer Defensive - Blue, Energy - Green, Financial - Grey<br>
                    Healthcare - Forest Green, Industrials - Dark Purple,Real Estate - Brown, Technology - Light Blue, Utilities - Dark Orange
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {abbreviateNumber} from "@/helpers/formatting";
    import {ORDERS_COLLECTION} from "@/firebase/config";
    import {mapActions} from "vuex";
    //import {checkCookie, COOKIE_USER_AUTH, COOKIE_USER_ID, getCookie} from "../../utils/CookieHelper";
    import apiService from "@/api/daily-data";
    import {getCookie, COOKIE_USER_AUTH} from "@/utils/CookieHelper";

    export default {
        name: "StreamingDarkPool",
        props: {
            widgetMode: Boolean
        },
        beforeMount() {

            if (!this.widgetMode) {
                this.tableHeight = `${window.innerHeight - 270}`;
            }
        },
        async mounted() {
          // Send user back if not logged in
            // Send user back if not logged in
            //

          var cookie = getCookie(COOKIE_USER_AUTH)
          if (!cookie){
              window.location.href = 'https://truetradinggroup.com/member-dashboard/options-dark-pool-access-link/';
          }

          let backlog = await apiService.getDarkPoolDataTTG();
          this.printData = await backlog.json();

          this.prints = this.printData;

          // have to do 15m delayed on the DP because it is delayed. Then divide by 1000 on it
          let nowDate = new Date().getTime()
          let MS_PER_MINUTE = 60000;
          var myStartDate = (nowDate - (15 * MS_PER_MINUTE)) / 1000;

          // TODO: Stop this when you navigate to expanded view - current if you watch the console it keeps printing.

          this.dpListener = ORDERS_COLLECTION.where('epochTime', '>', myStartDate).onSnapshot(snapshot => {
            if (!snapshot.docChanges().empty) {
              snapshot.docs.forEach((doc) => {
                  //console.log(doc.data());
                this.prints.unshift(doc.data());
              });
            }
          }).bind(this);

          this.loading = false;
        },
        beforeDestroy() {
            //kill the listener
            this.dpListener();

        },
        data: () => ({
            lightPrintColorMap: {
                "Light_A": "#025c1d",
                "Light_B": "#ab4f2b",
                "Light_I": "#2180CE",
            },
            sectorColorMap: {
                "Basic Materials": "#666e18",
                "Communication Services": "#DE832F",
                "Consumer Cyclical": "#75206b",
                "Consumer Defensive": "#2F65DE",
                "Energy": "#025c1d",
                "Financial": "#3c4d3e",
                "Healthcare": "#1e3133",
                "Industrials": "#3f3c69",
                "Real Estate": "#693827",
                "Technology": "#2180CE",
                "Utilities": "#ab4f2b"
            },
            tableHeight: 0,
            loading: true,
            search: '',
            headers: [
                {
                    text: "Symbol",
                    align: 'center',
                    sortable: true,
                    value: 'symbol',
                },
                {
                    text: "Price",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'price',
                },
                {
                    text: "Size",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'size',
                },
                {
                    text: "Value",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'total',
                },
                {
                    text: "Time",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'epochTime',
                },
                {
                    text: "Type",
                    align: 'center',
                    filterable: false,
                    sortable: true,
                    value: 'side',
                },
            ],
            prints: [],
        }),
        methods: {
            ...mapActions('elementVisibility', [
                'setLogoAndBottomNavVisibility'
            ]),
            filterPerfectMatch(value, search) {
                return value != null && value.toString().toUpperCase() === search.toString().toUpperCase()
            },
            determinePrintType: function (print) {

                // TODO: Make these icons instead of text?
                let exchange = print.exchange.split("|")[0];
                if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
                    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";

                let lightText = this.$vuetify.breakpoint.smAndDown ? "L " : "Light"
                if (print.bidAsk === "AtAsk")
                    return `${lightText}<span style="font-family: 'Verdana', sans-serif">(A)</span>`;
                else if (print.bidAsk === "AtBid") return `${lightText}<span style="font-family: 'Verdana', sans-serif">(B)</span>`;
                else if (print.bidAsk === "Inside") return `${lightText}<span style="font-family: 'Verdana', sans-serif">(I)</span>`;
                else return lightText;
            },
            formatDate: function (millis) {
                let date = new Date(millis);
                return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            },
            abbrNum: abbreviateNumber,
            toExpanded: function (value) {
                let stockInfo = {
                    ticker: value.symbol,
                };

                this.$router.push({
                    name: "StockExpandedTTG",
                    params: {
                        stockInfo: stockInfo
                    }
                });
            },
        }

    }
</script>

<style scoped>

    #streaming-container {
        height: 100%;
        width: 100%;
        background-color: #323232;
    }

    #page-controls {
        flex: none;
        padding: 16px 16px;
        width: 100%;
        flex: none;
    }

    #page-heading {
        flex: none;
        padding: 16px;
        color: black;
        flex: none;
        width: 100%;
        background-color: #E5E5E5;
    }
    h1 {
        text-align:center;
    }

    #streaming-controls {
        width: auto;
    }

    #print-table {
        height: 100%;
        border-radius: 0 !important;
        width: 100%;
        overflow-y: auto;

        background-color: #323232;
        box-shadow: none;
    }

    .bid-ask-indicator {
        font-family: 'Verdana',  sans-serif !important;
    }

    .stock-sector-blip {
        height: 30px;
        width: 30px;
    }

    >>>th {
        padding: 16px 0 16px 14px !important;
    }
    >>>td {
        padding: 16px 0 !important;
    }

    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }

    #flow-button {
        position: absolute; right: 0;
        height: 62px;
        background-color: #404040;
        border: thin solid #CCCCCC;

    }
    >>>tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }


</style>