<template>
  <div id="stock-expanded">
    <div v-if="stock" id="expanded-content">

      <div id="stock-expanded-mast">
        <div class="flex-row-center-between">
          <span id="ticker">{{stock.ticker}} <!-- span id="price">$price< /span --></span>
          <v-btn
              icon
              @click="() => this.$router.go(-1)"
              class="stage-buttons"
              style="margin-right: 0"
              exact-active-class="stage-button-active"
              dark
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <div class="flex-row-center-between">
          <span id="name"> {{ stock.company }}</span>
        </div>
      </div>

      <div id="sections-container">
        <high-chartsTTG class="expanded-section" :ticker="stock.ticker"/>
        <TVChartContainerTTG :ticker="stock.ticker"/>
        <flow-printsTTG
                class="expanded-section" :ticker="stock.ticker"
        />
        <dark-printsTTG
            class="expanded-section" :ticker="stock.ticker"
        />
        <DetailsTTG
            :stock="stock"
            :ticker="stock.ticker"
        />
      </div>

    </div>
  </div>
</template>

<script>
import DarkPrintsTTG from "@/components/user-pages/stock-expanded/DarkPrints/DarkPrintsTTG";
import FlowPrintsTTG from "@/components/user-pages/stock-expanded/FlowPrints/FlowPrintsTTG";
import HighChartsTTG from "@/components/user-pages/stock-expanded/ChartsExpanded/HighChartsTTG2";
import DetailsTTG from "@/components/user-pages/stock-expanded/Details/DetailsTTG";
import { mapActions } from 'vuex'
import TVChartContainerTTG from "@/components/user-pages/shared-components/TVChartContainer/TVChartContainerTTG";



export default {
  name: "StockExpandedTTG",

  beforeMount() {

    this.stock = this.$route.params.stockInfo;

  },

  beforeDestroy() {

  },

  data: () => ({
    stock: null,
  }),

  methods: {
    ...mapActions('elementVisibility', [
      'setLogoAndBottomNavVisibility'
    ]),
  },
  components: {TVChartContainerTTG, DetailsTTG, DarkPrintsTTG, FlowPrintsTTG, HighChartsTTG}
}
</script>

<style>
  #stock-expanded {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  #expanded-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #323232;
    padding: 16px 16px 0 16px;
  }

  #stock-expanded-mast {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    padding-bottom: 10px;
  }

  #ticker {
    font-size: 24px;
    font-weight: bold;
  }

  #name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    max-width: 80%;
    text-align: start;
  }

  #price {
    font-weight: normal;
  }

  .expanded-section {
    margin-bottom: 1rem;
  }

  #sections-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  /* For inhertance */
  .section-header {
    color: white;
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
    text-decoration: underline;
  }
</style>