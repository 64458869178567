import {BASE_URL, headers} from "./config";

export default {

    getStockChartingData(stock, interval, startDate, endDate) {
        return fetch(`${BASE_URL}/charting/${stock}/${startDate}/${endDate}`, {headers : headers});
    },

    getMinuteChartingData(stock, interval, startDate, endDate) {
        //console.log(`${BASE_URL}/charting/minute/${stock}/${interval}/${startDate}/${endDate}`)
        return fetch(`${BASE_URL}/charting/minute/${stock}/${interval}/${startDate}/${endDate}`, {headers : headers});
    },

    // https://api.darksweep.com/v1/charting/minute/aapl/5min/2021-05-13/2021-05-17

    getStockDpLevels(stock, interval) {
        return fetch(`${BASE_URL}/dplines/${stock}/${interval}`, {headers : headers});
    },

    getWeeklyEtfChartData() {
        return fetch(`${BASE_URL}/largest/weekly/etf.json`, {headers: headers})
    },

    getWeeklyStockChartData() {
        return fetch(`${BASE_URL}/largest/weekly/nonetf.json`, {headers: headers})
    },

    getSectorRollUp() {
        return fetch(`${BASE_URL}/sectors.json`, {headers: headers})
    },
};