<template>
    <div id="streaming-container" class="flex-cl-center-start">

        <div class="flex-row-center-between">
            <h1>{{niceSectorName}} Options Summary</h1>
        </div>

        <div id="help" style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details> This page sorts options Calls and Puts by % of Market Cap on the Sum of all Calls/Puts for the respective Symbol. <br>The higher the Market Cap % the more significant the flow is assumed to be. You can sort it however you'd like.

        </div>

        <br>
        <br>
        <br>
        <br>
        <div id="charts-container">
            <div class="chartElem">
                <div class="row1">
                    <highcharts class="pie" :options="chartOptions3" :updateArgs="updateArgs3"></highcharts>
                    <highcharts class="pie" :options="chartOptions2" :updateArgs="updateArgs2"></highcharts>
                </div>
            </div>
        </div>
        <v-btn
                :color="changeBackgroundColor ? 'secondary' : 'info'"
                @click="changeBackgroundColor = !changeBackgroundColor"
                :style="{
                       backgroundColor : changeBackgroundColor ? 'black !important' : '',
                       color: changeBackgroundColor ? 'secondary' : 'info'
                       }"
                large
                class="ma-2 white--text"
                id="1m+"
                v-on:click="filter1()"
        >
            Sum > 1M
        </v-btn>

        <v-btn
                :color="changeBackgroundColor3 ? 'secondary' : 'info'"
                @click="changeBackgroundColor3 = !changeBackgroundColor3"
                :style="{
                       backgroundColor : changeBackgroundColor3 ? 'black !important' : '',
                       color: changeBackgroundColor3 ? 'secondary' : 'info'
                       }"
                large
                class="ma-2 white--text"
                id="Calls"
                v-on:click="callsFilter()"
        >
            Calls
        </v-btn>

        <v-btn
                :color="changeBackgroundColor4 ? 'secondary' : 'info'"
                @click="changeBackgroundColor4 = !changeBackgroundColor4"
                :style="{
                       backgroundColor : changeBackgroundColor4 ? 'black !important' : '',
                       color: changeBackgroundColor4 ? 'secondary' : 'info'
                       }"
                large
                class="ma-2 white--text"
                id="Puts"
                v-on:click="putsFilter()"
        >
            Puts
        </v-btn>
        <v-btn
                icon
                v-on:click="clearFilter()"
                class="ma-2 white--text"
                style="margin-right: 0; height: 45px !important; width: 45px !important;"
                dark
                small
                @click="loader = 'loading2'"
        >
            <v-icon>mdi-autorenew</v-icon>
        </v-btn>

        <v-card-title>
            <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search Symbol Here..."
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>

        <v-skeleton-loader
                style="width: 100%; height: 100%;"
                v-if="loading"
                type="table-tbody"
        ></v-skeleton-loader>


        <v-data-table
                v-else
                dark
                id="print-table"
                :headers="headers"
                :items="prints"
                :footer-props="{'items-per-page-options':[50, 100]}"
                :search="search"
                :custom-filter="filterPerfectMatch"
                :item-class="itemRowBackground"
                dense
                calculate-widths
                mobile-breakpoint="200"
                @click:row="toExpanded"
        >

            <template
                    v-slot:item.symbol="{ item }"
            >
                    {{ item.symbol }}
            </template>
            <template
                    v-slot:item.cp="{ item }"
            >
                {{ item.cp }}
            </template>

            <template
                    v-slot:item.mcPercent="{ item }"
            >
                {{ (item.mcPercent).toFixed(4) }}
            </template>

            <template
                    v-slot:item.Count="{ item }"
            >
                    {{item.Count}}
            </template>
            <template
                    v-slot:item.Sum="{ item }"
            >
                ${{Number(item.Sum).toLocaleString()}}
            </template>

            <template
                    v-slot:item.MarketCap="{ item }"
            >
                    {{abbrNum(item.MarketCap * 1000000)}}
            </template>



            <template
                    v-slot:item.SharesFloat="{ item }"
            >
                {{abbrNum(item.SharesFloat * 1000000)}}
            </template>
            <template
                    v-slot:item.InsiderOwnership="{ item }"
            >
                {{item.InsiderOwnership}}
            </template>
            <template
                    v-slot:item.InstitutionalOwnership="{ item }"
            >
                {{item.InstitutionalOwnership}}
            </template>



            <template v-slot:footer>
                <div style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details><br><br><br><br>S - Sweep, SP - Split, C - Complex, O - Opening (Vol>OI), A - Ask, B - Bid, BB - Below Bid <br>
                    AA - Above Ask, LB - Lean Bid, LA - Lean Ask - All contracts are $25k+ prem for Stocks and $100k+ prem for ETFs
                </div>
            </template>

        </v-data-table>
    </div>

</template>

<script>

    import {abbreviateNumber} from "@/helpers/formatting";
    //import {FLOW_COLLECTION} from "@/firebase/config";
    import {mapActions} from "vuex";
    import {COOKIE_USER_AUTH, getCookie} from "@/utils/CookieHelper";
    import apiService from '@/api/daily-data';

    export default {
        name: "SectorRollupTTG",
        props: {
            widgetMode: Boolean,
            sectorMap :{
                type: String,
                required: true
            }
        },
        beforeMount() {

            if (!this.widgetMode) {
                this.tableHeight = `${window.innerHeight - 270}`;
            }

            this.niceSectorName = this.getSectorName(this.sectorMap)
            console.log(this.sectorMap)


        },
        async mounted() {

            var cookie = getCookie(COOKIE_USER_AUTH)
            if (!cookie){
                window.location.href = 'https://truetradinggroup.com/member-dashboard/options-dark-pool-access-link/';
            }

            this.getData();
            let backlog = await apiService.getSectorRollup(this.sectorMap);
            this.printData = await backlog.json();
            this.prints = this.printData;

            this.loading = false;
        },
        data: () => ({
            updateArgs2: [true, true, {duration: 1000}],
            updateArgs3: [true, true, {duration: 1000}],
            bullishPrem : "",
            bearishPrem : "",
            totalCallPrem : "",
            totalPutPrem : "",
            flowsumCalls : [],
            flowsumPuts : [],
            chartOptions2: {
                chart: {
                    type: 'pie',
                },
                xAxis: {
                    categories: ['Apples', 'Bananas', 'Oranges'],
                    title: {
                        text: 'Expiration Date'
                    },
                },
                yAxis: {
                },
                title: {
                    text: 'Total Premium Call vs Puts'
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {

                    pie: {
                        size: 130,
                        center: ["50%", "50%"],
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: 'Call vs Put Prem',
                    colorByPoint: true,
                    data: [{
                        name: 'Calls',
                        y: 62,
                        sliced: true,
                        selected: true
                    }, {
                        name: 'Puts',
                        y: 38
                    }]
                }]
            },
            chartOptions3: {
                chart: {
                    type: 'pie'
                },
                yAxis: {
                },
                title: {
                    text: 'Bullish vs Bearish Prem'
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        size: 130,
                        center: ["50%", "50%"],
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                        }
                    }
                },
                series: [{
                    name: 'Call vs Put Prem (Above and At Ask)',
                    colorByPoint: true,
                    data: [{
                        name: 'Calls',
                        y: 62,
                        sliced: true,
                        selected: true
                    }, {
                        name: 'Puts',
                        y: 38
                    }]
                }]
            },


            niceSectorName: "",
            loader: null,
            loading2: false,
            changeBackgroundColor: false,
            changeBackgroundColor2 : false,
            changeBackgroundColor3: false,
            changeBackgroundColor4 : false,
            lightPrintColorMap: {
                "Light_AA": "#005005",
                "Light_A": "#2e7d32",
                "Light_LA": "#60ad5e",
                "Light_BB": "#8e0000",
                "Light_B": "#c62828",
                "Light_LB": "#ff5f52",
                "Light_I": "#2180CE",
            },
            sectorMapName: {
                "BM" : "Basic Materials",
                "CS" : "Communication Services",
                "CC" : "Consumer Cyclical",
                "CD" : "Consumer Defensive",
                "E" : "Energy",
                "F" : "Financial",
                "H" : "Healthcare",
                "I" : "Industrials",
                "RE" : "Real Estate",
                "T" : "Technology",
                "U" : "Utilities"
            },
            tableHeight: 0,
            loading: true,
            search: '',
            headers: [
                {
                    text: "Symbol",
                    align: 'center',
                    sortable: true,
                    value: 'symbol',
                },
                {
                    text: "%ofMarketCap",
                    align: 'center',
                    sortable: true,
                    value: 'mcPercent',
                },
                {
                    text: "CP",
                    align: 'center',
                    sortable: true,
                    value: 'cp',
                },
                {
                    text: "Count",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'Count',
                },
                {
                    text: "Sum",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'Sum',
                },
                {
                    text: "Market Cap",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'MarketCap',
                },
                {
                    text: "Shares Float",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'SharesFloat',
                },
                {
                    text: "Insider Ownership",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'InsiderOwnership',
                },
                {
                    text: "Inst Ownership",
                    align: 'center',
                    sortable: true,
                    filterable: false,
                    value: 'InstitutionalOwnership',
                },
            ],
            snapshotInitState: true,
            printData: [],
            prints: [],
        }),

        methods: {
            ...mapActions('elementVisibility', [
                'setLogoAndBottomNavVisibility'
            ]),
            getData: function() {
                let self = this;
                apiService.getSectorRollupPie(this.sectorMap)
                    .then(response => response.json().then(response => {
                        self.stocks = response;
                        self.totalCallPrem = 0;
                        self.totalPutPrem = 0;
                        self.bullishPrem = 0;
                        self.bearishPrem = 0;

                        for(var i=0; i<self.stocks.length; i++){
                            if (self.stocks[i]['cp'] === "C") {
                                self.flowsumCalls.push(self.stocks[i]['sum']);
                                self.totalCallPrem = self.stocks[i]['sum'] + self.totalCallPrem
                                if (self.stocks[i]['Side'] === 3 || self.stocks[i]['Side'] === 2) {
                                    self.bullishPrem = self.stocks[i]['sum'] + self.bullishPrem
                                }
                            }
                            if (self.stocks[i]['cp'] === "P"){
                                self.flowsumPuts.push(self.stocks[i]['sum']);
                                self.totalPutPrem = self.stocks[i]['sum'] + self.totalPutPrem
                                if (self.stocks[i]['Side'] === 3 || self.stocks[i]['Side'] === 2 ) {
                                    self.bearishPrem = self.stocks[i]['sum'] + self.bearishPrem
                                }
                            }
                        }

                        this.chartOptions2.series[0].data[0].y = self.totalCallPrem
                        this.chartOptions2.series[0].data[1].y = self.totalPutPrem
                        this.chartOptions3.series[0].data[0].y = self.bullishPrem
                        this.chartOptions3.series[0].data[1].y = self.bearishPrem


                    }));
            },
            getColor (item) {
                if (item.cp === "P") return 'rgba(255, 0, 0, 0.30)'
                else return 'rgba(0, 128, 0, .40)'

            },
            getSideColor (item) {
                if (item.side === 3)
                    return this.lightPrintColorMap.Light_AA;
                else if (item.side === 2) return this.lightPrintColorMap.Light_A;
                else if (item.side === 1) return this.lightPrintColorMap.Light_LA;
                else if (item.side === 0) return this.lightPrintColorMap.Light_I;
                else if (item.side === -1) return this.lightPrintColorMap.Light_LB;
                else if (item.side === -2) return this.lightPrintColorMap.Light_B;
                else if (item.side === -3) return this.lightPrintColorMap.Light_BB;
            },
            getSectorName (item) {
                if (item === "BM")
                    return this.sectorMapName.BM;
                else if (item === "CS") return this.sectorMapName.CS;
                else if (item === "CC") return this.sectorMapName.CC;
                else if (item === "CD") return this.sectorMapName.CD;
                else if (item === "E") return this.sectorMapName.E;
                else if (item === "F") return this.sectorMapName.F;
                else if (item === "H") return this.sectorMapName.H;
                else if (item === "I") return this.sectorMapName.I;
                else if (item === "RE") return this.sectorMapName.RE;
                else if (item === "T") return this.sectorMapName.T;
                else if (item === "U") return this.sectorMapName.U;
            },
            showDP(){
                this.$router.push({name: "DarkPoolFlowTTG"});
            },
            itemRowBackground: function (item) {
                return item.cp === "P" ? 'put' : 'call'
            },
            filterPerfectMatch(value, search) {
                return value != null && value.toString().toUpperCase() === search.toString().toUpperCase()
            },
            filter1() {
                this.prints = this.prints.filter(el => {
                    return (el.Sum > 1000000);
                });
            },
            openingFilter() {
                this.prints = this.prints.filter(el => {
                    return (el.cond.includes("OPENING"));
                });
            },
            callsFilter() {
                this.prints = this.prints.filter(el => {
                    return (el.cp === "C");
                });
            },
            putsFilter() {
                this.prints = this.prints.filter(el => {
                    return (el.cp === "P");
                });
            },
            async clearFilter() {

                if (this.loading) return;
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                }, 5000);


                //TODO : Having this just reload the backlog here when they clear the filter, missing some when it does - this will cause a longer loadtime tho...
                // Maybe a better fix is to just have another array the data is stored in?
                let backlog = await apiService.getSectorRollup(this.sectorMap);
                this.prints = await backlog.json();
                this.changeBackgroundColor = false;
                this.changeBackgroundColor2 = false;
                this.changeBackgroundColor3 = false;
                this.changeBackgroundColor4 = false;
            },

            formatDate: function (millis) {
                let date = new Date(millis);
                return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            },
            abbrNum: abbreviateNumber,
            toExpanded: function (value) {
                let stockInfo = {
                    ticker: value.symbol,
                };

                this.$router.push({
                    name: "StockExpandedTTG",
                    params: {
                        stockInfo: stockInfo
                    }
                });
            },
        },
        watch: {
            // TODO : this makes it so they can't click it a bunch. needs to spin tho and it doesnt
            loader () {
                const l = this.loader
                this[l] = !this[l]

                setTimeout(() => (this[l] = false), 3000)

                this.loader = null
            },
        },

    }
</script>

<style scoped>

    #streaming-container {
        height: 100%;
        width: 100%;
        background-color: #323232;
    }

    #page-controls {
        flex: none;
        padding: 16px 16px;
        width: 100%;
        flex: none;
    }

    h1 {
        text-align:center;
    }

    #page-heading {
        flex: none;
        padding: 16px;
        color: black;
        flex: none;
        width: 100%;
        background-color: #E5E5E5;
    }

    #streaming-controls {
        width: auto;
    }

    .rowClassCP {
        background-color: #3f87a6 !important;
    }

    #print-table {
        height: 100%;
        border-radius: 0 !important;
        width: 100%;
        overflow-y: auto;

        background-color: #323232;
        box-shadow: none;
    }

    .bid-ask-indicator {
        font-family: 'Verdana',  sans-serif !important;
    }

    .stock-sector-blip {
        height: 30px;
        width: 30px;
    }

    >>>th {
        padding: 16px 0 16px 14px !important;
    }
    >>>td {
        padding: 16px 0 !important;
    }
    #flow-button {
        position: absolute; right: 0;
        height: 62px;
        background-color: #404040;
        border: thin solid #CCCCCC;

    }

    .flex-row-center-between{
        padding-top: 20px;
    }

    #help{
        padding-top: 30px !important;
        padding-bottom:30px !important;
    }

    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }

    >>>tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }

    >>>tbody tr.call {
        background-image: linear-gradient(90deg, rgba(0, 128, 0, .40) 5%, rgba(36,36,36,.05) 20%, rgba(36,36,36,.05) 80%, rgba(0, 128, 0, .40) 95%);
    }
    >>>tbody tr.put {
        background-image: linear-gradient(90deg, rgba(235, 0, 15, 0.40) 5%, rgba(36,36,36,.15) 20%, rgba(36,36,36,.15) 80%, rgba(255, 0, 0, .40) 95%);
    }

    >>>tr {
        text-align: center !important;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .pie {
        min-width: 400px;
        max-width: 400px;
        min-height: 300px;
        max-height: 300px;
        top: 2px;
        border: 2px;
        padding: 2px;
        padding-top: 2px;
        font-family: 'Roboto', sans-serif !important;
    }

    .row1{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content:center;
        font-family: 'Roboto', sans-serif !important;
    }
    #charts-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Roboto', sans-serif !important;
    }
</style>