<template>

    <div class="chartElem">
        <div>
        <div style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details>All options data are contracts $25k+ prem for Stocks and $100k+ prem for ETFs
            <br>All DP data for ETFs is 50M+ prem and 1-10M+ prem for stocks
        </div>
        </div>
        <br><br><br><br>
        <highcharts class="chart" :options="etfChart" :updateArgs="updateEtfChart"></highcharts>
        <div data-app>
        <br>
            <v-layout row wrap justify-center>
                <v-flex xs3>
                    <v-select id="selector"
                              dark
                              v-model="value"
                              :items="items"
                              label="Basic Materials"
                              dense
                              solo
                              v-on:input="changeData(`${items.value}`)"
                    ></v-select>
                </v-flex>
            </v-layout>
        </div>
        <highcharts class="chart" :options="sectorChart" :updateArgs="updateSectorChart"></highcharts>
        <div class="row1">
            <div>

                <highcharts class="pie" :options="sectorFlowDP" :updateArgs="updateFlowDP"></highcharts>
            </div>
        </div>

    </div>
</template>


<script>
    import apiService from '../api/daily-data';
    import {COOKIE_USER_AUTH, getCookie} from "@/utils/CookieHelper";

    export default {
        data () {
            return {
                value : null,
                items: ['Basic Materials', 'Communication Services', 'Consumer Cyclical', 'Consumer Defensive'
                    , 'Energy', 'Financial', 'Healthcare', 'Industrials', 'Real Estate', 'Technology', 'Utilities'],
                bullishPrem : "",
                bearishPrem : "",
                totalCallPrem : "",
                totalPutPrem : "",
                SPY : [],
                QQQ : [],
                IWM : [],
                sectorsHist : [],
                pPremBM : [],
                cPremBM : [],
                cPremCS : [],
                pPremCS : [],
                cPremCC : [],
                pPremCC : [],
                cPremCD : [],
                pPremCD : [],
                cPremE : [],
                pPremE : [],
                cPremF : [],
                pPremF : [],
                cPremH : [],
                pPremH : [],
                cPremI : [],
                pPremI : [],
                cPremRE : [],
                pPremRE : [],
                cPremT : [],
                pPremT : [],
                cPremU : [],
                pPremU : [],
                sectors : [],
                sum : [],
                oiCallPercent : [],
                oiPutPercent : [],
                vol : [],
                volCallsPercent : [],
                volPutsPercent : [],
                datesHist : [],
                cOI : [],
                cVolu : [],
                pOI : [],
                pVolu : [],
                totalOI : [],
                totalVol : [],
                avgVol : [],
                flowsumCalls : [],
                flowsumPuts : [],
                title: '',
                points: [10, 0, 8, 2, 6, 4, 5, 5],
                chartType: 'Spline',
                seriesColor: '#6fcd98',
                colorInputIsSupported: null,
                animationDuration: 1000,
                updateEtfChart: [true, true, {duration: 1000}],
                updateFlowDP: [true, true, {duration: 1000}],
                updateSectorChart: [true, true, {duration: 1000}],

                sectorChart: {
                    chart: {
                        type: 'line'
                    },
                    xAxis: {
                        type: "datetime",
                        title: {
                            text: 'Date'
                        },
                        reversed: false
                    },
                    yAxis: {},
                    title: {
                        text: 'Total Premium Flow by Sector / Daily'
                    },
                    series: [{
                        name: "Basic Materials Puts",
                        data: [10, 0, 8, 2, 6, 4, 8, 9],
                        color: '#f31945'
                    }, {
                        name: "Basic Materials Calls",
                        data: [10, 0, 8, 2, 6, 4, 8, 9],
                        color: '#63e71c'
                    },
                    ]
                },

                etfChart: {

                    chart: {
                        type: 'line'
                    },
                    xAxis: {
                        type: "datetime",
                        title: {
                            text: 'Expiration Date'
                        },
                        reversed: false
                    },
                    yAxis: {},
                    title: {
                        text: 'Total Dark Pool Flow ETFs / Daily'
                    },
                    series: [{
                        name: "QQQ",
                        data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
                        color: '#52f319'
                    }, {
                        name: "IWM",
                        data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
                        color: '#1c3ee7'
                    }, {
                        name: "SPY",
                        data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434],
                        color: '#f1b40f'
                    },
                    ]
                },
                sectorFlowDP: {
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        type: "datetime",
                        categories: ['Apples', 'Bananas', 'Oranges'],
                        title: {
                            text: 'Expiration Date'
                        },
                    },
                    yAxis: {
                    },
                    title: {
                        text: 'Dark Pool by Sector - Today'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>${point.y:,.0f}</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {

                        pie: {
                            size: 320,
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    },
                    series: [{
                        name: 'Dark Pool',
                        colorByPoint: true,
                        data: []
                    }]
                },
            }
        },
        mounted: function () {
            var cookie = getCookie(COOKIE_USER_AUTH)
            if (!cookie){
                window.location.href = 'https://truetradinggroup.com/member-dashboard/options-dark-pool-access-link/';
            }

            this.getData();
        },
        methods: {
            getData: function () {
                let self = this;

                apiService.getetfDPActivity()
                    .then(response => response.json().then(response => {
                        self.stocks = response;
                        for(var i=0; i<self.stocks.length; i++){

                            if (self.stocks[i]["stock"] === "SPY") {
                                self.SPY.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["last_updated"])});
                            }
                            if (self.stocks[i]["stock"] === "QQQ") {
                                self.QQQ.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["last_updated"])});
                            }
                            if (self.stocks[i]["stock"] === "IWM") {
                                self.IWM.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["last_updated"])});
                            }





                        }
                    }));

                this.etfChart.series[0].data = self.QQQ
                this.etfChart.series[1].data = self.IWM
                this.etfChart.series[2].data = self.SPY


                apiService.getSectorData()
                    .then(response => response.json().then(response => {
                        self.stocks = response;
                        for(var i=0; i<self.stocks.length; i++){
                            self.sectors.push(self.stocks[i]["sector"]);
                            self.sum.push(self.stocks[i]["sum"]);
                            this.sectorFlowDP.series[0].data.push({
                                "name" : self.stocks[i]["Sector"],
                                "y" : self.stocks[i]["sum"]
                            })
                        }
                    }));

                apiService.getSectorsHistorical()
                    .then(response => response.json().then(response => {
                        self.stocks = response;
                        console.log(self.stocks)
                        for(var i=0; i<self.stocks.length; i++){


                            if (self.stocks[i]["Sector"] === "Basic Materials") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremBM.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremBM.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Communication Services") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremCS.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremCS.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Consumer Cyclical") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremCC.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremCC.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Consumer Defensive") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremCD.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremCD.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Energy") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremE.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremE.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Financial") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremF.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremF.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Healthcare") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremH.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremH.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Industrials") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremI.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremI.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Real Estate") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremRE.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremRE.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Technology") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremT.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremT.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }
                            if (self.stocks[i]["Sector"] === "Utilities") {
                                if (self.stocks[i]['cp'] === "C") {
                                    self.cPremU.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                                if (self.stocks[i]['cp'] === "P"){
                                    self.pPremU.push({"y" : Math.round(self.stocks[i]["sum"]),"x" : Date.parse(self.stocks[i]["date"])});
                                }
                            }

                            self.sectorsHist.push(self.stocks[i]["Sector"]);

                        }

                    }));


                this.sectorChart.series[0].data = self.pPremBM
                this.sectorChart.series[1].data = self.cPremBM


            },
            changeData() {
                //this.$router.push({path: a })
                if (this.value === "Communication Services") {
                    this.sectorChart.series[0].name = "Communication Services Puts"
                    this.sectorChart.series[1].name = "Communication Services Calls"

                    this.sectorChart.series[0].data = this.pPremCS
                    this.sectorChart.series[1].data = this.cPremCS
                }
                if (this.value === "Basic Materials") {
                    this.sectorChart.series[0].name = "Basic Materials Puts"
                    this.sectorChart.series[1].name = "Basic Materials Calls"
                    this.sectorChart.series[0].data = this.pPremBM
                    this.sectorChart.series[1].data = this.cPremBM
                }
                if (this.value === "Consumer Cyclical") {
                    this.sectorChart.series[0].name = "Consumer Cyclical Puts"
                    this.sectorChart.series[1].name = "Consumer Cyclical Calls"
                    this.sectorChart.series[0].data = this.pPremCC
                    this.sectorChart.series[1].data = this.cPremCC
                }
                if (this.value === "Consumer Defensive") {
                    this.sectorChart.series[0].name = "Consumer Defensive Puts"
                    this.sectorChart.series[1].name = "Consumer Defensive Calls"
                    this.sectorChart.series[0].data = this.pPremCD
                    this.sectorChart.series[1].data = this.cPremCD
                }
                if (this.value === "Energy") {
                    this.sectorChart.series[0].name = "Energy Puts"
                    this.sectorChart.series[1].name = "Energy Calls"
                    this.sectorChart.series[0].data = this.pPremE
                    this.sectorChart.series[1].data = this.cPremE
                }
                if (this.value === "Financial") {
                    this.sectorChart.series[0].name = "Financial Puts"
                    this.sectorChart.series[1].name = "Financial Calls"
                    this.sectorChart.series[0].data = this.pPremF
                    this.sectorChart.series[1].data = this.cPremF
                }
                if (this.value === "Healthcare") {
                    this.sectorChart.series[0].name = "Healthcare Puts"
                    this.sectorChart.series[1].name = "Healthcare Calls"
                    this.sectorChart.series[0].data = this.pPremH
                    this.sectorChart.series[1].data = this.cPremH
                }
                if (this.value === "Industrials") {
                    this.sectorChart.series[0].name = "Industrials Puts"
                    this.sectorChart.series[1].name = "Industrials Calls"
                    this.sectorChart.series[0].data = this.pPremI
                    this.sectorChart.series[1].data = this.cPremI
                }
                if (this.value === "Real Estate") {
                    this.sectorChart.series[0].name = "Real Estate Puts"
                    this.sectorChart.series[1].name = "Real Estate Calls"
                    this.sectorChart.series[0].data = this.pPremRE
                    this.sectorChart.series[1].data = this.cPremRE
                }
                if (this.value === "Technology") {
                    this.sectorChart.series[0].name = "Technology Puts"
                    this.sectorChart.series[1].name = "Technology Calls"
                    this.sectorChart.series[0].data = this.pPremT
                    this.sectorChart.series[1].data = this.cPremT
                }
                if (this.value === "Utilities") {
                    this.sectorChart.series[0].name = "Utilities Puts"
                    this.sectorChart.series[1].name = "Utilities Calls"
                    this.sectorChart.series[0].data = this.pPremU
                    this.sectorChart.series[1].data = this.cPremU
                }


            },
        },
        created () {
            let i = document.createElement('input')
            i.setAttribute('type', 'color');
            (i.type === 'color') ? this.colorInputIsSupported = true : this.colorInputIsSupported = false
        },
        watch: {
            title (newValue) {
                this.chartOptions.title.text = newValue
            },
            points (newValue) {
                this.chartOptions.series[0].data = newValue
                this.chartOptions.series[1].data = newValue
            },
            chartType (newValue) {
                this.chartOptions.chart.type = newValue.toLowerCase()
            },
            seriesColor (newValue) {
                this.chartOptions.series[0].color = newValue.toLowerCase()
            },
            animationDuration (newValue) {
                this.updateArgs[2].duration = Number(newValue)
            }
        }
    }
</script>

<style scoped>
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    .v-select.v-input--is-dirty input {
        display: none;
    }

    .v-select__selection {
        max-width: none;
    }

    .chartElem{
        padding: 1%;
    }

    #test{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

    }

    .pie {
        min-width: 1200px;
        max-width: 1200px;
        top: 2px;
        border: 2px;
        padding: 2px;
        padding-top: 8px;
        font-family: 'Roboto', sans-serif !important;
    }

    .row1{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto', sans-serif !important;
    }

    #colorPicker {
        border: 0;
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
    }
    .numberInput {
        width: 30px;
    }
</style>
