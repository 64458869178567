var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-cl-center-start",attrs:{"id":"streaming-container"}},[_c('div',{staticClass:"flex-row-center-between"},[_c('h1',[_vm._v(_vm._s(_vm.niceSectorName)+" Options Summary")])]),_vm._m(0),_c('br'),_c('br'),_c('br'),_c('br'),_c('div',{attrs:{"id":"charts-container"}},[_c('div',{staticClass:"chartElem"},[_c('div',{staticClass:"row1"},[_c('highcharts',{staticClass:"pie",attrs:{"options":_vm.chartOptions3,"updateArgs":_vm.updateArgs3}}),_c('highcharts',{staticClass:"pie",attrs:{"options":_vm.chartOptions2,"updateArgs":_vm.updateArgs2}})],1)])]),_c('v-btn',{staticClass:"ma-2 white--text",style:({
                   backgroundColor : _vm.changeBackgroundColor ? 'black !important' : '',
                   color: _vm.changeBackgroundColor ? 'secondary' : 'info'
                   }),attrs:{"color":_vm.changeBackgroundColor ? 'secondary' : 'info',"large":"","id":"1m+"},on:{"click":[function($event){_vm.changeBackgroundColor = !_vm.changeBackgroundColor},function($event){return _vm.filter1()}]}},[_vm._v(" Sum > 1M ")]),_c('v-btn',{staticClass:"ma-2 white--text",style:({
                   backgroundColor : _vm.changeBackgroundColor3 ? 'black !important' : '',
                   color: _vm.changeBackgroundColor3 ? 'secondary' : 'info'
                   }),attrs:{"color":_vm.changeBackgroundColor3 ? 'secondary' : 'info',"large":"","id":"Calls"},on:{"click":[function($event){_vm.changeBackgroundColor3 = !_vm.changeBackgroundColor3},function($event){return _vm.callsFilter()}]}},[_vm._v(" Calls ")]),_c('v-btn',{staticClass:"ma-2 white--text",style:({
                   backgroundColor : _vm.changeBackgroundColor4 ? 'black !important' : '',
                   color: _vm.changeBackgroundColor4 ? 'secondary' : 'info'
                   }),attrs:{"color":_vm.changeBackgroundColor4 ? 'secondary' : 'info',"large":"","id":"Puts"},on:{"click":[function($event){_vm.changeBackgroundColor4 = !_vm.changeBackgroundColor4},function($event){return _vm.putsFilter()}]}},[_vm._v(" Puts ")]),_c('v-btn',{staticClass:"ma-2 white--text",staticStyle:{"margin-right":"0","height":"45px !important","width":"45px !important"},attrs:{"icon":"","dark":"","small":""},on:{"click":[function($event){return _vm.clearFilter()},function($event){_vm.loader = 'loading2'}]}},[_c('v-icon',[_vm._v("mdi-autorenew")])],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search Symbol Here...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"dark":"","id":"print-table","headers":_vm.headers,"items":_vm.prints,"footer-props":{'items-per-page-options':[50, 100]},"search":_vm.search,"custom-filter":_vm.filterPerfectMatch,"item-class":_vm.itemRowBackground,"dense":"","calculate-widths":"","mobile-breakpoint":"200"},on:{"click:row":_vm.toExpanded},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(item.symbol)+" ")]}},{key:"item.cp",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(item.cp)+" ")]}},{key:"item.mcPercent",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s((item.mcPercent).toFixed(4))+" ")]}},{key:"item.Count",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(item.Count)+" ")]}},{key:"item.Sum",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" $"+_vm._s(Number(item.Sum).toLocaleString())+" ")]}},{key:"item.MarketCap",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.abbrNum(item.MarketCap * 1000000))+" ")]}},{key:"item.SharesFloat",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.abbrNum(item.SharesFloat * 1000000))+" ")]}},{key:"item.InsiderOwnership",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(item.InsiderOwnership)+" ")]}},{key:"item.InstitutionalOwnership",fn:function(ref){
                   var item = ref.item;
return [_vm._v(" "+_vm._s(item.InstitutionalOwnership)+" ")]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"pa-0 pl-2",staticStyle:{"position":"absolute","font-size":".75em"},attrs:{"label":"Legend","hide-details":""}},[_c('br'),_c('br'),_c('br'),_c('br'),_vm._v("S - Sweep, SP - Split, C - Complex, O - Opening (Vol>OI), A - Ask, B - Bid, BB - Below Bid "),_c('br'),_vm._v(" AA - Above Ask, LB - Lean Bid, LA - Lean Ask - All contracts are $25k+ prem for Stocks and $100k+ prem for ETFs ")])]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 pl-2",staticStyle:{"position":"absolute","font-size":".75em"},attrs:{"id":"help","label":"Legend","hide-details":""}},[_vm._v(" This page sorts options Calls and Puts by % of Market Cap on the Sum of all Calls/Puts for the respective Symbol. "),_c('br'),_vm._v("The higher the Market Cap % the more significant the flow is assumed to be. You can sort it however you'd like. ")])}]

export { render, staticRenderFns }