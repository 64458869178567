<template>
    <div id="dp-prints-container">
        <h2 class="section-header">Short Volume per Finra</h2>

        <v-skeleton-loader
                style="width: 100%; height: 100%;"
                v-if="loading"
                type="table-tbody"
        ></v-skeleton-loader>

        <v-data-table
                dark
                v-else
                id="print-table"
                :headers="headers"
                :items="prints"
                :items-per-page="15"
                dense
                calculate-widths
                mobile-breakpoint="200"
        >
            <template
                    v-slot:item.date="{ item }"
            >
                {{ new Date(item.date).toLocaleDateString("en-US", {timeZone: 'UTC'}) }}
            </template>

            <template
                    v-slot:item.shortVolume="{ item }"
            >

                {{ Number(item.shortVolume).toLocaleString() }}

            </template>

            <template
                    v-slot:item.shortExemptVolume="{ item }"
            >
                {{ item.shortExemptVolume.toLocaleString() }}
            </template>

            <template
                    v-slot:item.totalVolume="{ item }"
            >
                {{ item.totalVolume.toLocaleString() }}
            </template>


        </v-data-table>
    </div>
</template>

<script>
    import apiService from "@/api/daily-data";

    export default {
        name: "ShortInterestTabTTG",
        props: {
            ticker: String
        },
        async mounted() {
            let oiData = await apiService.getShortIntData(this.ticker);
            this.printData = await oiData.json();
            this.prints = this.printData;
            this.loading = false;

        },
        data: () => ({
            loading: true,
            headers: [
                {
                    text: "Date",
                    align: 'center',
                    sortable: true,
                    value: "date",
                },
                {
                    text: "Short Volume",
                    align: 'center',
                    sortable: true,
                    value: "shortVolume",
                },
                {
                    text: "Exempt Vol",
                    align: 'center',
                    sortable: true,
                    value: "shortExemptVolume",
                },
                {
                    text: "Total Vol",
                    align: 'center',
                    sortable: true,
                    value: "totalVolume",
                },
            ],
            prints: [],
        }),

    }
</script>

<style scoped>

    #dp-prints-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    #print-table {
        padding-top: 10px;
        width: 100%;
        background-color: #4C4C4C;
    }

    >>>td {
        padding: 16px 0 !important;
    }

    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }


</style>