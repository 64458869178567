<template>
  <div id="dp-prints-container">
    <h2 class="section-header">Individual Prints</h2>

    <v-skeleton-loader
        style="width: 100%; height: 100%;"
        v-if="loading"
        type="table-tbody"
    ></v-skeleton-loader>

    <v-data-table
        dark
        v-else
        id="print-table"
        :headers="headers"
        :items="prints"
        :items-per-page="5"
        dense
        calculate-widths
        mobile-breakpoint="200"
    >
      <template
          v-slot:item.price="{ item }"
      >
        {{ `\$${item.price.toFixed(2)}` }}
      </template>

      <template
          v-slot:item.size="{ item }"
      >
        {{ abbrNum(item.size) }}
      </template>

      <template
          v-slot:item.total="{ item }"
      >
        ${{ abbrNum(item.total) }}
      </template>

      <template
          v-slot:item.epochTime="{ item }"
      >
        {{ formatDate(item.epochTime*1000) }}
      </template>
      <template
              v-slot:item.side="{ item }"
      >
        <div v-html="determinePrintType(item)"></div>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import {abbreviateNumber} from "@/helpers/formatting";
import apiService from "@/api/daily-data";

export default {
  name: "DarkPrintsTTG",
  props: {
    ticker: String
  },
  async mounted() {
    let dpData = await apiService.getDarkPoolDataPerStockTTG(this.ticker);
    this.printData = await dpData.json();
    this.prints = this.printData;
    //ORDERS_COLLECTION.where('symbol', '==', this.ticker.toUpperCase())
    //    .orderBy('epochTime', 'desc')
    //    .get()
    //    .then(querySnapshot => {
    //      this.prints = querySnapshot.docs.map(doc => {
    //        const data = doc.data();
    //        const side = this.determinePrintType(data.exchange.split("|")[0]);
    //        return {side, ...data}
    //      });
    //      this.loading = false;
    //    })
    this.loading = false;
  },
  data: () => ({
    loading: true,
    headers: [
      {
        text: "Price",
        align: 'center',
        sortable: true,
        value: 'price',
      },
      {
        text: "Size",
        align: 'center',
        sortable: true,
        value: 'size',
      },
      {
        text: "Value",
        align: 'center',
        sortable: true,
        value: 'total',
      },
      {
        text: "Time",
        align: 'center',
        sortable: true,
        value: 'epochTime',
      },
      {
        text: "Type",
        align: 'center',
        sortable: true,
        value: 'side',
      },
    ],
    prints: [],
  }),
  methods: {
    determinePrintType: function (print) {

      // TODO: Make these icons instead of text?
      let exchange = print.exchange.split("|")[0];
      if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
        return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";

      let lightText = this.$vuetify.breakpoint.smAndDown ? "L " : "Light "
      if (print.bidAsk === "AtAsk")
        return `${lightText}<span style="font-family: 'Verdana', sans-serif">(A)</span>`;
      else if (print.bidAsk === "AtBid") return `${lightText}<span style="font-family: 'Verdana', sans-serif">(B)</span>`;
      else if (print.bidAsk === "Inside") return `${lightText}<span style="font-family: 'Verdana', sans-serif">(I)</span>`;
      else return lightText;
    },
    formatDate: function (millis) {
      let date = new Date(millis);
      return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    },
    abbrNum: abbreviateNumber
  }

}
</script>

<style scoped>

  #dp-prints-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  #print-table {
    padding-top: 10px;
    width: 100%;
    background-color: #4C4C4C;
  }

 >>>td {
    padding: 16px 0 !important;
  }

 >>>.v-skeleton-loader__table-tbody {
   background-color: #4C4C4C !important;
 }


</style>