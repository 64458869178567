<template>
    <div id="details-container">
        <div style="padding: 16px 0" class="flex-cl-center-start">
                    <table
                            class="deets-table"
                            v-for="(table, index) in tables"
                            :key="index"
                    >
                        <tr>
                            <th
                                    v-for="(header, index) in table.headers"
                                    :key="index"
                            >{{ header }}</th>
                        </tr>

                        <tr>
                            <td
                                    v-for="(column, index) in table.columns"
                                    :key="index"
                            >{{ column }}</td>
                        </tr>
                    </table>
                </div>
    </div>
</template>

<script>
    import {abbreviateNumber} from "@/helpers/formatting";
    import apiService from "@/api/daily-data";

    export default {
        name: "DetailsTabTTG",
        props: {
            ticker: String
        },
        async mounted() {
            let dpData = await apiService.getStockDataTTG(this.ticker);
            this.printData = await dpData.json();
            this.stock = this.printData[0];

            this.tables.push({
                headers: ["Earnings Date"],
                columns: [
                    this.checkAvail(this.stock.EarningsDate)
                ]
            });

            this.tables.push({
                headers: ["Sector", "Industry"],
                columns: [
                    this.checkAvail(this.stock.Sector),
                    this.checkAvail(this.stock.Industry),
                ]
            });

            this.tables.push({
                headers: ["Avg. Vol", "Float"],
                columns: [
                    this.abbrNum(this.stock.avgVolume * 1000),
                    this.abbrNum(this.stock.sharesFloat * 1000000),
                ]
            })

            this.tables.push({
                headers: ["P/E", "RSI-14"],
                columns: [
                    this.abbrNum(this.stock.pe),
                    this.checkAvail(this.stock.rsi14),
                ]
            })

            this.tables.push({
                headers: ["Ins Own", "Inst. Own", "MCap"],
                columns: [
                    `${this.checkAvail(this.stock.insiderOwnership, {suffix: '%'})}`,
                    `${this.checkAvail(this.stock.institutionalOwnership, {suffix: '%'})}`,
                    `${this.abbrNum(this.stock.marketCap * 1000000, {prefix:'$'})}`
                ]
            })

            this.tables.push({
                headers: ["SMA200", "SMA50", "SMA20"],
                columns: [
                    `${this.checkAvail(this.stock.sma200, {suffix: '%'})}`,
                    `${this.checkAvail(this.stock.sma50, {suffix: '%'})}`,
                    `${this.checkAvail(this.stock.sma20, {suffix: '%'})}`
                ]
            })
        },

        data: () => ({
            tables: [],
            tab: 0
        }),

        methods: {
            formatEarnings: function (earningsTimestamp) {
                if (!isNaN(earningsTimestamp)) {
                    return new Date(earningsTimestamp*1000).toLocaleDateString()
                } else {
                    return "N/A"
                }
            },
            checkAvail: function (str) {
                if (str.length > 0) return str;
                else return "N/A";
            },
            abbrNum: abbreviateNumber
        }
    }
</script>

<style scoped>
    #details-container {
        width: 100%;
    }

    .deets-table {
        width: 100%;
        color: white;
        margin-bottom: 1rem;
        table-layout: fixed;
    }

    th {
        text-align: left;
    }

    td, th {
        border: 1px none #999;
        padding: 0.5rem;
        text-align: left;
    }
</style>