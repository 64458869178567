<template>
    <div id="details-container">
        <v-tabs dark color=#65BA7D background-color=#323232 v-model="tab">
            <v-tab background-color="transparent" v-for="item in items" :key="item.tab">
                {{ item.tab }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item.tab">
                <v-card-text id="container">
                    <component v-bind:is="item.content"></component>
                </v-card-text>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import HighFloatShort from "./HighFloatShort";
    import HighShortVolume from "./HighShortVolume";
    import HighFeeStock from "./HighFeeStock";

    export default {
        name: "DashboardShort",
        components: {
            HighFloatShort,
            HighShortVolume,
            HighFeeStock,
        },
        data() {
            return {
                tab: null,
                items: [
                    {tab: 'High Float Short %', content: 'HighFloatShort'},
                    {tab: 'High Short Volume', content: 'HighShortVolume'},
                    {tab: 'High Fee Stocks', content: 'HighFeeStock'},
                ],
            }
        },
    }
</script>

<style scoped>
    #details-container {
        width: 100%;
    }

    .deets-table {
        width: 100%;
        color: white;
        margin-bottom: 1rem;
        table-layout: fixed;
    }

    th {
        text-align: left;
    }

    #container {
        width: 100%;
        padding: 5px;
    }

    td, th {
        border: 1px none #999;
        padding: 0.5rem;
        text-align: left;
    }
</style>