<template>

  <div id="app">

    <v-app >

      <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons">


      <NavBar />

          <v-content>
            <router-view :key="$route.fullPath"/>
          </v-content>

      <!-- route outlet -->
      <!-- component matched by the route will render here -->

    </v-app>
  </div>
</template>

<script>
  import NavBar from './components/NavBar'

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -ms-overflow-style: none;
  }

  * ::-webkit-scrollbar {
    display: none;
  }

  body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.4;
  }

  .v-navigation-drawer {
      z-index: 999999 !important;
  }

  #app {
    background-color: #292929;
    min-height: 100vh;
  }

</style>
