<template>

    <div id="charts-container">

        <div class="chartElem">
            <div class="row1">
                <v-container>
                    <v-row justify="center" align="center" class="row">
                        <v-form @submit.prevent="onEnter" ref="form" class="mx-2" v-model="valid" lazy-validation>
                            <v-text-field
                                    v-model="stock"
                                    required
                                    label="Stock"
                                    :rules="stockRules"
                                    prepend-inner-icon="mdi-magnify"
                                    outlined
                                    clearable
                            ></v-text-field>
                        </v-form>
                    </v-row>
                </v-container>
            </div>
                <v-row no-gutters>
                    <v-col
                            cols="12"
                            lg="3"
                            md="12"

                    >
                        <v-card
                                class="pa-1"
                        >
                            <highcharts class="chart" :options="sectorChart" :updateArgs="updateSectorChart" ref="highcharts1"></highcharts>
                        </v-card>
                    </v-col>
                    <v-col
                            cols="12"
                            lg="6"
                            md="12"
                    >
                        <v-card
                                class="pa-1"
                        >
                            <highcharts id="myChart" class="chart" :options="flowPrem" :updateArgs="updatePremChartArgs" ref="highcharts2"></highcharts>
                        </v-card>
                    </v-col>
                    <v-col
                            cols="12"
                            lg="3"
                            md="12"
                    >
                        <v-card
                                class="pa-1"
                        >
                            <p class="text-center">Largest Opening Flow - Today</p>
                            <div id="streaming-container-summary" class="flex-cl-center-start">
                                <v-skeleton-loader
                                        style="width: 100%; height: 100%;"
                                        v-if="loading"
                                        type="image"
                                ></v-skeleton-loader>

                                    <v-data-table
                                        v-else
                                        dark
                                        id="print-table2"
                                        :headers="headersSummary"
                                        :items="indexedItems"
                                        :footer-props="{'items-per-page-options':[10, 20]}"
                                        :item-class="itemRowBackground"
                                        dense
                                        item-key="id"
                                        :single-expand="singleExpand"
                                        :expanded.sync="expanded"
                                        show-expand
                                        calculate-widths
                                        mobile-breakpoint="20"
                                >

                                    <template slot="no-data">
                                        <v-alert :value="true" color="" icon="">
                                            No Flow Found
                                        </v-alert>
                                    </template>
                                    <template v-slot:expanded-item="{ headers, item }">
                                        <td :colspan="headers.length">
                                            <v-simple-table id="print-table3" dense>
                                                <template v-slot:default>
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">
                                                            Expiration
                                                        </th>
                                                        <th class="text-left">
                                                            Side
                                                        </th>
                                                        <th class="text-left">
                                                            CP
                                                        </th>
                                                        <th class="text-left">
                                                            Strike
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        <td class="text-left">  {{item.expiry}}</td>
                                                        <td class="text-left"><v-chip small
                                                                :color="getSideColor(item)"
                                                                dark
                                                        >
                                                            <div v-html="determinePrintType(item)"></div>
                                                        </v-chip></td>
                                                        <td class="text-center">  {{   item.cp }}</td>
                                                        <td class="text-center">  {{item.strike}}</td>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </td>

                                    </template>
                                    <template
                                            v-slot:item.symbol="{ item }"
                                    >
                                        {{ item.symbol }}
                                    </template>

                                    <template
                                            v-slot:item.expiry="{ item }"
                                    >
                                        {{item.expiry}}
                                    </template>

                                    <template
                                            v-slot:item.strike="{ item }"
                                    >
                                        {{item.strike}}
                                    </template>
                                    <template v-slot:item.sum="{ item }">
                                        <v-chip small
                                                :color="getColor(item)"
                                                dark
                                        >
                                            ${{ abbrNum(item.sum) }}
                                        </v-chip>

                                    </template>
                                    <template
                                            v-slot:item.epochTime="{ item }"
                                    >
                                        {{ formatDate(item.epochTime*1000) }}
                                    </template>
                                    <template
                                            v-slot:item.trade="{ item }"
                                    >
                                        <div v-html="determineTradeType(item)"></div>

                                    </template>

                                    <template
                                            v-slot:item.condExtra="{ item }"
                                    >

                                        <div v-html="determineUnusualType(item)"></div>

                                    </template>
                                </v-data-table>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>




            <br>
            <div align="center" style="font-size:.75em" class="helpText">Opening flow listed below if any exists. Click on a Bar in the Flow Premium graph for a detailed drilldown on that date.
            </div>
            <div id="streaming-container" class="flex-cl-center-start">
                <v-skeleton-loader
                        style="width: 100%; height: 100%;"
                        v-if="loading"
                        type="image"
                ></v-skeleton-loader>


                <v-data-table
                        v-else
                        dark
                        id="print-table"
                        :headers="headers"
                        :items="prints"
                        :footer-props="{'items-per-page-options':[10, 20]}"
                        :search="search"
                        :item-class="itemRowBackground"
                        dense
                        calculate-widths
                        mobile-breakpoint="20"
                >

                    <template slot="no-data">
                        <v-alert :value="true" color="" icon="">
                            No Opening Flow Found - Click on a Flow Premium Bar to load historical or daily data.
                        </v-alert>
                    </template>

                    <template
                            v-slot:item.symbol="{ item }"
                    >
                        {{ item.symbol }}
                    </template>

                    <template
                            v-slot:item.spot="{ item }"
                    >
                        {{ `\$${item.spot.toFixed(2)}` }}
                    </template>

                    <template
                            v-slot:item.expiry="{ item }"
                    >
                        {{item.expiry}}
                    </template>

                    <template
                            v-slot:item.strike="{ item }"
                    >
                        {{item.strike}}
                    </template>



                    <template v-slot:item.sum="{ item }">
                        <v-chip
                                :color="getColor(item)"
                                dark
                        >
                            ${{ abbrNum(item.sum) }}
                        </v-chip>

                    </template>



                    <template
                            v-slot:item.epochTime="{ item }"
                    >
                        {{ formatDate(item.epochTime*1000) }}
                    </template>
                    <template
                            v-slot:item.cp="{ item }"
                    >
                        {{ item.cp }}
                    </template>
                    <template
                            v-slot:item.oi="{ item }"
                    >

                        {{ Number(item.oi).toLocaleString() }}
                    </template>
                    <template
                            v-slot:item.trade="{ item }"
                    >
                        <div v-html="determineTradeType(item)"></div>

                    </template>
                    <template
                            v-slot:item.side="{ item }"
                    >
                        <v-chip
                                :color="getSideColor(item)"
                                dark
                        >

                            <div v-html="determinePrintType(item)"></div>
                        </v-chip>
                    </template>
                    <template
                            v-slot:item.cond="{ item }"
                    >
                        <div v-html="determineCondType(item)"></div>
                    </template>
                    <template
                            v-slot:item.condExtra="{ item }"
                    >

                        <div v-html="determineUnusualType(item)"></div>

                    </template>


                    <template v-slot:footer>
                        <div style="position: absolute; font-size:.75em" class="pa-0 pl-2" label="Legend" hide-details><br><br><br><br>S - Sweep, SP - Split, C - Complex, O - Opening (Vol>OI), A - Ask, B - Bid, BB - Below Bid <br>
                            AA - Above Ask, LB - Lean Bid, LA - Lean Ask - All contracts are $25k+ prem for Stocks and $100k+ prem for ETFs <br>
                            Unusual - column identifies size of order to current OI. 25-50x means volume of order was 25-50 times the current OI.
                        </div>
                    </template>

                </v-data-table>
            </div>

        </div>
    </div>
</template>


<script>
    import apiService from '../api/daily-data';
    import Highcharts from "highcharts";
    import {abbreviateNumber} from "@/helpers/formatting";
    import {COOKIE_USER_AUTH, getCookie} from "@/utils/CookieHelper";



    export default {
        data() {
            return {
                expanded: [],
                singleExpand: true,
                stockRules: [
                    v => !!v || 'Stock symbol is required',
                ],
                chart: undefined,
                value: null,
                valid: null,
                loader: null,
                loading2: false,
                title: '',
                stock: null,
                loading: true,
                dates: [],
                calls: [],
                puts: [],
                flowsumCalls : [0,0,0,0,0,0,0],
                flowsumPuts : [0,0,0,0,0,0,0],
                seriesColor: '#6fcd98',
                colorInputIsSupported: null,
                animationDuration: 1000,
                updatePremChartArgs: [true, true, {duration: 1000}],
                updateSectorChart: [true, true, {duration: 1000}],
                search: '',
                sectorChart: {
                    chart: {
                        type: 'bar',
                        height: 500 + 'px',
                        backgroundColor: '#40403d',
                        margin: [30, 90, 30, 90]
                    },
                    title: {
                        text: 'AAPL Flow Sum'
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: [{
                        categories: ['AboveAsk', 'Ask', 'LeanAsk','InBtwn', 'LeanBid', 'Bid','BelowBid']
                    },{
                        categories: ['BelowBid', 'Bid', 'LeanBid','InBtwn','LeanAsk','Ask','AboveAsk'],
                        opposite: true,
                        reversed: true,
                    }],
                    yAxis: [{
                        offset: 0,
                        width: '46%',
                        title: {
                            text: 'Calls'
                        },
                        max: 35000000,
                        gridLineWidth : 0,
                        labels: {
                            enabled: false
                        }
                    }, {
                        offset: 0,
                        labels: {
                            enabled: false
                        },
                        reversed: true,
                        left: '54%',
                        width: '46%',
                        title: {
                            text: 'Puts'
                        },
                        max: 35000000,
                        gridLineWidth : 0
                    }],
                    series: [{
                        xAxis: 0,
                        data: [1, 2, 3, 4, 5, 6, 7],
                        color: '#66bb6a',
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (this.y > 1000000) {
                                    return Highcharts.numberFormat(this.y / 1000000, 2) + "M"
                                } else if (this.y > 1000) {
                                    return Highcharts.numberFormat(this.y / 1000, 2) + "K";
                                } else {
                                    return this.y
                                }
                            }
                        }
                    }, {
                        xAxis: 1,
                        yAxis: 1,
                        data: [4, 5, 6, 7, 32, 3, 1],
                        color: '#ef5350',
                        dataLabels: {
                            enabled: true,
                            formatter: function() {
                                if (this.y > 1000000) {
                                    return Highcharts.numberFormat(this.y / 1000000, 2) + "M"
                                } else if (this.y > 1000) {
                                    return Highcharts.numberFormat(this.y / 1000, 2) + "K";
                                } else {
                                    return this.y
                                }
                            }
                        }
                    }]
                },
                flowPrem: {
                    chart: {
                        height: 500 + 'px',
                        renderTo: 'myChart',
                        //plotBackgroundImage: 'https://optionsfambot.com/assets/test.png',
                        backgroundColor: '#40403d',
                        type: 'column',
                        margin: [40, 50, 40, 50],
                        plotBorderWidth: 2
                    },
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: ({point}) => {
                                        this.getDataFromChartClickHistory(point.category, point.color);
                                    }
                                }
                            }
                        }
                    },
                    legend: {
                        align: 'top',
                        verticalAlign: 'left',
                        x: 60,
                        y: 25,
                        floating: true
                    },
                    xAxis: {
                        type: 'category',
                        title: {
                            text: 'Date'
                        },
                        labels: {
                            reserveSpace: true,
                            style: {
                                fontSize: '6px'
                            },
                        },
                    },
                    yAxis: {
                        opposite:false,
                        gridLineWidth: 0,

                    },
                    title: {
                        text: 'AAPL Flow Premium $ Value'
                    },
                    series: [{
                        name: "Call Premium",
                        data: [],
                        color: '#66bb6a',
                        dataLabels: {
                            enabled: true,
                            allowOverlap: true,
                            formatter: function () {
                                if (this.y > 1000000) {
                                    return Highcharts.numberFormat(this.y / 1000000, 2) + "M"
                                } else if (this.y > 1000) {
                                    return Highcharts.numberFormat(this.y / 1000, 2) + "K";
                                } else {
                                    return this.y
                                }
                            }
                        }
                    }, {
                        name: "Put Premium",
                        data: [],
                        color: '#ef5350',
                        dataLabels: {
                            allowOverlap: true,
                            enabled: true,
                            formatter: function () {
                                if (this.y> 1000000) {
                                    return Highcharts.numberFormat(this.y / 1000000, 2) + "M"
                                } else if (this.y > 1000) {
                                    return Highcharts.numberFormat(this.y / 1000, 2) + "K";
                                } else {
                                    return this.y
                                }
                            }
                        }
                    },
                    ]
                },lightPrintColorMap: {
                    "Light_AA": "#005005",
                    "Light_A": "#2e7d32",
                    "Light_LA": "#60ad5e",
                    "Light_BB": "#8e0000",
                    "Light_B": "#c62828",
                    "Light_LB": "#ff5f52",
                    "Light_I": "#2180CE",
                },
                sectorColorMap: {
                    "Basic Materials": "#666e18",
                    "Communication Services": "#DE832F",
                    "Consumer Cyclical": "#75206b",
                    "Consumer Defensive": "#2F65DE",
                    "Energy": "#025c1d",
                    "Financial": "#3c4d3e",
                    "Healthcare": "#1e3133",
                    "Industrials": "#3f3c69",
                    "Real Estate": "#693827",
                    "Technology": "#2180CE",
                    "Utilities": "#ab4f2b"
                },
                tableHeight: 0,
                headers: [
                    {
                        text: "Symbol",
                        align: 'center',
                        sortable: true,
                        value: 'symbol',
                    },
                    {
                        text: "Spot",
                        align: 'center',
                        sortable: true,
                        value: 'spot',
                    },
                    {
                        text: "Expiry",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'expiry',
                    },
                    {
                        text: "Strike",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'strike',
                    },
                    {
                        text: "Sum",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'sum',
                    },
                    {
                        text: "Time",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'epochTime',
                    },
                    {
                        text: "CP",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'cp',
                    },
                    {
                        text: "OI",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'oi',
                    },
                    {
                        text: "Trade",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'trade',
                    },
                    {
                        text: "Side",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'side',
                    },
                    {
                        text: "Cond",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'cond',
                    },
                    {
                        text: "Unusual",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'condExtra',
                    },
                ],
                headersSummary: [
                    {
                        text: "Symbol",
                        align: 'center',
                        sortable: true,
                        value: 'symbol',
                    },
                    {
                        text: "Sum",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'sum',
                    },
                    {
                        text: "Time",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'epochTime',
                    },
                    {
                        text: "Unusual",
                        align: 'center',
                        sortable: true,
                        filterable: false,
                        value: 'condExtra',
                    },
                ],
                snapshotInitState: true,
                printData: [],
                prints: [],
                printsSummary: [],
                printDataSummary: []
            }
        },
        mounted: async function () {

            var cookie = getCookie(COOKIE_USER_AUTH)
            if (!cookie){
                window.location.href = 'https://truetradinggroup.com/member-dashboard/options-dark-pool-access-link/';
            }


            await this.loadSideChart("aapl")
            await this.loadPremChart("aapl")


            let backlogSummary = await apiService.getSummaryOpeningFlow();
            this.printDataSummary = await backlogSummary.json();
            this.printsSummary = this.printDataSummary;
            this.loading = false;




            // Initial load of Opening Data for Aapl
            let backlog = await apiService.getOpeningData("aapl");
            try {
                this.printData = await backlog.json();
                this.prints = this.printData;
                this.loading = false;
            } catch (e){
                this.prints = []
                this.loading = false;
            }


        },
        methods: {
            getDataFromChartClickHistory: async function (date, side) {
                let cp = "P"
                if (side === "#66bb6a") {
                    cp = "C"
                }
                let todayDate = new Date().toISOString()
                let todayLive = todayDate.split("T")
                todayLive = todayLive[0]
                // check if the row they clicked is live data or not. figure this out...then either call live or historical
                if (date === todayLive) {
                    let data = await apiService.getDataLive(this.stock, date, cp);
                    let jsonData = await data.json();
                    this.prints = jsonData;
                }else {
                    let data = await apiService.getDataHistory(this.stock, date, cp);
                    let jsonData = await data.json();
                    this.prints = jsonData;
                }
            },
            getDataSideChart: async function () {
                let data = await apiService.getTestData(this.stock);
                let jsonData = await data.json();
                return jsonData;
            },
            loadPremChart: async function (symbol) {
                this.stock = symbol
                let flowPremData = await this.getDataChart1();
                this.flowPremChart(flowPremData);
            },
            loadSideChart: async function (symbol) {
                try {
                    this.stock = symbol
                    let sideData = await this.getDataSideChart();
                    this.sideChart(sideData);
                }catch{
                    this.sectorChart.title.text = this.stock.toUpperCase() + " Daily Flow Sum"
                    this.flowsumCalls = [0,0,0,0,0,0,0]
                    this.flowsumPuts = [0,0,0,0,0,0,0]
                    this.sectorChart.series[0].data = this.flowsumCalls
                    this.sectorChart.series[1].data = this.flowsumPuts
                }
            },
            loadOpeningData: async function (symbol) {
                try {
                    this.stock = symbol
                    let backlog = await apiService.getOpeningData(this.stock);
                    this.printData = await backlog.json();
                    this.prints = this.printData;
                }catch{
                    this.prints = []
                }
            },
            //updateSideChart: async function (symbol) {
            //    this.stock = symbol
            //    let sideData = await this.getDataSideChart();
            //    this.updateFlowSideChart(sideData);
            //},
            updatePremChart: async function (symbol) {
                this.stock = symbol
                let chart1 = await this.getDataChart1();
                this.calls = []
                this.puts = []
                this.flowPremChart(chart1);
            },
            async onEnter(e) {
                e.preventDefault();
                await this.loadSymbol();
            },
            async loadSymbol () {

                this.loading2 = true
                var validator = this.$refs.form.validate();

                if (validator) {

                    this.loading = false;

                    // Initial load of Opening Data for Aapl
                    this.loading = true;
                    await this.loadSideChart(this.stock)
                    await this.updatePremChart(this.stock)
                    await this.loadOpeningData(this.stock)

                    try {
                        this.loading = false;
                        this.loading2 = false
                    } catch (e) {
                        this.prints = []
                        this.loading = false;
                        this.loading2 = false
                    }
                }else{
                    this.loading2 = false
                }
            },
            getDataChart1: async function () {
                let data = await apiService.getTestData2(this.stock);
                let jsonData = await data.json();
                return jsonData;
            },
            flowPremChart: function (data) {
                this.stocks = data;
                this.dates = []
                this.flowPrem.series[0].data = this.calls
                this.flowPrem.series[1].data = this.puts
                //this.dates = []
                for (var i = 0; i < this.stocks.length; i++) {
                    if (this.stocks[i]["cp"] === "P") {
                        let dateString = this.stocks[i]["date"].split("T")
                        this.puts.push([dateString[0],this.stocks[i]["sum"],"Put"]);
                        this.dates.push(dateString[0])
                    }
                    if (this.stocks[i]["cp"] === "C") {
                        let dateString = this.stocks[i]["date"].split("T")
                        this.calls.push([dateString[0],this.stocks[i]["sum"],"Call"]);
                        this.dates.push(dateString[0])
                    }
                }
                this.dates = [... new Set(this.dates)]
                this.flowPrem.xAxis.categories = this.dates
                this.flowPrem.title.text = this.stock.toUpperCase() + " Flow Premium $ Value"
                this.flowPrem.series[0].data = this.calls
                this.flowPrem.series[1].data = this.puts
            },
            updateFlowPremChart: function (data) {
                this.stocks = data;
                let chartref3 = this.$refs.highcharts2.chart;
                this.calls = []
                this.puts = []
                this.dates = []
                chartref3.xAxis.categories = this.dates
                chartref3.series[0].setData(this.calls)
                chartref3.series[0].setData(this.puts)
                for (var i = 0; i < this.stocks.length; i++) {
                    if (this.stocks[i]["cp"] === "P") {
                        let dateString = this.stocks[i]["date"].split("T")
                        this.puts.push([dateString[0],this.stocks[i]["sum"],"Put"]);
                        this.dates.push(dateString[0])
                    }
                    if (this.stocks[i]["cp"] === "C") {
                        let dateString = this.stocks[i]["date"].split("T")
                        this.calls.push([dateString[0],this.stocks[i]["sum"],"Call"]);
                        this.dates.push(dateString[0])
                    }
                }
                this.puts.push({"name" : "Puts"})
                this.calls.push({"name" : "Calls"})
                this.dates = [... new Set(this.dates)]
                this.flowPrem.title.text = this.stock.toUpperCase() + " Flow Premium $ Value"
                chartref3.xAxis.categories = this.dates
                chartref3.title.textStr = this.stock + " Flow Premium $ Value"
                chartref3.series[0].setData(this.calls)
                chartref3.series[1].setData(this.puts)


            },
            sideChart: function (data) {
                let self = this;
                self.stocks = data;
                self.flowsumCalls = [0,0,0,0,0,0,0]
                self.flowsumPuts = [0,0,0,0,0,0,0]


                for(var i=0; i<self.stocks.length; i++){

                    if (self.stocks[i]["cp"] === "C") {

                        if (self.stocks[i]["Side"] === 3){
                            self.flowsumCalls[0] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === 2){
                            self.flowsumCalls[1] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === 1){
                            self.flowsumCalls[2] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === 0){
                            self.flowsumCalls[3] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === -1){
                            self.flowsumCalls[4] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === -2){
                            self.flowsumCalls[5] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === -3){
                            self.flowsumCalls[6] = self.stocks[i]["sum"]
                        }

                    }
                    if (self.stocks[i]["cp"] === "P") {
                        if (self.stocks[i]["Side"] === 3){
                            self.flowsumPuts[6] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === 2){
                            self.flowsumPuts[5] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === 1){
                            self.flowsumPuts[4] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === 0){
                            self.flowsumPuts[3] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === -1){
                            self.flowsumPuts[2] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === -2){
                            self.flowsumPuts[1] = self.stocks[i]["sum"]
                        }
                        if (self.stocks[i]["Side"] === -3){
                            self.flowsumPuts[0] = self.stocks[i]["sum"]
                        }
                    }
                }

                var findMax = self.flowsumCalls.concat(self.flowsumPuts);
                var findMaxValue = Math.max(...findMax)
                findMaxValue = (findMaxValue * .02) + findMaxValue
                this.sectorChart.series[0].data = self.flowsumCalls
                this.sectorChart.series[1].data = self.flowsumPuts
                this.sectorChart.yAxis[0].max = findMaxValue
                this.sectorChart.yAxis[1].max = findMaxValue
                this.sectorChart.title.text = this.stock.toUpperCase() + " Daily Flow Sum"
            },

            // Stuff for bottom table below //



            determinePrintType: function (print) {

                // TODO: Make these icons instead of text?
                //let exchange = print.exchange.split("|")[0];
                //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
                //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
                let lightText = this.$vuetify.breakpoint.smAndDown ? "AA" : "Above Ask"
                if (print.side === 3)
                    return this.$vuetify.breakpoint.smAndDown ? "AA" : `<span style="font-size:.7rem">ABOVE ASK</span>`;
                else if (print.side === 2) return this.$vuetify.breakpoint.smAndDown ? "A" : `<span style="font-size:.7rem">ASK</span>`;
                else if (print.side === 1) return this.$vuetify.breakpoint.smAndDown ? "LA" : `<span style="font-size:.7rem">LEAN ASK</span>`;
                else if (print.side === 0) return this.$vuetify.breakpoint.smAndDown ? "B" : `<span style="font-size:.7rem">BETWEEN</span>`;
                else if (print.side === -1) return this.$vuetify.breakpoint.smAndDown ? "LB" : `<span style="font-size:.7rem">LEAN BID</span>`;
                else if (print.side === -2) return this.$vuetify.breakpoint.smAndDown ? "B" : `<span style="font-size:.7rem">BID</span>`;
                else if (print.side === -3) return this.$vuetify.breakpoint.smAndDown ? "BB" : `<span style="font-size:.7rem">BELOW BID</span>`;
                else return lightText;
            },
            determineUnusualType: function (print) {

                // TODO: Make these icons instead of text?
                //let exchange = print.exchange.split("|")[0];
                //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
                //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
                let lightText = "";
                try {
                    if (print.condExtra.includes("1-5")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white">1-5x</span>` : `<span style="color: white">1-5x</span>`;
                    else if (print.condExtra.includes("5-10")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white; font-weight: bold">5-10x</span>` : `<span style="color: white; font-weight: bold">5-10x</span>`;
                    else if (print.condExtra.includes("10-25")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white">10-25x</span>` : `<span style="color: white">10-25x</span>`;
                    else if (print.condExtra.includes("25-50")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white">25-50x</span>` : `<span style="color: white">25-50x</span>`;
                    else if (print.condExtra.includes(">50")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: gold">>50x</span>` : `<span style="color: gold">>50x</span>`;
                    else return lightText;
                }catch{
                    return lightText;
                }
            },
            determineCondType: function (print) {

                // TODO: Make these icons instead of text?
                //let exchange = print.exchange.split("|")[0];
                //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
                //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
                let lightText = "";
                if (print.cond.includes("OPENING")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: cyan">O</span>` : `<span style="color: cyan">OPENING</span>`;
                else if (print.cond.includes("COMPLEX")) return this.$vuetify.breakpoint.smAndDown ? "C" : "COMPLEX";
                else return lightText;
            },
            determineTradeType: function (print) {

                // TODO: Make these icons instead of text?
                //let exchange = print.exchange.split("|")[0];
                //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
                //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
                let lightText = "";
                if (print.trade.includes("SWEEP")) return this.$vuetify.breakpoint.smAndDown ? "S" : "SWEEP";
                else if (print.trade.includes("SPLIT")) return this.$vuetify.breakpoint.smAndDown ? "SP" : "SPLIT";
                else if (print.trade === ("")) return this.$vuetify.breakpoint.smAndDown ? "B" : "BLOCK";
                else return lightText;
            },
            formatDate: function (millis) {
                let date = new Date(millis);
                return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
            },
            abbrNum: abbreviateNumber,
            getColor (item) {
                if (item.cp === "P") return 'rgba(255, 0, 0, 0.30)'
                else return 'rgba(0, 128, 0, .40)'

            },
            getSideColor (item) {
                if (item.side === 3)
                    return this.lightPrintColorMap.Light_AA;
                else if (item.side === 2) return this.lightPrintColorMap.Light_A;
                else if (item.side === 1) return this.lightPrintColorMap.Light_LA;
                else if (item.side === 0) return this.lightPrintColorMap.Light_I;
                else if (item.side === -1) return this.lightPrintColorMap.Light_LB;
                else if (item.side === -2) return this.lightPrintColorMap.Light_B;
                else if (item.side === -3) return this.lightPrintColorMap.Light_BB;
            },
            itemRowBackground: function (item) {
                return item.cp === "P" ? 'put' : 'call'
            },
            created() {
                let i = document.createElement('input')
                i.setAttribute('type', 'color');
                (i.type === 'color') ? this.colorInputIsSupported = true : this.colorInputIsSupported = false
            }

        },
        computed: {
            indexedItems () {
                return this.printsSummary.map((item, index) => ({
                    id: index,
                    ...item
                }))
            }
        }
    }
</script>

<style scoped>
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    .v-select.v-input--is-dirty input {
        display: none;
    }

    .v-select__selection {
        max-width: none;
    }

    .chartElem {
        padding-top: 5px;
        width: 100%;
        background-color: #4C4C4C;
        height: 100%;
    }

    #test{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

    }
    #charts-container {
        width: 100%;
        height: 100%;
        background-color: #323232;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Roboto', sans-serif !important;
    }
    .row1{
        margin : auto;
        text-align:center;
        justify-content:center;
        align-items: center;
        font-family: 'Roboto', sans-serif !important;
    }
    .chart {
        min-width: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        border: 2px;
        padding: 2px;
        padding-top: 4px;
        background-color: #4C4C4C;
        font-family: 'Roboto', sans-serif !important;
    }


    #colorPicker {
        border: 0;
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
    }
    .numberInput {
        width: 30px;
    }
    #streaming-container {
        height: 100%;
        width: 100%;
        background-color: #323232;
        padding: 1%;
    }

    h1 {
        text-align:center;
    }
    .helpText{
        padding-bottom: 5px;
    }
    #print-table {
        height: 100%;
        border-radius: 0 !important;
        width: 100%;
        overflow-y: auto;

        background-color: #323232;
        box-shadow: none;
    }
    #print-table2 {
        height: 100%;
        border-radius: 0 !important;
        width: 100%;
        background-color: #323232;
        box-shadow: none;
    }
    #print-table3 {
        height: 100%;
        border-radius: 0 !important;
        width: 100%;
        background-color: #323232;
        box-shadow: none;
    }
    #streaming-container-summary {
        min-width: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        background-color: #323232;
        padding: 1%;
    }
    .pa-1{
        min-height: 100%;
        max-height: 100%;
        background-color: #323232;
    }

</style>