var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dp-prints-container"}},[_c('h2',{staticClass:"section-header"},[_vm._v("Individual Prints")]),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"dark":"","id":"print-table","headers":_vm.headers,"items":_vm.prints,"items-per-page":5,"dense":"","calculate-widths":"","mobile-breakpoint":"200"},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("$" + (item.price.toFixed(2))))+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.abbrNum(item.size))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.abbrNum(item.total))+" ")]}},{key:"item.epochTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.epochTime*1000))+" ")]}},{key:"item.side",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.determinePrintType(item))}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }