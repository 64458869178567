<template>
  <div id="dp-prints-container">
    <h2 class="section-header">Individual Flow Prints</h2>

    <v-skeleton-loader
        style="width: 100%; height: 100%;"
        v-if="loading"
        type="table-tbody"
    ></v-skeleton-loader>

    <v-data-table
        dark
        v-else
        id="print-table"
        :headers="headers"
        :items="prints"
        :items-per-page="5"
        dense
        calculate-widths
        mobile-breakpoint="200"
    >
      <template
              v-slot:item.symbol="{ item }"
      >
        {{ item.symbol }}
      </template>

      <template
              v-slot:item.spot="{ item }"
      >
        {{ `\$${item.spot.toFixed(2)}` }}
      </template>

      <template
              v-slot:item.expiry="{ item }"
      >
        {{item.expiry}}
      </template>

      <template
              v-slot:item.strike="{ item }"
      >
        {{item.strike}}
      </template>

      <template
              v-slot:item.sum="{ item }"
      >
        ${{ abbrNum(item.sum) }}
      </template>

      <template
              v-slot:item.epochTime="{ item }"
      >
        {{ formatDate(item.epochTime*1000) }}
      </template>
      <template
              v-slot:item.cp="{ item }"
      >
        {{ item.cp }}
      </template>
      <template
              v-slot:item.oi="{ item }"
      >

        {{ Number(item.oi).toLocaleString() }}
      </template>
      <template
              v-slot:item.trade="{ item }"
      >
        {{ item.trade }}
      </template>
      <template
              v-slot:item.side="{ item }"
      >
        <div v-html="determinePrintType(item)"></div>
      </template>
      <template
              v-slot:item.cond="{ item }"
      >
        <div v-html="determineCondType(item)"></div>
      </template>
      <template
              v-slot:item.condExtra="{ item }"
      >

        <div v-html="determineUnusualType(item)"></div>

      </template>
    </v-data-table>
  </div>
</template>

<script>
import {abbreviateNumber} from "@/helpers/formatting";
import apiService from "@/api/daily-data";

export default {
  name: "DarkPrintsTTG",
  props: {
    ticker: String
  },
  async mounted() {
      let flowData = await apiService.getFlowDataPerStockTTG(this.ticker);
      this.printData = await flowData.json();
      this.prints = this.printData;
    //FLOW_COLLECTION.where('symbol', '==', this.ticker.toUpperCase())
    //    .orderBy('epochTime', 'desc')
    //    .get()
    //    .then(querySnapshot => {
    //      this.prints = querySnapshot.docs.map(doc => {
     //       const data = doc.data();
    //        const side = this.determinePrintType(data);
     //       return {side, ...data}
     //     });
      this.loading = false;
     //   })
  },
  data: () => ({
    loading: true,
    headers: [
      {
        text: "Symbol",
        align: 'center',
        sortable: true,
        value: 'symbol',
      },
      {
        text: "Spot",
        align: 'center',
        sortable: true,
        value: 'spot',
      },
      {
        text: "Expiry",
        align: 'center',
        sortable: true,
        value: 'expiry',
      },
      {
        text: "Strike",
        align: 'center',
        sortable: true,
        value: 'strike',
      },
      {
        text: "Sum",
        align: 'center',
        sortable: true,
        value: 'sum',
      },
      {
        text: "Time",
        align: 'center',
        sortable: true,
        value: 'epochTime',
      },
      {
        text: "CP",
        align: 'center',
        sortable: true,
        value: 'cp',
      },
      {
        text: "OI",
        align: 'center',
        sortable: true,
        value: 'oi',
      },
      {
        text: "Trade",
        align: 'center',
        sortable: true,
        value: 'trade',
      },
      {
        text: "Side",
        align: 'center',
        sortable: true,
        value: 'side',
      },
      {
        text: "Cond",
        align: 'center',
        sortable: true,
        filterable: false,
        value: 'cond',
      },
      {
        text: "Unusual",
        align: 'center',
        sortable: true,
        filterable: false,
        value: 'condExtra',
      },
    ],
    prints: [],
  }),
  methods: {
    determinePrintType: function (print) {

      // TODO: Make these icons instead of text?
      //let exchange = print.exchange.split("|")[0];
      //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
      //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
      let lightText = this.$vuetify.breakpoint.smAndDown ? "AA" : "Above Ask"
      if (print.side === 3)
        return this.$vuetify.breakpoint.smAndDown ? "AA" : "Above Ask"
      else if (print.side === 2) return this.$vuetify.breakpoint.smAndDown ? "A" : "Ask";
      else if (print.side === 1) return this.$vuetify.breakpoint.smAndDown ? "LA" : "Lean Ask";
      else if (print.side === 0) return this.$vuetify.breakpoint.smAndDown ? "B" : "Between";
      else if (print.side === -1) return this.$vuetify.breakpoint.smAndDown ? "LB" : "Lean Bid";
      else if (print.side === -2) return this.$vuetify.breakpoint.smAndDown ? "B" : "Bid";
      else if (print.side === -3) return this.$vuetify.breakpoint.smAndDown ? "BB" : "Below Bid";
      else return lightText;
    },
    determineCondType: function (print) {

      // TODO: Make these icons instead of text?
      //let exchange = print.exchange.split("|")[0];
      //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
      //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
      let lightText = "";
      if (print.cond.includes("OPENING")) return this.$vuetify.breakpoint.smAndDown ? "O" : "Opening";
      else if (print.cond.includes("COMPLEX")) return this.$vuetify.breakpoint.smAndDown ? "C" : "COMPLEX";
      else return lightText;
    },
    determineUnusualType: function (print) {

      // TODO: Make these icons instead of text?
      //let exchange = print.exchange.split("|")[0];
      //if (exchange === "NQNX" || exchange === "BTRF" || exchange === "NTRF")
      //    return this.$vuetify.breakpoint.smAndDown ? `D` : "Dark";
      let lightText = "";
      if (print.condExtra.includes("1-5")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white">1-5x</span>` : `<span style="color: white">1-5x</span>`;
      else if (print.condExtra.includes("5-10")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white; font-weight: bold">5-10x</span>` : `<span style="color: white; font-weight: bold">5-10x</span>`;
      else if (print.condExtra.includes("10-25")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white">10-25x</span>` : `<span style="color: white">10-25x</span>`;
      else if (print.condExtra.includes("25-50")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: white">25-50x</span>` : `<span style="color: white">25-50x</span>`;
      else if (print.condExtra.includes(">50")) return this.$vuetify.breakpoint.smAndDown ? `<span style="color: gold">>50x</span>` : `<span style="color: gold">>50x</span>`;
      else return lightText;
    },
    formatDate: function (millis) {
      let date = new Date(millis);
      return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    },
    abbrNum: abbreviateNumber
  }

}
</script>

<style scoped>

  #dp-prints-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  #print-table {
    padding-top: 10px;
    width: 100%;
    background-color: #4C4C4C;
  }

 >>>td {
    padding: 16px 0 !important;
  }

 >>>.v-skeleton-loader__table-tbody {
   background-color: #4C4C4C !important;
 }


</style>