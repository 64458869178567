var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-cl-center-start",attrs:{"id":"streaming"}},[_c('div',{staticClass:"flex-cl-center-start",attrs:{"id":"streaming-container"}},[_c('script',{attrs:{"type":"application/javascript","src":"https://s3.tradingview.com/tv.js"}}),_vm._m(0),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search Symbol Here...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"dark":"","id":"print-table","headers":_vm.headers,"items":_vm.indexedItems,"item-key":"id","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"footer-props":{'items-per-page-options':[50, 100]},"search":_vm.search,"custom-filter":_vm.filterPerfectMatch,"dense":"","calculate-widths":"","mobile-breakpoint":"200"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" More info about "+_vm._s(item.symbol)+" "),_c('div',{staticClass:"tradingview-widget-container",attrs:{"id":"tv123"}},[_c('script',{attrs:{"type":"application/javascript"}},[_vm._v(" new TradingView.widget( { \"width\": 980, \"height\": 610, \"symbol\": 'BINANCE:"+_vm._s(item.symbol)+"USDT', \"interval\": \"D\", \"timezone\": \"Etc/UTC\", \"theme\": \"dark\", \"style\": \"1\", \"locale\": \"en\", \"toolbar_bg\": \"#f1f3f6\", \"enable_publishing\": false, \"allow_symbol_change\": true, \"container_id\": \"tv123\" } ); ")])])])]}},{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.sectorColorMap[item.sector],"dark":""}},[_vm._v(" "+_vm._s(item.symbol)+" ")])]}},{key:"item.exchange",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.exchange)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price < .01 ? item.price.toFixed(8) : item.price.toLocaleString('en-US', {style: 'currency', currency: 'USD' }))+" ")]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sum.toLocaleString())+" ")]}},{key:"item.premium",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.abbrNum(item.premium.toFixed(0)))+" ")]}},{key:"item.epochTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.epochTime))+" ")]}},{key:"item.side",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getSideColor(item),"dark":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.determinePrintType(item))}})]),(item.count>1)?_c('p'):_vm._e(),(item.count>1)?_c('v-chip',{attrs:{"color":_vm.getSweepColor(item),"dark":"","x-small":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.determineSweepType(item))}})]):_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"pa-0 pl-2",staticStyle:{"position":"absolute","font-size":".75em"},attrs:{"label":"Legend","hide-details":""}},[_c('br'),_vm._v("A - Ask, B - Bid "),_c('br'),_vm._v(" Sweep - Denotes order was made up of 2+ orders"),_c('br')])]},proxy:true}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 pl-2",staticStyle:{"position":"absolute","font-size":".75em"},attrs:{"label":"Legend","hide-details":""}},[_c('br'),_c('br'),_c('br'),_c('br'),_vm._v(" "),_c('br'),_vm._v(" Crypto Stream - Currently following BTC - 150k+, ETH-SOL 75k+, DOGE - SHIB - DOT - LTC - XRP 50k+ "),_c('br'),_vm._v("A - Ask, B - Bid, Sweep - Denotes order was made up of 2+ orders ")])}]

export { render, staticRenderFns }