var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dp-prints-container"}},[_c('h2',{staticClass:"section-header"},[_vm._v("Institutional Changes")]),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"dark":"","id":"print-table","headers":_vm.headers,"items":_vm.prints,"items-per-page":15,"dense":"","calculate-widths":"","mobile-breakpoint":"200"},scopedSlots:_vm._u([{key:"item.origName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.origName)+" ")]}},{key:"item.AsharesSum1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.AsharesSum1).toLocaleString())+" ")]}},{key:"item.BsharesSum2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.BsharesSum2.toLocaleString())+" ")]}},{key:"item.difference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.difference.toLocaleString())+" ")]}},{key:"item.percentDif",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.percentDif.toFixed(2)).toLocaleString())+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }