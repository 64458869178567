var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-cl-center-start",attrs:{"id":"streaming-container"}},[_vm._m(0),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search Symbol Here...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"dark":"","id":"print-table","headers":_vm.headers,"items":_vm.prints,"footer-props":{'items-per-page-options':[50, 100]},"search":_vm.search,"custom-filter":_vm.filterPerfectMatch,"dense":"","calculate-widths":"","mobile-breakpoint":"200"},on:{"click:row":_vm.toExpanded},scopedSlots:_vm._u([{key:"item.symbol",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.sectorColorMap[item.sector],"dark":""}},[_vm._v(" "+_vm._s(item.symbol)+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("$" + (item.price.toFixed(2))))+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.abbrNum(item.size))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm.abbrNum(item.total))+" ")]}},{key:"item.epochTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.epochTime*1000))+" ")]}},{key:"item.side",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.determinePrintType(item))}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"pa-0 pl-2",staticStyle:{"position":"absolute","font-size":".75em"},attrs:{"label":"Legend","hide-details":""}},[_c('br'),_c('br'),_c('br'),_c('br'),_vm._v("D - Dark, L - Light "),_c('br'),_vm._v(" Colors - Basic Materials - Yellow, Com. Services - Orange, Consumer Cyclical - Maroon, Consumer Defensive - Blue, Energy - Green, Financial - Grey"),_c('br'),_vm._v(" Healthcare - Forest Green, Industrials - Dark Purple,Real Estate - Brown, Technology - Light Blue, Utilities - Dark Orange ")])]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 pl-2",staticStyle:{"position":"absolute","font-size":".75em"},attrs:{"label":"Legend","hide-details":""}},[_c('br'),_c('br'),_c('br'),_c('br'),_vm._v("D - Dark, L - Light "),_c('br'),_vm._v(" Colors - Basic Materials - Yellow, Com. Services - Orange, Consumer Cyclical - Maroon, Consumer Defensive - Blue, Energy - Green, Financial - Grey"),_c('br'),_vm._v(" Healthcare - Forest Green, Industrials - Dark Purple,Real Estate - Brown, Technology - Light Blue, Utilities - Dark Orange ")])}]

export { render, staticRenderFns }