var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dp-prints-container"}},[_c('h2',{staticClass:"section-header"},[_vm._v("Short Volume per Finra")]),(_vm.loading)?_c('v-skeleton-loader',{staticStyle:{"width":"100%","height":"100%"},attrs:{"type":"table-tbody"}}):_c('v-data-table',{attrs:{"dark":"","id":"print-table","headers":_vm.headers,"items":_vm.prints,"items-per-page":15,"dense":"","calculate-widths":"","mobile-breakpoint":"200"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date).toLocaleDateString("en-US", {timeZone: 'UTC'}))+" ")]}},{key:"item.shortVolume",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.shortVolume).toLocaleString())+" ")]}},{key:"item.shortExemptVolume",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shortExemptVolume.toLocaleString())+" ")]}},{key:"item.totalVolume",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalVolume.toLocaleString())+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }