<template>
    <div id="">

    </div>
</template>


<script>
import {AUTH} from "@/firebase/config";
import {COOKIE_USER_AUTH, setCookieWithDayExpiry} from "@/utils/CookieHelper";

export default {
    name: 'Landing',

    props: {
    },
    beforeMount() {
        const code = this.$route.query.token;
        // Set cookies with userID and userAuthToken for later use
        setCookieWithDayExpiry(COOKIE_USER_AUTH, code)

        AUTH.signInAnonymously()
            .then(() => {
                // Signed in
                console.log("signed in");
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode);
                console.log(errorMessage);
                // ...
            });

        if (!this.widgetMode) {
            this.tableHeight = `${window.innerHeight - 270}`;
        }

    },
    mounted() {

    },
    
    components: {
        
    },

    methods: {
    
    },

    data: () => ({
      
    }),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    

</style>
