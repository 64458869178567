<template>
    <div id="streaming-container">


        <div id="container" class="flex-cl-center-start">

            <highcharts id="chartContainer" class="chart" :options="chartOptions" :updateArgs="updateArgs" ref="highcharts"></highcharts>


        </div>
    </div>
</template>

<script>
    import { Chart } from "highcharts-vue";
    import Highcharts from "highcharts";
    //var bs = require("black-scholes");
    import exportingInit from "highcharts/modules/exporting";
    import offlineExporting from "highcharts/modules/offline-exporting";
    import apiService from "@/api/daily-data";
    import stockList from '../assets/stocks.json'
    //import weeklyList from '../assets/weeklys.json'
    //import apiService from '../api/daily-data';
    //import {COOKIE_USER_AUTH, getCookie} from "@/utils/CookieHelper";

    exportingInit(Highcharts);
    offlineExporting(Highcharts);


    export default {
        components: {
            highcharts: Chart
        },
        data() {
            return {
                model: null,
                stock: null,
                optionable: [],
                stocks: [
                    'Samson', 'Wichita', 'Combustion', 'Triton',
                    'Helios', 'Wimbeldon', 'Brixton', 'Iguana',
                    'Xeon', 'Falsy', 'Armagedon', 'Zepellin'],
                ticker : [],
                marketCap : [],
                floatShort : [],
                earningsDate : [],
                earningsTime : [],
                mondayPM : [],
                mondayAH : [],
                tuesdayPM : [],
                tuesdayAH : [],
                wednesdayPM : [],
                wednesdayAH : [],
                thursdayPM : [],
                thursdayAH : [],
                fridayPM : [],
                fridayAH : [],
                disabled: true,
                updateArgs: [true, false, false],
                slider: 60,
                rangeCalls: [],
                days: [],
                chart: undefined,
                chartVisibility: false,
                callPut: false,
                callorput: "call",
                valid: false,
                valid2: false,
                stockPrice: 0,
                optionPrice: 0,
                strikePrice: 0,
                volatility: 0,
                form: {},
                data: [],
                prices: [],
                chartOptions: {
                    chart: {
                        type: 'heatmap',
                        zoomType: 'xy',
                        marginTop: 90,
                        marginBottom: 10,
                        plotBorderWidth: 1,
                        height: "815px",
                        allowHTML : true,
                        style: {
                            fontFamily: 'myriad',
                            fontWeight: 'bold'
                        }
                    },
                    exporting: {
                        sourceWidth: 1300,
                        sourceHeight: 815,
                        scale: 1,
                        allowHTML : true,
                        chartOptions: {
                            height: "1015px",
                            width: "815px",// specific options for the exported image
                            allowHTML : true,
                            plotOptions: {
                                allowHTML : true,
                                series: {
                                    dataLabels: {
                                        enabled: true,
                                        allowHTML : true
                                    }
                                }
                            }
                        },
                        fallbackToExportServer: false
                    },


                    title: {
                        text: 'Earnings'
                    },


                    xAxis: {
                        categories: ["Monday <br> Before Open", "Monday <br> After Close","Tuesday <br> Before Open", "Tuesday <br> After Close","Wednesday <br> Before Open", "Wednesday <br> After Close","Thursday <br> Before Open", "Thursday <br> After Close","Friday <br> Before Open", "Friday <br> After Close"],
                        opposite : true,
                    },
                    yAxis: {
                        visible: false
                    },
                    legend: {
                        enabled: false
                    },

                    tooltip: {
                        enabled: false
                    },
                    colorAxis: {
                        minColor: '#ffffff',
                        maxColor: '#ffffff'
                    },

                    series: [{
                        useHTML:true,
                        colorKey: 'y',
                        fillColor: 'blue',
                        name: 'Sales per employee',
                        borderWidth: 1,
                        data: [
                            [0, 0, ["NXPI", "ARE"]],
                            [1, 0, ["AMD", "GOOG"]],
                            [2, 0, ["AMD", "GOOG"]],
                            [3, 0, ["AMD", "GOOG"]],
                            [4, 0, ["AMD", "GOOG"]],
                            [5, 0, ["AMD", "GOOG"]],
                            [6, 0, ["AMD", "GOOG"]],
                            [7, 0, ["AMD", "GOOG"]],
                            [8, 0, ["AMD", "GOOG"]],
                            [9, 0, ["AMD", "GOOG"]],
                        ],
                        dataLabels: {

                            enabled: true,
                            color: 'Grey',
                            useHTML: true,
                            verticalAlign : 'top',
                            align: 'left',
                            x: 20,
                            y: 0,
                            style: {
                                fontWeight: 'bold',
                                textOutline: false
                            },
                            formatter: function() {
                                let tickers = this.point.value
                                var html = []
                                for (let i = 0; i < tickers.length; i++) {
                                    var ticker = tickers[i]
                                    var htmlLabel = `<div style="display: flex; cursor: pointer; margin-left: 0px; margin-top: 10px;">
                                    <img  class="${this.point.name}-icon" src='https://firebasestorage.googleapis.com/v0/b/highshorts.appspot.com/o/logos2%2F${ticker}.png?alt=media' alt=${this.point.value} style="height:60px; width:80px; border-radius: 10px">
                                    <div style="display: flex; flex-direction: row; align-items: center; margin-left: 3px; ">
                                    <!-- span style="color:#ece6e6; font-size=100px">${ticker}</span -->
                                    </div>
                                    </div>`

                                    html.push(htmlLabel)
                                }
                                var final = html.toString()
                                final = final.replaceAll(",", " ")
                                return final;
                            }
                        }
                    }],
                }
            }
        },

        mounted: function () {
            this.getData();

            var result = [];

            for(var i in stockList)
                result.push(stockList[i].ticker);


            this.optionable = result;
            console.log(this.optionable)
        },
        methods: {
            getData: function () {
                apiService.getEarningsCaledar()
                    .then(response => response.json().then(response => {
                        this.stocks = response;
                        var curr = new Date; // get current date

                        // get days of week
                        var dates = []
                        for (var j = 1; j < 6; j++){
                            var first = curr.getDate() - curr.getDay() + j; // First day is the day of the month - the day of the week
                            dates.push(new Date(curr.setDate(first)).toISOString().split('T')[0])
                        }

                        for(var i=0; i<this.stocks.length; i++) {
                            if (this.optionable.includes(this.stocks[i]["Ticker"])) {
                                let erDate = '';
                                let erTime = '';
                                try {
                                    var erDateArr = this.stocks[i]["EarningsDate"].split(" ")
                                    erDate = Date.parse(erDateArr[0])
                                    erDate = new Date(erDate).toISOString().split('T')[0]
                                    erTime = erDateArr[1]
                                } catch {
                                    erTime = "8:30:00"
                                    erDate = this.stocks[i]["EarningsDate"]
                                }

                                if (erDate === dates[0]) {
                                    if (erTime === "8:30:00") {
                                        if (this.mondayPM.length < 10) {
                                            this.mondayPM.push(this.stocks[i]["Ticker"])
                                        }
                                    } else {
                                        if (this.mondayAH.length < 10) {
                                            this.mondayAH.push(this.stocks[i]["Ticker"])
                                        }
                                    }
                                }
                                if (erDate === dates[1]) {
                                    if (erTime === "8:30:00") {
                                        if (this.tuesdayPM.length < 10) {
                                            this.tuesdayPM.push(this.stocks[i]["Ticker"])
                                        }
                                    } else {
                                        if (this.tuesdayAH.length < 10) {
                                            this.tuesdayAH.push(this.stocks[i]["Ticker"])
                                        }
                                    }
                                }
                                if (erDate === dates[2]) {
                                    if (erTime === "8:30:00") {
                                        if (this.wednesdayPM.length < 10) {
                                            this.wednesdayPM.push(this.stocks[i]["Ticker"])
                                        }
                                    } else {
                                        if (this.wednesdayAH.length < 10) {
                                            this.wednesdayAH.push(this.stocks[i]["Ticker"])
                                        }
                                    }
                                }
                                if (erDate === dates[3]) {
                                    if (erTime === "8:30:00") {
                                        if (this.thursdayPM.length < 10) {
                                            this.thursdayPM.push(this.stocks[i]["Ticker"])
                                        }
                                    } else {
                                        if (this.thursdayAH.length < 10) {
                                            this.thursdayAH.push(this.stocks[i]["Ticker"])
                                        }
                                    }
                                }
                                if (erDate === dates[4]) {
                                    if (erTime === "8:30:00") {
                                        if (this.fridayPM.length < 10) {
                                            this.fridayPM.push(this.stocks[i]["Ticker"])
                                        }
                                    } else {
                                        if (this.fridayAH.length < 10) {
                                            this.fridayAH.push(this.stocks[i]["Ticker"])
                                        }
                                    }
                                }

                                this.earningsDate.push(erDate);
                                this.earningsTime.push(erTime);
                                this.ticker.push(this.stocks[i]["Ticker"]);
                                this.marketCap.push(this.stocks[i]["marketCap"]);
                                this.floatShort.push(this.stocks[i]["floatShort"]);

                            }
                        }

                    }));

                var newData = []

                newData.push([0,0,this.mondayPM])
                newData.push([1,0,this.mondayAH])
                newData.push([2,0,this.tuesdayPM])
                newData.push([3,0,this.tuesdayAH])
                newData.push([4,0,this.wednesdayPM])
                newData.push([5,0,this.wednesdayAH])
                newData.push([6,0,this.thursdayPM])
                newData.push([7,0,this.thursdayAH])
                newData.push([8,0,this.fridayPM])
                newData.push([9,0,this.fridayAH])
                this.chartOptions.series[0].data = newData


                console.log(this.chartOptions.series[0].data)
                //let chart = this.$refs.highcharts.chart;
                //chart.redraw(true);
                //chart.series[0].setData(newData)

            },
            clear: function () {
                this.chartVisibility = false;
                this.$refs.inputs.reset();
                this.callPut = false;
                this.callorput = "call";
            },
            created() {
                let i = document.createElement('input')
                i.setAttribute('type', 'color');
                (i.type === 'color') ? this.colorInputIsSupported = true : this.colorInputIsSupported = false
            },
        },
        watch: {
            title (newValue) {
                this.chartOptions.title.text = newValue
            },
            points (newValue) {
                this.chartOptions.series[0].data = newValue
            },
            chartType (newValue) {
                this.chartOptions.chart.type = newValue.toLowerCase()
            },
            seriesColor (newValue) {
                this.chartOptions.series[0].color = newValue.toLowerCase()
            },
            animationDuration (newValue) {
                this.updateArgs.duration = Number(newValue)
            }
        },
    }
</script>

<style scoped>

    #streaming-container {
        height: 100%;
        width: 100%;
        background-color: #323232;
        padding: 3%;
    }

    .highcharts-figure, .highcharts-data-table table {
        min-width: 360px;
        max-width: 800px;
        margin: 1em auto;
    }

    .highcharts-data-table table {
        font-family: Verdana, sans-serif;
        border-collapse: collapse;
        border: 1px solid #EBEBEB;
        margin: 10px auto;
        text-align: center;
        width: 100%;
        max-width: 500px;
    }
    .highcharts-data-table caption {
        padding: 1em 0;
        font-size: 1.8em;
        color: #25e5ec;
    }
    .highcharts-data-table th {
        font-weight: 600;
        padding: 0.5em;
    }
    .highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
        padding: 0.5em;
    }
    .highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
        background: #f8f8f8;
    }
    .highcharts-data-table tr:hover {
        background: #f1f7ff;
    }

</style>