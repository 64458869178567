<template>
    <div id="charts-container">
        <div class="chartElem">
            <div class="row1">
            <highcharts class="pie" :options="chartOptions3" :updateArgs="updateArgs3"></highcharts>
            <highcharts class="pie" :options="chartOptions2" :updateArgs="updateArgs2"></highcharts>
            <highcharts class="pie" :options="chartOptions4" :updateArgs="updateArgs4"></highcharts>
            </div>
            <div class="row2">
              <highcharts class="chart" :options="chartOptionsHist" :updateArgs="updateArgs2"></highcharts>
              <highcharts class="chart" :options="chartOptions" :updateArgs="updateArgs"></highcharts>
            </div>
        </div>
    </div>
</template>


<script>
    import apiService from "@/api/daily-data";

    export default {
        name: "HighChartsTTG",
        props: {
            ticker: String
        },
        data () {
            return {
                bullishPrem : "",
                bearishPrem : "",
                totalCallPrem : "",
                totalPutPrem : "",
                dates : [],
                openInterest : [],
                oiCallPercent : [],
                oiPutPercent : [],
                vol : [],
                volCallsPercent : [],
                volPutsPercent : [],
                datesHist : [],
                cOI : [],
                cVolu : [],
                pOI : [],
                pVolu : [],
                totalOI : [],
                totalVol : [],
                avgVol : [],
                flowsumCalls : [],
                flowsumPuts : [],
                title: '',
                points: [10, 0, 8, 2, 6, 4, 5, 5],
                chartType: 'Spline',
                seriesColor: '#6fcd98',
                colorInputIsSupported: null,
                animationDuration: 1000,
                updateArgs: [true, true, {duration: 1000}],
                updateArgs2: [true, true, {duration: 1000}],
                updateArgs3: [true, true, {duration: 1000}],
                updateArgs4: [true, true, {duration: 1000}],
                chartOptions: {
                    chart: {
                        type: 'bar'
                    },
                    xAxis: {
                        categories: ['Apples', 'Bananas', 'Oranges'],
                        title: {
                            text: 'Expiration Date'
                        },
                        reversed: true
                    },
                    yAxis: {},
                    title: {
                        text: 'Total OI and Volume by Date'
                    },
                    series: [{
                        name: "Open Interest",
                        data: [10, 0, 8, 2, 6, 4, 8, 9],
                        color: '#52f319'
                    }, {
                        name: "Volume",
                        data: [10, 0, 8, 2, 6, 4, 8, 9],
                        color: '#1c3ee7'
                    },
                    ]
                },
                chartOptionsHist: {
                    chart: {
                        type: 'line'
                    },
                    xAxis: {
                        categories: ['Apples', 'Bananas', 'Oranges'],
                        title: {
                            text: 'Expiration Date'
                        },
                        reversed: true
                    },
                    yAxis: {},
                    title: {
                        text: 'Historical OI and Vol'
                    },
                    series: [{
                        name: "put volume",
                        data: [10, 0, 8, 2, 6, 4, 8, 9],
                        color: '#f31945'
                    }, {
                        name: "call volume",
                        data: [10, 0, 8, 2, 6, 4, 8, 9],
                        color: '#63e71c'
                    },
                        {
                            name: "call oi",
                            data: [10, 0, 8, 2, 6, 4, 8, 9],
                            color: '#1ce7b8'
                        },
                        {
                            name: "put oi",
                            data: [10, 0, 8, 2, 6, 4, 8, 9],
                            color: '#e7dd1c'
                        },
                        {
                            name: "avg vol 20d",
                            data: [10, 0, 8, 2, 6, 4, 8, 9],
                            color: '#d91ce7'
                        },
                        {
                            name: "Total OI",
                            data: [10, 0, 8, 2, 6, 4, 8, 9],
                            color: '#e77b1c'
                        },
                        {
                            name: "Total Vol",
                            data: [10, 0, 8, 2, 6, 4, 8, 9],
                            color: '#586161'
                        },
                    ]
                },
                chartOptions2: {
                    chart: {
                        type: 'pie'
                    },
                    xAxis: {
                        categories: ['Apples', 'Bananas', 'Oranges'],
                        title: {
                            text: 'Expiration Date'
                        },
                    },
                    yAxis: {
                    },
                    title: {
                        text: 'Total Premium Call vs Puts'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {

                        pie: {
                            size: 120,
                            center: ["50%", "50%"],
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    },
                    series: [{
                        name: 'Call vs Put Prem',
                        colorByPoint: true,
                        data: [{
                            name: 'Calls',
                            y: 62,
                            sliced: true,
                            selected: true
                        }, {
                            name: 'Puts',
                            y: 38
                        }]
                    }]
                },
                chartOptions3: {
                    chart: {
                        type: 'pie'
                    },
                    yAxis: {
                    },
                    title: {
                        text: 'Bullish vs Bearish Prem'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            size: 120,
                            center: ["50%", "50%"],
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    },
                    series: [{
                        name: 'Call vs Put Prem (Above and At Ask)',
                        colorByPoint: true,
                        data: [{
                            name: 'Calls',
                            y: 62,
                            sliced: true,
                            selected: true
                        }, {
                            name: 'Puts',
                            y: 38
                        }]
                    }]
                },
                chartOptions4: {
                    chart: {
                        type: 'pie'
                    },
                    yAxis: {
                    },
                    title: {
                        text: 'Total OI - Calls vs Puts'
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            size: 120,
                            center: ["50%", "50%"],
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                            }
                        }
                    },
                    series: [{
                        name: 'Total OI - Calls vs Puts',
                        colorByPoint: true,
                        data: [{
                            name: 'Calls',
                            y: 62,
                            sliced: true,
                            selected: true
                        }, {
                            name: 'Puts',
                            y: 38
                        }]
                    }]
                }
            }
        },
        mounted: function () {
            this.getData();
        },
        methods: {
            getData: function () {
                let self = this;
                apiService.getOIDataTTG(this.ticker.toUpperCase())
                    .then(response => response.json().then(response => {
                        self.stocks = response.reverse();
                        for(var i=0; i<self.stocks.length; i++){
                            self.dates.push(self.stocks[i]["expirDate"]);
                            self.openInterest.push(self.stocks[i]["totalOI"]);
                            self.oiCallPercent.push(self.stocks[i]["callOIpercent"]);
                            self.oiPutPercent.push(self.stocks[i]["putOIpercent"]);
                            self.vol.push(self.stocks[i]["totalVol"]);
                            self.volCallsPercent.push(self.stocks[i]["callVolpercent"]);
                            self.volPutsPercent.push(self.stocks[i]["putVolpercent"]);
                        }
                    }));
                apiService.dailyflowsum(this.ticker)
                    .then(response => response.json().then(response => {
                        self.stocks = response;
                        self.totalCallPrem = 0;
                        self.totalPutPrem = 0;
                        self.bullishPrem = 0;
                        self.bearishPrem = 0;

                        for(var i=0; i<self.stocks.length; i++){
                            if (self.stocks[i]['cp'] === "C") {
                                self.flowsumCalls.push(self.stocks[i]['sum']);
                                self.totalCallPrem = self.stocks[i]['sum'] + self.totalCallPrem
                                if (self.stocks[i]['Side'] === 3 || self.stocks[i]['Side'] === 2) {
                                    self.bullishPrem = self.stocks[i]['sum'] + self.bullishPrem
                                }
                            }
                            if (self.stocks[i]['cp'] === "P"){
                                self.flowsumPuts.push(self.stocks[i]['sum']);
                                self.totalPutPrem = self.stocks[i]['sum'] + self.totalPutPrem
                                if (self.stocks[i]['Side'] === 3 || self.stocks[i]['Side'] === 2 ) {
                                    self.bearishPrem = self.stocks[i]['sum'] + self.bearishPrem
                                }
                            }
                        }

                        this.chartOptions2.series[0].data[0].y = self.totalCallPrem
                        this.chartOptions2.series[0].data[1].y = self.totalPutPrem
                        this.chartOptions3.series[0].data[0].y = self.bullishPrem
                        this.chartOptions3.series[0].data[1].y = self.bearishPrem


                    }));
                apiService.getOIDataHist(this.ticker)
                    .then(response => response.json().then(response => {
                        self.stocks = response;
                        for(var i=0; i<self.stocks.length; i++){
                            self.datesHist.push(self.stocks[i]["date"].split("T")[0]);
                            self.cOI.push(self.stocks[i]["cOi"]);
                            self.cVolu.push(self.stocks[i]["cVolu"]);
                            self.pOI.push(self.stocks[i]["pOi"]);
                            self.pVolu.push(self.stocks[i]["pVolu"]);
                            self.totalOI.push(self.stocks[i]["totalIntraOI"]);
                            self.totalVol.push(self.stocks[i]["totalVolume"]);
                            self.avgVol.push(self.stocks[i]["avgOptVolu20d"]);
                        }
                        this.chartOptions4.series[0].data[0].y = self.cOI[0]
                        this.chartOptions4.series[0].data[1].y = self.pOI[0]


                    }));

                this.chartOptions.series[0].data = self.openInterest
                this.chartOptions.series[1].data = self.vol

                this.chartOptions.xAxis.categories = self.dates
                this.chartOptionsHist.series[0].data = self.pVolu
                this.chartOptionsHist.series[1].data = self.cVolu
                this.chartOptionsHist.series[2].data = self.cOI
                this.chartOptionsHist.series[3].data = self.pOI
                this.chartOptionsHist.series[4].data = self.avgVol
                this.chartOptionsHist.series[5].data = self.totalOI
                this.chartOptionsHist.series[6].data = self.totalVol
                this.chartOptionsHist.xAxis.categories = self.datesHist

            },
        },
        created () {
            let i = document.createElement('input')
            i.setAttribute('type', 'color');
            (i.type === 'color') ? this.colorInputIsSupported = true : this.colorInputIsSupported = false
        },
        watch: {
            title (newValue) {
                this.chartOptions.title.text = newValue
            },
            points (newValue) {
                this.chartOptions.series[0].data = newValue
            },
            chartType (newValue) {
                this.chartOptions.chart.type = newValue.toLowerCase()
            },
            seriesColor (newValue) {
                this.chartOptions.series[0].color = newValue.toLowerCase()
            },
            animationDuration (newValue) {
                this.updateArgs[2].duration = Number(newValue)
            }
        }
    }
</script>

<style scoped>
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }


    .pie {
        min-width: 400px;
        max-width: 400px;
        min-height: 300px;
        max-height: 300px;
        top: 2px;
        border: 2px;
        padding: 2px;
        padding-top: 8px;
        font-family: 'Roboto', sans-serif !important;
    }

    .row1{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-family: 'Roboto', sans-serif !important;
    }
    .row2{
        display: flex;
        flex-wrap: wrap;
        justify-content:center;
        align-items: center;
        font-family: 'Roboto', sans-serif !important;
    }
    .chart {
        min-width: 800px;
        max-width: 1200px;
        max-height: 500px;
        min-height: 500px;
        border: 2px;
        padding: 2px;
        padding-top: 8px;
        background-color: #4C4C4C;
        font-family: 'Roboto', sans-serif !important;
    }
    .numberInput {
        width: 30px;
    }

     #charts-container {
         width: 100%;
         display: flex;
         flex-direction: column;
         align-items: center;
         font-family: 'Roboto', sans-serif !important;
     }

    #chartElem {
        padding-top: 10px;
        width: 100%;
        background-color: #4C4C4C;
    }
    .highcharts-axis-labels{
        font-family: 'Roboto', sans-serif !important;
    }
    .highcharts-root{
        font-family: 'Roboto', sans-serif !important;
    }
    >>>td {
        padding: 16px 0 !important;
    }

    >>>.v-skeleton-loader__table-tbody {
        background-color: #4C4C4C !important;
    }
</style>