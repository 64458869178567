let abbreviateNumber = function (value, {prefix=null, suffix=null}={}) {
    let newValue;
    if (!isNaN(value) || !isNaN(Number(value))) {
        newValue = value;
    } else {
        newValue = Number(value);
        if (isNaN(newValue)) return "N/A";
    }
    const suffixes = ["", "K", "M", "B","T"];
    let suffixNum = 0;
    while (newValue >= 1000) {
        newValue /= 1000;
        suffixNum++;
    }

    newValue = newValue.toPrecision(3);

    if (prefix)
        newValue = prefix + newValue + suffixes[suffixNum]
    else if (suffix)
        newValue += suffix
    else
        newValue += suffixes[suffixNum];
    return newValue;
}

let checkNaN = function (value) {
    if (isNaN(value)) {
        return "N/A"
    } else {
        return value;
    }
}

export {abbreviateNumber, checkNaN}